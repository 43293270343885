//home donors
$primaryColor: #eb3e32;
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;700&display=swap');
  .imghoverzoom:hover {
    cursor: pointer;
   // transform: scale(1.03);
  }
  .donorItemsCont{
    display:flex;
    flex:1;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-left: 2%;
    margin-right: 2%;
  }
  .imghoverzoom{
    height:auto;
    width:22%;
    background-color:#F9F8F8;
    margin:7px;
    padding-bottom: 15px;
    transition: transform .5s ease;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
   }
   .imghoverzoom img{
     width:100%;
     height:300px;
     min-height: 50vh;
     object-fit:cover
   }

   .imghoverzoom h1{
    padding-top:7px;
    font-size:20px;
    font-weight:700;
    padding-right:7px;
    padding-left:7px;
    margin:0;
    font-family: 'Teko', sans-serif;
    line-height: 0.8;
   }
   .listP1{
    padding-top:2px;
    font-size:12px;
    font-weight:300;
    padding-right:7px;
    padding-left:7px;
    font-family: 'Poppins', sans-serif;
   }
   .listP2{
    padding-top:2px;
    font-size:14px;
    font-weight:700;
    padding-right:7px;
    padding-left:7px;
    color:$primaryColor;
    font-family: 'Poppins', sans-serif;
    text-decoration: underline;
   }
   .listP2:hover{
    color:rgb(255, 230, 0);
   }
   .container2{
       margin-bottom: 40px;
       padding-top: 70px;
   }
   .imgDiv{
    position: relative;
    height:80vh;
   }
   .imgDiv img{
     width:100%;
     height:80vh;
     min-height: 310px;
     object-fit:cover;
   }
   .imgDiv h1{
    font-size: 60px;
    color: black;
    font-family: 'Teko', sans-serif;
    z-index: 8;
    position: absolute;
    color: white;
    bottom: 0;
    background-color: black;
    margin-bottom:-30px;
    padding: 5px 20px;
    margin-left: 40px;
    font-weight: 500;
    
   }
   .container1{
     margin-top: 50px;
   }
  @media all and (max-width: 912px) {
 
     .imghoverzoom{
    
      width:47%;
    }
    
    
}
@media all and (max-width: 608px) {
  .imghoverzoom img{
    min-height: 38vh;
    height:230px;
  }
    
}

@media all and (max-width: 585px) {
  
   
.imghoverzoom{
 
  width:47%;
  margin:3px;
 
 }
 .imghoverzoom img{
  width:100%;
  height:38vh;
  min-height:230px;
  
 }
 .imghoverzoom h1{
  font-size:19px;
 }
}

 @media all and (max-width: 494px) {
  .imghoverzoom{
    width:47%;
    margin:3px;
   
   }
   .imghoverzoom img{
    width:100%;
    min-height:190px;
    height: 31vh;
    
   }
   .imgDiv{
    position: relative;
    height:55vh;
   }
   .imgDiv img{
     width:100%;
     height:55vh;
     min-height: 300px;
     object-fit:cover;
   }
  .imgDiv h1{
    font-size: 30px;
    margin-bottom:-30px;
    padding: 5px 40px;
    margin-left: 10px;
   }
 
  }
   @media all and (max-width: 380px) {
    .imghoverzoom{
      width:100%;
      margin:0px;
      margin-bottom: 20px;
     
     }
     .imghoverzoom img{
      width:100%;
      min-height:250px;
      height: 45vh;
      
     }
    .imghoverzoom h1{
      font-size: 25px;
     }
   
    }
     @media all and (max-width: 342px) {
      
       .imghoverzoom img{
        width:100%;
        height:38vh;
        min-height: 230px;
       }
       .imghoverzoom h1{
        font-size: 20px;
       }
  
     
      }
