//my account
$primaryColor: #eb3e32;
$secondaryColor: #FED800;
$hoverColor:#FBCD00;
$shopColor:#bfaf80;
.container{
    height: auto;
    background-color: whitesmoke;
    display: flex;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 38px;
    padding-bottom: 38px;
    //font-family: 'Poppins', sans-serif;
    
}
.container1{
    height: 373px;
    background-color: white;
    //min-height: 153px;
    width: 25%;
    margin-right: 30px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
    border-radius: 7px;
}
.container2{
    min-height: 461px;
    background-color: white;
    width: 75%;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
    border-radius: 7px;
}
.contMenu{
    height: 61px;
    display: flex;
    align-items: center;
    padding-left: 19px;
    padding-right: 15px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    border-bottom-color: whitesmoke;
    border-bottom-width:1px;
    border-bottom-style: solid;
}
.contMenu1{
    height: 61px;
    display: flex;
    align-items: center;
    padding-left: 19px;
    padding-right: 15px;
}
.contMenu1 p{
    font-size: 15px;
}
.contIcon1,.contIcon2,.contIcon3,.contIcon4,.contIcon5{
    font-size: 23px;
    margin-right: 15px;
}
.contMenu p{
    font-size: 15px;
}
.contMenu:hover{
    background-color: rgb(238, 238, 238);
    cursor: pointer;
    font-weight: 600;
}
.contMenu1:hover{
    background-color: rgb(238, 238, 238);
    cursor: pointer;
    font-weight: 600;
}
.contMenuLogOut{
    height: 61px;
    display: flex;
    align-items: center;
    padding-left: 19px;
    padding-right: 15px;
    border-top-color: whitesmoke;
    border-top-width:1px;
    border-top-style: solid;
    justify-content: center;
}
.contMenuLogOut p{
    font-size: 15px;
    font-weight: 500;
    //margin-top: 3px;
}
.contMenuLogOut p:hover{
     background-color: wheat;
     cursor: pointer;
     padding: 11px 15px 11px 15px;
     border-radius: 3px;
}

@media all and (max-width: 1076px) {
    .container1{
        width: 30%;
        margin-right: 23px;
    }
    .container2{
        width: 70%;
        
    }
}
@media all and (max-width: 769px) {
    .container{
        padding-left: 2%;
        padding-right: 2%;
        padding-top: 38px;      
    }
    .container1{
        width: 30%;
        margin-right: 7px;
    }
    .container2{
        width: 70%;
        
    }
}
@media all and (max-width: 692px) {
    
    .container{
      flex-direction: column;
      padding-top: 0em;
      padding-left: 0em;
      padding-right: 0em;
      //background-color: white;
     
    }
    .container1{
        height: auto;
        background-color: white;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        border-radius: 0em;
        box-shadow: none;
        margin-bottom: 15px;
        justify-content: space-between;
        padding-bottom: 3px;
        padding-top: 3px;
        
    }
    .contMenu{
        width: 32%;
        border: none;
        padding-left: 0em;
        padding-right: 0em;
        flex-direction: column;
        justify-content: center;
        border-color: whitesmoke;
        border-width:1px;
        border-style: solid;
        border-radius: 0em;
        margin-bottom: 7px;
        height: 69px;
       
    }
    .contMenu p,.contMenu1 p {
     padding-top: 3px;
     font-size: 13px;
     width: 90%;
     text-align: center;
    }
 
    .contIcon1,.contIcon2,.contIcon3,.contIcon4,.contIcon5{
        align-self: center;
        margin-right: 0em;
        font-size: 23px;
    }
        
    .contMenu1{
        width: 32%;
        padding-left: 0em;
        padding-right: 0em;
        flex-direction: column;
        justify-content: center;
        border-width:1px;
        border-style: solid;
        border-color: whitesmoke;
        height: 69px;
    }
    .contMenuLogOut{
        width: 32%; 
        display: flex;
        align-items: center;
        padding-left: 0em;
        padding-right: 0em;
        border: none;
        border-width:1px;
        border-style: solid;
        border-color: whitesmoke;
        height: 69px;
        flex-direction: column;
    }
    .contMenuLogOut p{
        margin-top: 3px;
        font-size: 13px;
        font-weight: 400;
    }
    .contMenuLogOut:hover{
        background-color:wheat;
    }
    .contMenuLogOut p:hover{
        background-color:wheat;
        cursor: pointer;
        padding: 0em 0em 0em 0em;
        border-radius: 3px;
   }
   .container2{
    width: 100%;
    border-radius: 0em;
}
}
@media all and (max-width: 384px) {
    .container1{
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .contMenu1{
        width: 32%;
        border-width:0px;
        height: 75px;
    }
    .contMenu,.contMenuLogOut{
        width: 32%;
        border-width:0px;
        height: 75px;
    }
    
       .contIcon1,.contIcon2,.contIcon3,.contIcon4,.contIcon5{
        font-size: 25px;
        padding: 10px;
        color: white;
        border-radius: 50px;
    }
    .contIcon1{
        background-color: $primaryColor;
    }
    .contIcon2{
        background-color:rgb(7, 182, 94);
    }
    .contIcon3{
        background-color: rgb(255, 166, 0);
    }
    .contIcon4{
        background-color: rgb(218, 116, 228);
    }
    .contIcon5{
        background-color: rgb(7, 25, 182);
    }
    .contIcon6{
        font-size: 25px;
        padding: 10px;
        color: white;
        border-radius: 50px;
        background-color: rgb(7, 141, 182);
    }
      
    
    .contMenuLogOut p{
        font-size: 11px;
    }
    .container{
        font-family: 'Roboto', sans-serif;
    }
    
}
