$primaryColor: #FF7C59;
$hoverColor:#FBCD00;
.container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

}
/*.mainCont{
   padding: 30px 50px; 
   display: flex;
   flex:1;
}
.container1{
 width:30%;
 margin-right: 10px;
 box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
 background-color:#0e1726;
 height:60vh
}
.container2{
    width:70%;
    margin-left: 10px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
    background-color:#0e1726;
}*/
.triangle{
    position: relative;
    width: 100%;
    height: 600px;
}

.triangle::before{
    content: "";        
    position: absolute;
    background:blue;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0% 0, 0 100%, 100% 50%);
}
.triangle2{
    position: relative;
    width: 100%;
    height: 600px;
}

.triangle2::before{
    content: "";        
    position: absolute;
    background:red;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0% 0, 0 100%, 100% 50%);
}