$primaryColor: #eb3e32;
$secondaryColor: #FED800;
.container{
    height: auto;
    width: 100%;
    background-color: whitesmoke;
    
}
.container2{
    height: auto;
    width:500px;
    padding-bottom: 30px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: white;
}
.container1{
    display: flex;
    width:100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
}
.contHead{
    height: 60px;
    width:100%;
    border-bottom: 1px solid whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;

}
.contHead p{
  font-weight: 600;
  font-size: 20px;
  font-family: 'Rubik', sans-serif;
}
.payments{
    margin: 10px;
    display: flex;
}
.imgCont{
    height: 40px;
    width: 60px;
    background-color: white; 
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

#imgCont1:hover{
 transform: scale(1.1);
 cursor: pointer;
}
#imgCont1 img{
    height: 100%; 
 }
#imgCont2:hover{
    transform: scale(1.1);
    cursor: pointer;
   }
#imgCont2 img{
    height: 80%; 
 }
#imgCont3:hover{
    transform: scale(1.1);
    cursor: pointer;
   }
#imgCont3 img{
    height: 70%; 
 }
#imgCont4:hover{
    transform: scale(1.1);
    cursor: pointer;
   }
#imgCont4 img{
    height: 70%; 
 }
#imgCont5:hover{
    transform: scale(1.1);
    cursor: pointer;
   }
#imgCont5 img{
    height: 80%; 
 }
 .payP{
     margin-left: 20px;
     margin-top: 20px;
     font-size: 14px;
 }
 .methSelector{
    height: 18px;
    width: 18px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
 }
 .selectorDiv2{
    height: 20px;
    width: 20px;
    background-color: $primaryColor;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 38px;
    margin-right: 5px;
}
.methCont{
    display: flex;
    margin-right: 20px;
    align-items: center;
    margin-left: 20px;
    margin-top: 10px;
}
.methCont p{
    font-size: 16px;
    font-weight: 600;
    color: $primaryColor;
}
.typeCont p{
    font-size: 14px;
}
.selectorDiv2:hover{
    cursor: pointer;
}
.typeCont{
    display: flex;
    margin-right: 20px;
    align-items: center;
}
.typeDiv{
    display: flex;
    margin-left: 20px;
    margin-top: 15px;
}


.selectCont{
    display: flex;
    width: 80%;
    height: 40px;
    border-color: whitesmoke;
    border-width: 1px;
    border-style: solid;
    padding: 10px;
    box-sizing: border-box;

}
.selectCont:hover{
    cursor: pointer;
}
.contDiv:hover{
   
    cursor: pointer;
}
.contDiv{
    margin-left: 20px;
    margin-right: 20px;
}
.selectDiv{
    flex: 1;
    border: none;
    font-size: 14px;
    outline: none; 
}
.selectDiv:hover{
    cursor: pointer;
}
.condP{
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 14px;
}
.donateCont{
    background-color: $primaryColor;
    font-size: 16px;
    width:180px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-top: 30px;
    margin-left: 20px;
}
.donateCont:hover{
    cursor: pointer;
    background-color: $secondaryColor;
}
.howMuchDiv{
    margin-top: 20px;
    margin-left: 20px;
    //width:90%
}
.howMuchDiv p{
    font-size: 14px;
    margin-bottom: 10px;
}
.howMuchDiv input{
   border-style: none;
   font-size: 14px;
   text-align: left;
   overflow: hidden;
   outline: none;
   margin-right: 10px;
   width: 73%;
   padding:12px;
   border-color: whitesmoke;
   border: 1px solid whitesmoke;
  
}
.donDiv{
    display: flex;
   
}
.donList{
    display: flex;
    align-items: center;
    margin-right: 10px;
    height: 25px;
    margin-bottom: 20px;
}
.donListSelector{
    height: 20px;
    width:20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.donListSelector:hover{
    background-color:$primaryColor;
    cursor: pointer;
}
.donListIc{
    color: white;
    font-size: 14px;
}
.donList p{
    margin-top: 8px;
    padding-left: 5px;
}


@media all and (max-width: 500px) {
    .container2{
        width:400px;
       
    }
}
@media all and (max-width: 500px) {
    .container2{
        width:100vw;
       margin-top: 80px;
    }
    .contHead{
        height: 50px;
        width:100%;
        justify-content: flex-start;
    
    }
    .contHead p{
        font-size: 14px;
        font-weight: 500;
        margin-left: 15px;
    }
    .selectCont{
        display: flex;
        width: 98%;
    }
}

