
//editaccount
$primaryColor: #FF7C59;
$secondaryColor: #FED800;
$hoverColor:#FBCD00;
$shopColor:#bfaf80;
.container{
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}
.containerA{
    background-color: white;
    width: 60%;
    height: auto;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
    margin-top: 38px;
    border-radius: 7px;
    box-sizing: border-box;
    padding: 23px 23px 38px 23px;
    margin-bottom: 38px;
}
.container1{
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 15px; 
}
.container1B{
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 38px;
   // background-color: yellow;
   
}
.container2{
    display: flex;
    flex-direction: column;
    flex:1;
    box-sizing: border-box;
    margin-right: 15px;
}
.container2B{
    display: flex;
    flex-direction: column;
    flex:1;
    box-sizing: border-box;
    margin-left: 15px;
}
.input{
    padding-top: 11px;
    padding-bottom: 11px;
    font-size: 15px;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-color: whitesmoke;
    border-bottom-width:1px;
}
.adrressInput{
    padding-top: 11px;
    padding-bottom: 11px;
    font-size: 15px;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-color: whitesmoke;
    width: 100%;
    border-bottom-width:1px;

}
.containerA p{
    font-size: 13px;
    color: rgb(167, 167, 167);
}
.container1C{
    margin-top: 38px;
}
.container1C p{
    margin-top: 23px;
}
.saveCont{
    height: 46px;
    background-color: $primaryColor;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 7px;
    margin-top: 76px;
}
.saveCont:hover{
    background-color: $hoverColor;
    cursor: pointer;
  
}
.saveCont h4{
    font-size: 15px;
    letter-spacing: 1px;
}

@media all and (max-width: 769px) {
  .containerA{
      width: 80%;
  }  
  }
  @media all and (max-width: 615px) {
    .containerA{
        width: 80%;
    } 
    .container1{
        flex-direction: column;
    } 
    .container2B{
        margin-top: 38px;
        margin-left: 0em;
        margin-right: 15px;
    }
    .container1B{
        flex-direction: column;
    }
    .container1C{
        margin-right: 15px;
    }
    }
    @media all and (max-width: 461px) {
        .containerA{
            width: 95%;
            margin-top: 0em;
            box-shadow: none;
        }
        .mainContainer{
            background-color: white;
        }
        
    }
    @media all and (max-width: 384px) {
        .containerA{
            width: 100%;
        }
        .container2B{
            margin-top: 15px;
        }
        .container1C{
            margin-top: 15px;   
        }
        .container1B{
            margin-top: 15px;   
        }
        .saveCont{
            height: 38px;
            background-color: $primaryColor;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            border-radius: 7px;
            margin-top: 38px;
        }
    }


