//homeproducts
$primaryColor: #ed1d4f;
$secondaryColor: #FED800;
@mixin maxlinelength {
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
}

body{
    overflow-x:hidden;
    background-color: whitesmoke;
   
}
.homeProCont{
  min-height: 380px;  
  height: auto;
  flex: 1;
  display: flex;
  box-sizing: border-box;
  margin-left: 40px;
  margin-right: 25px;
  margin-bottom: 30px;
  margin-top: -7px;
  padding: 0px;
  flex-direction: column;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
 
}
.proListCont{
  display: flex;
  flex-wrap: wrap;
  
  padding-bottom: 22px;
  padding-left: 15px;
  padding-right: 7px;
 
}
.homeProCont p{
   font-size: 19px;
   font-weight: 600;
   margin-bottom: 0em;
   
   //font-family: 'Roboto', sans-serif;
   margin-left: 15px;
}
.homeProListCont{
  background-color: white;
    width: 32%;
    height: 470px;
    overflow:hidden;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
   border: 1px solid whitesmoke;
    display: flex;
    margin: 5px;

}
.homeProListCont:hover{
  cursor: pointer;
  transform: scale(1.01);
}
.homeImgDiv{
  height: 270px;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding-bottom: 7px;
 
}
.homeProImg{
  height: 100%;
}
.homeProListCont p{
  margin: 0em;
  padding: 0em;
   margin-left: 15px;
   margin-right: 15px;
   text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
   margin-top: 10px;
  
}
.homeProListCont .homeProdTitle{
  font-size: 20px;
  -webkit-line-clamp: 2;
  @include maxlinelength();
  color: black;
  font-weight: 600;
}
.homeProListCont .homeProdPrice{
  font-size: 16px;
  color: black;
  -webkit-line-clamp: 3;
  @include maxlinelength();
  font-weight: 500;
}
.homeProListCont .homeProdLoc{
  font-size: 12px;
  color: #fff;
  overflow: hidden;
  -webkit-line-clamp: 1;
  font-weight: 400;
  background-color: $primaryColor;
  width:150px;
  height:40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  
}
@media all and (max-width: 1200px) {
  .homeProListCont{
    width: 21.8%;
  }
  }
  @media all and (max-width: 1000px) {
    .homeProListCont{
      width: 29.5%;
    }
     }
     @media all and (max-width: 700px) {
      .homeProListCont{
        width: 45.5%;
      }
      .homeProCont{
        margin-right: 20px;
        margin-left: 20px;
      }
       }
       @media all and (max-width: 500px) {
        .homeProListCont{
          height: 243px;
          width: 45.2%;
        }
        .homeProCont{
          margin-right: 0em;
          margin-left: 0em;
          padding-right: 3px;
          padding-left: 0em;
          border-radius: 0px;
          }
          .homeImgDiv{
            height: 152px;

          }
         
         }
        
         @media all and (max-width: 400px) {
          .homeProListCont{
            width: 46%;
            height: 210px;
            margin: 2px;
            border-color: whitesmoke;
          }
          .homeImgDiv{
            height: 129px;

          }
            .proListCont{
              padding-right: 7px;
              padding-left: 7px;
            }
            .homeProCont p{
              font-size: 15px;
              margin-bottom: 0em;
              margin-left: 15px;
              margin-right: 15px;
           }
           .homeProListCont .homeProdTitle{
            font-size: 13px;
           
          }
          .homeProListCont .homeProdPrice{
            font-size: 15px;
           
          }
          .homeProListCont .homeProdLoc{
            font-size: 9px;
            
          }
        
        }
        @media all and (max-width: 350px) {
          body{
            font-family: 'Roboto', sans-serif;
          }
          .homeProListCont{
            height: 165px;
            margin-top: 0px;
          
          }
          
          .homeImgDiv{
            height: 83px;
            margin-bottom: 3px;
             }
             .homeProListCont .homeProdTitle{
              font-size: 11px;
              font-weight: 500;
             
            }
            .homeProListCont .homeProdPrice{
              font-size: 12px;
              font-weight: 500;
             
            }
            .homeProListCont .homeProdLoc{
              font-size: 9px;
              font-weight: 400;
             
              
            }
            .proListCont{
              padding-right: 0em;
              padding-left: 0em;
              margin: 0em;
             
            }
            .homeProListCont p{
              
               margin-left: 3px;
               margin-right: 3px;
              }
          
        }
        