$darkTheme:#473c40;
$primaryColor: #8d2855;
$secondaryColor: #db5d96;
$hoverColor:#d8b806;
$theGrey1: #5F6162;
.container{
    height: auto;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 70px;
}
.statusBar{
    height: 70px;
    background-color: $primaryColor;
  }
.container2{
    display: flex;
    height: auto;
    //background-color: royalblue;
    margin-top: 40px;
    display:flex;
    justify-content: center;
    padding-left: 50px;
    padding-right: 50px;
    
   
}
.cont1{
    width:50%;
    background-color: $darkTheme;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    padding-top: 20px;
    padding-bottom: 20px;
}
.cont2{
    width:50%;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 8%;
    padding-top: 20px;
    padding-bottom: 20px;
}
.contAdress{
    height:40px;
    width:40px;
    border: 1px solid white;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
   
}
.adIcon{
    font-size: 30px;
    color: white;
   
   
}
.cont1A, .cont1B, .cont1C, .cont1D{
    box-sizing: border-box;
    width:40%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-right: 20px;
   // background-color: blue;
}
.cont1A h4,.cont1B h4, .cont1C h4, .cont1D h4{
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 10px;
  color: white;
  
 // text-align: center;
}
.cont1A p, .cont1B p, .cont1C p, .cont1D p{
    font-size: 12px;
    color: white;
    text-align: center;
  }
  .sIcon{
      color: white;
      margin: 5px;
      font-size: 20px;
  }
  .sIcon:hover{
      cursor: pointer;
      color: $primaryColor;
  }
  .name{
      display: block;
      width:70%;
      border:none;
      border-bottom: 1px solid rgb(168, 168, 168);
      padding-bottom: 10px;
      margin-top: 10px;
      outline: none;
      
  }
  .phone{
    display: block;
    width:70%;
    border:none;
    border-bottom: 1px solid rgb(168, 168, 168);
    padding-bottom: 10px;
    margin-top: 30px;
    outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
.message{
    display: block;
    width:70%;
    border:none;
    border-bottom: 1px solid rgb(168, 168, 168);
    padding-bottom: 10px;
    margin-top: 30px;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
}
.sendMesoDiv{
    background-color: $primaryColor;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    border-radius: 5px;
    margin-top: 30px;
}
.sendMesoDiv:hover{
    cursor: pointer;
    background-color: $secondaryColor;
}
.sendMesoDiv h4{
    color: white;
    font-weight: 400;
}
.cont2 h3{
 margin-bottom: 10px;
 font-size: 18px;
}
.cont2 p{
    margin-bottom: 10px;
    font-size: 14px;
}
.selectDiv{
    flex: 1;
    border: none;
    font-size: 14px;
    outline: none; 
    border-bottom: 1px solid  rgb(168, 168, 168);
    padding-bottom: 10px;
}
.selectDiv option{
    margin-bottom: 20px;
}
.selectDiv:hover{
    cursor: pointer;
}
.selectCont{
    display: flex;
    width: 70%;
    margin-bottom: 20px;
}
@media all and (max-width: 1000px) {
    .name{
        width:80%;  
    }
    .phone{
      width:80%;
  }
  .message{
      width:80%;
  }
  .selectCont{
    width: 80%;
}
}

@media all and (max-width: 800px) {
    .container2{
        padding-left: 20px;
        padding-right: 20px;
    }
    .cont1A, .cont1B, .cont1C, .cont1D{
        width:42%;
       
    }
}
@media all and (max-width: 700px) {
    .container2{
       flex-direction: column;
       margin-top: 20px;
       padding-left: 50px;
       padding-right: 50px;
    }  
    .cont1{
        order:2;
        width:auto;
    }
    .cont2{
        order: 1;
        width:auto;
        padding-left: 8%;
        padding-top: 30px;
        padding-bottom: 40px;
    } 
    .container{
        padding-top: 0px;
        padding-bottom: 50px;
    }
}
@media all and (max-width: 500px) {
    .container2{
        flex-direction: column;
        margin-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
     }  
}
@media all and (max-width: 420px) {
    .container2{
        flex-direction: column;
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
     }  
     .name{
        width:90%;  
    }
    .phone{
      width:90%;
  }
  .message{
      width:90%;
  }
  .selectCont{
    width: 90%;
}
.cont1A p, .cont1B p, .cont1C p, .cont1D p{
    font-size: 10px;
  }
}


