.container{
    height: auto;
    min-height: 800px;
    padding-top: 200px;
    display: flex;
    overflow: hidden;
    
}
.container img{
    width: 1000px;
    overflow: hidden;
}