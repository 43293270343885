//miziki
$primaryColor: #eb3e32;
$hoverColor:#FBCD00;
.container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
   
}
.container1{
    width: 100%;
    height: 85vh;
    min-height: 530px;
    overflow: hidden;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.7), 
        rgba(0, 0, 0, 0.7)
      ),url('https://images.pexels.com/photos/1588075/pexels-photo-1588075.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
      background-repeat: no-repeat;
     background-size: 100% 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}
.contDonateP{
    text-decoration: none;
}
.container2{
    box-sizing: border-box;
    height: auto;
    background-color: whitesmoke;
   // padding-left: 150px;
   // padding-right: 150px;
    padding-top: 76px;
    padding-bottom: 76px;
    width: 70%;
    align-self: center;
    
}
.container3{
    height: 150px;
   // width: 100%;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
    background-color: bl;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.7), 
        rgba(0, 0, 0, 0.7)
      ),url('https://images.pexels.com/photos/1588075/pexels-photo-1588075.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
      background-repeat: no-repeat;
     background-size: 100% 100%;
    background-size: cover;
    background-position: center;
    padding-left: 20px;
    padding-right: 20px;
}
#jI{
   font-size: 20px;
   color: white;
   }
#gI{
 background-color: $primaryColor;
 color: white;
 padding: 10px 20px;
 margin-top: 10px;
 font-weight: 500;
 font-size: 20px;
}
#gI:hover{
    cursor: pointer;
}
.container2 h2{
    font-size: 61px;
    font-family: 'Teko', sans-serif;
    line-height: 1.2;
    margin-bottom: 20px;
    text-align: center;
    
}
.container2 p{
    padding-top:1px;
    font-size: 15px;
    line-height: 1.3;
   // padding-left: 7px;
   // padding-right: 7px;
   
    
}
.container1Det{
  margin-left: 5%;
  margin-right: 40%;
 // background-color: red;
}
.title{
    color: white;
    font-size:30px;
    font-weight:800;
    letter-spacing: 1px;
    line-height: 1.2;
    font-family: 'Rubik', sans-serif;
}
.details{
    margin:0em;
    font-size:53px;
    font-weight:700;
    color:#fff;
    line-height: 1.2;
    letter-spacing: 1px;
    font-family: 'Rubik', sans-serif;
}
.contDonate{
    box-sizing: border-box;
    background-color:$primaryColor;
     height: 53px;
     width:176px;
     display:flex;
     justify-content:center;
     align-items: center;
     margin-top:38px;
     border-radius: 7px;
     letter-spacing: 1px;
     font-weight:700;
     font-family: 'Rubik', sans-serif;
     border-style: solid;
     border-color: $primaryColor;
     border-width: 1px;
  }
  .contDonate p{
      font-size: 19px;
      color: white;
      
  }
  .contDonate:hover{
    
    cursor: pointer;
    background-color: $hoverColor;
    
}



.contactUs{
    box-sizing: border-box;
    border-style: solid;
    border-color: white;
    border-width: 1px;
     height: 53px;
     width:176px;
     display:flex;
     justify-content:center;
     align-items: center;
     margin-top:38px;
     border-radius: 7px;
     letter-spacing: 1px;
     font-weight:700;
     font-family: 'Rubik', sans-serif;
     margin-left: 23px;
  }
  .contactUs p{
      font-size: 19px;
      color: white;
  }
  .contactUs:hover{
    border: none;
    cursor: pointer;
    background-color: black;
    
}

//infolist
.infoList p{
    margin-bottom: 20px;
    line-height: 20px;
}
.container2 h3{
    margin-bottom: 20px;
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    text-align: center;
    line-height: 20px;
}
@media all and (max-width: 1107px) {
    .container2{
         padding-left: 10%;
        padding-right: 10%;
    }
}
@media all and (max-width: 961px) {
    .container2{
         padding-left: 5%;
        padding-right: 5%;
    }
    .container1Det{
       
        margin-right: 20%;
      }
}
@media all and (max-width: 769px) {
    .container2 h2{
        font-size: 30px;
    }
    .container2 p{
        font-size: 13px;
    }
    .container1Det{
       
        margin-right: 10%;
      }
      .details{
       
        font-size:38px;
    }
    .container2{
        padding-top: 38px;
        padding-bottom: 38px;
    }
  
}
@media all and (max-width: 615px) {
    
    .title{
        font-size:20px;    
    }
    .container1{
        height: 74vh;
        min-height: 461px;
    }
   
    
}
@media all and (max-width: 461px) {
    .container2{
        padding-top: 76px;
        padding-bottom: 76px;
        width: 100%;
        align-self: flex-start;
        
    }
    .container1{
        height: 62vh;
        min-height: 384px;
    }
    .contactUs, .contDonate{
        height: 46px;
        width:153px;
    }
    .contactUs p, .contDonate p{
        font-size: 15px;
    }
    .details{
        font-size: 38px;
        margin-top: 15px;
       }
      .container2 h2{
          font-size: 30px;
      }
}
@media all and (max-width: 420px) {
    .container1{
        height: 307px;
    }
    .contactUs, .contDonate{
        height: 38px;
        width:100px;
    }
    .contactUs p, .contDonate p{
        font-size: 10px;
    }
    .details{
        font-size: 23px;
        margin-top: 15px;
        line-height: 30px;
       }

       
    .container2{
        padding-top: 23px;
        padding-bottom: 30px;
    }
    .container2 h2{
        font-size: 26px;
        margin-bottom: 10px;
    }
    .title{
        font-size:14px;    
    }
    .container1Det{
        margin-left: 20px;
        margin-right: 10px;
        
      }
    //infolist
.infoList p{
    margin-bottom: 10px;
    line-height: 20px;
}
.container2 h3{
    margin-bottom: 10px;
    font-style: italic;
    font-size: 12px;
}
#jI{
    font-size: 16px;
    text-align: center;
    color: white;
    }
 #gI{
  background-color: $primaryColor;
  color: white;
  padding: 10px 10px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 14px;
 }
}