
 $primaryColor: #8d2855;
 $secondaryColor: #db5d96;
 $hoverColor:#d8b806;
 $theGrey1: #5F6162;
@mixin maxlinelength {
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   /* number of lines to show */
   -webkit-box-orient: vertical;
}
.container01{
  background-color: white;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("https://images.pexels.com/photos/6670776/pexels-photo-6670776.jpeg?auto=compress&cs=tinysrgb&w=600");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  background-size: cover;
  box-sizing: border-box;
  background-attachment: fixed;
  padding-bottom:40px;
  padding-top: 40px;
}
.container{
   box-sizing: border-box;
    height: auto;
    overflow: hidden;
    padding-left: 3%;
    padding-right: 3%;
   
}
.donateDiv3{
    background-color:$primaryColor;
     width:136px;
     height: 38px;
     display:flex;
     justify-content:center;
     align-items: center;
     margin-left: 7px;
     border-radius: 5px;
     align-self: center;
  }
  .causedivzoom .donateDiv3 p{
    color:#fff;
    font-weight:700;
   // margin:0;
    letter-spacing:0px;
    font-size: 15px;
    text-align: 'center';
  }
  .donateDiv3:hover{
    cursor: pointer;
    background-color: $hoverColor;
    
}
.causedivzoom{
    display: flex;
    flex-direction: column;
     height: auto;
     padding-bottom: 15px;
     width:23%;
     background-color:#F9F8F8;
     margin:10px;
    justify-content:center;
    transition: transform .5s ease;
    align-items: center;
    border: 1px solid #eee;
    text-decoration: none;
    color: black;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;

  }
  .ICDiv{
      background-color: #fbe8f1;
      border-radius: 80px;
      margin-top: 20px;
      padding: 20px;
      margin-bottom: 10px;
  }
  .causedivzoom img{
     width:100%;
     height:35vh;
     min-height:200px;
     object-fit:cover;
     border-top-left-radius: 5px;
     border-top-right-radius: 5px;
     border-bottom:5px solid $primaryColor;
  }

  .causesDetCont{
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;
   
   
  }
  .causesDetCont h1{
   font-size: 24px;
   color:$theGrey1;
   font-weight:500;
  
  }
  .causesDetCont p{
   width: 70%;
   text-align: center;
   font-size: 34px;
   margin-bottom:30px;
   margin-top: 2px;
   font-family: 'Roboto', sans-serif;
   -webkit-line-clamp: 4;
   @include maxlinelength();
   font-weight:700;
   color: $primaryColor;
  }
  .causesItemsCont{
    display:flex;
    flex:1;
    flex-wrap: wrap;
    padding-left:40; 
    padding-right:40;
    //justify-content: space-between;

  }
  .causedivzoom h1{
    padding-top:5px;
    font-size:22px;
    padding-right:7px;
    padding-left:7px;
    text-align: center;

  }
  .causedivzoom p{
    font-size:16px;
    padding-right:5px;
    padding-left:7px;
    margin-top:3px;
    margin-bottom:7px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.3;
    -webkit-line-clamp: 4;
    @include maxlinelength();
    text-align: center;
  }
  


  @media all and (max-width: 950px) {

     .img-hover-zoom{
    
      width:30%;
    }
    .causedivzoom{

      width:46%;
    }
    
}
@media all and (max-width: 760px) {
  
  
  .causedivzoom img{
     height:40vh; 
     min-height: 240px;   
  }
  .container{
  
     margin-top: 7px;
  }
}
@media all and (max-width: 684px) {
   .causedivzoom{
    width:45%;
  }
  .causedivzoom h1{
   font-size:22px;
    }
 
   
}
@media all and (max-width: 608px) {

  .causedivzoom img{
     height:40vh;    
     min-height: 235px;
  }
}




@media all and (max-width: 532px) {
  .container{
    
     padding-left: 2%;
     padding-right: 2%;
 }
  
  .causedivzoom{
    width:45%;
  }
  .causedivzoom h1{
   font-size:19px;
    }
    
       .causedivzoom img{
        height:36vh;   
        min-height:220px; 
     }
}

 @media all and (max-width: 456px) {
  .causesDetCont h1{
    font-size: 20px;
    margin-left: 10px;
   }
  .causesDetCont p{
    width: 100%;
    font-size: 28px;
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  .causedivzoom{
    width:46%;
  }

       .causedivzoom img{
        min-height:190px; 
        height:31vh;   
     }
    
    
}
   @media all and (max-width: 420px) {
    .causesDetCont p{
      margin-left: 10px;
      font-size: 26px;
     }
    .container{
    
      padding-left: 1%;
      padding-right: 1%;
  }
    .causedivzoom{
      width:100%;
    }
    .causedivzoom h1{
     font-size:20px;
      }
  
         .causedivzoom img{
          height:50vh;
          min-height: 300px;    
       }
   }
   @media all and (max-width: 380px) {
    .causedivzoom{
      width:100%;
    }
    .container{
    
      padding-left: 0%;
      padding-right: 0%;
  }
  .causesDetCont p{
    margin-left: 10px;
   
   }

   }
     @media all and (max-width: 342px) {
      .img-hover-zoom{
        width:100%;
       
       
       }
       .img-hover-zoom img{
        width:100%;
        height:228px;
        
       }
       .causedivzoom h1{
        font-size:20px;
         }
       
      }
