//terms
.container{
    height: auto;
    background-color: whitesmoke;
    padding:30px;
    font-family: 'Poppins', sans-serif;
}
.container2{
    background-color: white;
    padding: 30px 30px 60px 30px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
}

.subHeading{
    margin-top: 15px;
    font-size: 17px;
}
.headLine2{
  margin-top: 8px;
}
.container2 p{
    font-size: 14px;
    line-height: 23px;
}
.container2 .headLine{
    margin-top: 15px;
    margin-bottom: 8px;
    font-size: 19px;
    font-weight: 600;
    margin-left: 0px;
}
.container2 h2{
    font-size: 23px;
    text-align: center;
}
@media all and (max-width: 600px) {
    .container{
        background-color: white;
        padding:0px;
    }
}

@media all and (max-width: 525px) {
    .container{
        background-color: white;
        padding:0px;
    }
    .container2 .headLine{
        font-size: 15px;
        
    }
    .container2 p{
        font-size: 12px;
      
    }
}
@media all and (max-width: 380px) {
    .container{
        background-color: white;
        padding:0px;
    }
    .container2{
        padding: 15px 15px 30px 15px;

    }
    .container2 .headLine{
        font-size: 14px;
        margin-bottom: 4px;
        margin-top: 8px;
        
    }
    .container2 p{
        font-size: 11px;
        line-height: 19px;
      
    }
    .container2 h2{
        font-size: 14px;
        text-align: center;
    }
}
