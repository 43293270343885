$primaryColor: #FF7C59;
$secondaryColor: #FED800;
$hoverColor:#FBCD00;
$shopColor:#bfaf80;
.mainCont{
   
    margin-left: 3%;
    margin-right: 3%;
    padding-bottom: 50px; 
    min-height: 1000px;
    //font-family: 'Rubik', sans-serif;
    //font-family: 'Poppins', sans-serif;
}
.headCont{
    display: flex;
    height: 100px;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
}


.container{
    height: auto;
    display: flex;
   
}

.container1{
    display: flex;
    width: 25%;
    background-color: whitesmoke;
    flex-direction: column;

}
.container2{
    padding-top: 20px;
    height: auto;
    display: flex;
    width: 75%;
    background-color: white;
    flex-wrap: wrap;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05);
}
.backArrow{
    font-size: 30px;
}

.contRight1{
    height: 450px;
    background-color: white;
    width: 95%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.contRightNav{
    height: 60px;
    background-color: $primaryColor;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 20px;
   
}
.contRightNav p{
    font-size: 22px;
    font-weight: 700;
    color: white;
}
.contRightItems{

}

.catListCont {
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-bottom-color: whitesmoke;
    border-bottom-width: 0.5px;
    border-bottom-style: solid;
    color: black;
    font-size: 20px;   
    
}
.catListCont:hover{
    cursor: pointer;
    background-color: whitesmoke;
  }

.myItemsListCont{
    width: 22.5%;
    height: 500px;
    margin-left: 2%;
    text-decoration: none;
    padding-bottom: 10px;
    //background-color: yellow;
    margin-bottom: 30px;
    //margin-top: -200px;
   
  }
 .myItemsListCont:hover{
    
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05);
 }
  .imageDiv{
    
    height: 350px;
    border-bottom-color: whitesmoke;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    position: relative;
  }
  .theImage{
    height: 70%;
    //border-radius: 20px;
 }
 .clothListDetCont{
    display: flex;
    flex-direction: column;
    height: 100px;
    align-items: center;
    //margin-bottom: 20px;
}
.clothListDetCont p{
    font-size:22px;
    font-weight: 400;
     padding-top: 10px;
     color: #000;
     font-family: 'Rubik', sans-serif;
    // font-family: 'Poppins', sans-serif;
     //font-family: 'Ubuntu', sans-serif;
     
}
.clothListDetCont h4{
  font-size:21px;
  font-weight: 500;
  padding-top: 0px;
  //padding-bottom: 10px;
  color: $primaryColor;
  font-family: 'Poppins', sans-serif;
  //font-family: 'Ubuntu', sans-serif;
}
.addToCart{
    min-height: 40px;
    border-radius: 20px;
    width: 150px;
    display: flex;
    align-items: center;
    background-color: black;
    align-self: center;
    justify-content: center;
  }
  .addToCart:hover{
      background-color: $primaryColor;
  }
  
  .addToCart h6{
      font-size: 15px;
      color:white
  }
  .cartIcon{
      //background-color:$shopColor;
      color: white;
      padding: 10px;
      font-size: 20px;
      margin-right: 3px;
      border-style: solid;
      border-color: whitesmoke;
      border-width: 1px;
  }
  .cartIcon:hover{
      background-color: whitesmoke;
      color: $shopColor;
      cursor: pointer;
  }
  .ratingDiv{
    padding-top: 10px;
  }
  .ratingIcon{
      color: rgb(255, 238, 0);
      
  }
  .headCont1{
      display: flex;
      //padding-top: 5px;
     
  }
  .headCont1 p{
      margin-top: 4px;
     
  }
  .headCont1:hover{
    color: $primaryColor;
    cursor: pointer;
  }
  .contRight2{
      box-sizing: border-box;
      height: 400px;
      background-color: white;
      width: 95%;
      padding: 10px 20px 10px 20px;
      //border-top-left-radius: 10px;
      //border-top-right-radius: 10px;
      margin-top: 30px;
  }
  .contRight3{
    box-sizing: border-box;
    height: auto;
    background-color: white;
    width: 95%;
    padding: 10px 20px 10px 20px;
    margin-top: 30px;
    padding-bottom: 20px;
  }
  .minPriceCont{
      height: 50px;
      border-style: solid;
      border-color: whitesmoke;
      border-width: 1px;
      display: flex;
      margin-bottom: 20px;
    
  }
  .maxPriceCont{
    height: 50px;
    border-style: solid;
    border-color: whitesmoke;
    border-width: 1px;
    display: flex;
       
}
.filterPrice{
    height: 60px;
    background-color: black;
    margin-right: 20px;
    margin-top: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
}
.resetPrice{
    box-sizing: border-box;
    height: 60px;
    margin-top: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    color: black;
}
.resetPrice h3{
   
    font-size: 20px;
   
    //letter-spacing: 3px;
}
.resetPrice:hover{
    cursor: pointer;
    background-color: $primaryColor;
    color: white;
    border-style: solid;
    border-color: $primaryColor;
    border-width: 1px;
}
.filterPrice h3{
    color: white;
    font-size: 20px;
    //letter-spacing: 3px;
}
.filterPrice:hover{
    cursor: pointer;
    background-color: $primaryColor;
}
.contRight2 h4{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
}
.contRight2 p{
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
}
.inputPrice{
    outline: none;
    border-style: none;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 18px;
}
.inputPriceP{
    font-size: 18px;
    color: rgb(206, 206, 206);
    margin-left: 10px;
    margin-right: 10px;
}
.sortDiv1{
    height: 50px;
    border-style: solid;
    border-width: 1px;
    border-color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    
}
.sortDiv1 h6{
    font-size: 18px;
    font-weight: 500;
}
.sortIcon{
    font-size: 25px;
}
.sortDiv2{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    
}
.sortDiv2 h6{
    font-size: 18px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 500;
}
.sortDiv2 h6:hover{
    color: white;
   background-color: $primaryColor;
   cursor: pointer;
}
.contRight3 h4{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
}
.sortDiv1:hover{
    cursor: pointer;
}
.filterDiv{
    display: flex;
    
}


