
//shop nav
$primaryColor: #eb3e32;
$secondaryColor: #FED800;
$hoverColor:#FBCD00;
$shopColor:#bfaf80;
body{
    margin:0;
    padding: 0;
}

.shopNav{
    box-sizing: border-box;
    z-index: 1;
    background-color: black;
    border-bottom-width: 2px;
    border-bottom-color: $primaryColor;
    border-bottom-style: solid;
    display: flex;
    flex-direction: column;
    margin:0px;
    padding: 0px;
    
}
.shopNav2{
    box-sizing: border-box;
    height: 10vh;
    min-width: 60px;
    padding-left: 4%;
    padding-right: 4%;
    display: flex;
    align-items: center;
   // box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
    z-index: 1;
    //justify-content: space-between;
    //background-color: yellow;
    padding-bottom: 0px;
    margin-bottom: 0px;
   
}
.shopDiv{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    //background-color: yellow;
}
.shopScrolled{
    width: 100%;
    position: fixed;
    background-color:rgba(0, 0, 0, 1);
    //opacity: 0.7;
}
.NavLogo img{
    height:38px;
    width:123px;
}

  
  .shopSearchCont{
      border-style: solid;
      border-width: 1px;
      border-color: #fff;
      flex: 1;
      height: 38px;
      border-radius: 23px 0em 0em 23px;
      display: flex;
      align-items: center;
      padding-left: 15px;
      margin-left: 5%;
      background-color: #fff;
  }
  .shopSearchCont p{
      margin-left: 7px;
      color: #000;
      font-size: 13px;
     
  }
  .shopSearch{
      height: 42px;
      width: 107px;
      background-color: $primaryColor;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -2px;
      margin-right: 5%;
      border-radius: 0em 23px 23px 0em;
     
  }
  .shopSearch h4{
    color: white;
    font-size: 13px;
    font-weight: 700;
  }
  .rightCont{
      display: flex;
      height: 38px;
      align-items: center;
    // background-color: yellow;
      
  }
  .rightCont p{
      font-size: 15px;
      font-weight: 700;
  }
  .helpDiv{
      display: flex;
      align-items: center;
      color: white;
      text-decoration: none;
  }
  .helpDiv p{
    font-size: 20px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
   
  }
  .helpDiv:hover{
   cursor: pointer;
  }
  .helpDiv:hover{
  color: $primaryColor;  
   }
   .accountIc{
    
    font-size:20px;
    margin-right:23px;
   }
   .accountCont{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.0);
    height: 100%;
    min-height: 100vh;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
   }
   .accountDiv{
       height: 215px;
       width: 200px;
       background-color: white;
       margin-top: 40px;
       box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
       border-radius: 10px;
       overflow: hidden;
       margin-right: 7%;
       
   }
   .accMenu{
       text-decoration: none;
       display: flex;
       padding-top: 10px;
       padding-bottom: 10px;
       overflow: hidden;
       align-items: center;
   }
   .logOutNav{
    text-decoration: none;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    border-top-color: whitesmoke;
    border-top-width: 2px;
    border-top-style: solid;
   }
   .logOutNav p{
     color: $primaryColor;
     font-size: 16px;
     font-weight: 600;
     font-family: 'Poppins', sans-serif;
   }
   .logOutNav p:hover{
     color: $hoverColor;
   }
   .accMenu:hover{
     background-color: whitesmoke;
   }
   .accIcons{
       color: black;
       font-size: 16px;
       margin-left:10px;
       margin-right: 15px;
   }
   .accIcons1{
    color: black;
    font-size: 20px;
    margin-left:10px;
    margin-right: 10px;
   }
   .accMenu p{
       color: black;
       font-size: 14px;
       font-family: 'Poppins', sans-serif;
   }
  .cartDiv{
    display: flex;
    text-decoration: none;
    align-items: center;
    color: white;
    position: relative;
    //background-color: yellow;
    height: 53px;
}
.cartDiv p{
   font-size: 20px;
   font-weight: 400;
   font-family: 'Poppins', sans-serif;
}
.cartDiv2{
background-color: red;
height: 23px;
width: 23px;
position: absolute;
top: 0;
display: flex;
align-items: center;
justify-content: center;
border-radius: 38px;
margin-left: 7px;
}
.cartDiv2 h2{
 font-size: 13px;
}
.acProfile{
    height: 30px;
    width: 30px;
    background-color: white;
    border-radius: 38px;
    margin-right: 15px;
    display: none;
}
.cartIc{
    font-size:23px;
    margin-right:7px;   
}
.cartDiv:hover{
    color: $primaryColor;
    cursor: pointer;
}
.shopSearchCont2A{
    display: flex;
    margin-top: 7px;
}
.shopSearchCont2{
    box-sizing: border-box;
    display: flex;
    flex: 1;
    background-color: white;
    height: 42px;
    margin-left: 3%;
    margin-right: 3%;
    border-radius: 30px;
    justify-content: space-between;
    display: none;
   
}
.shopSearchCont1{
    display: flex;
    flex: 1;
    //background-color: black;
    height: 42px;
    margin-left: 3%;
    margin-right: 10%;
    //border-radius: 30px;
    justify-content: space-between;
    display: flex;
   
}
.shopSearch2{
    display: flex;
    align-self: center;
    background-color: white;
    flex: 1;
    margin-right: 15px;
    height: 42px;
    align-items: center;
    border-radius: 15px;
    box-sizing: border-box;
    text-decoration: none;
  
}
.shopSearch2B{
    display: flex;
    align-self: center;
    background-color: white;
    flex: 1;
    margin-right: 15px;
    height: 42px;
    align-items: center;
    border-radius: 15px;
    box-sizing: border-box;
  
}
.shopSearch3B{
    height: 100%;
    background-color: $primaryColor;
    width: 61px;
    border-radius: 0em 15px 15px 0em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
.shopSearch2:hover{
 cursor:text;
}
.input{
    flex:1;
    margin-right: 15px;
    border: none;
    font-size: 15px;
    outline: none;
}
.input2{
    box-sizing: border-box;
    flex:1;
   // margin-right: 15px;
    border: none;
    font-size: 15px;
    outline: none;
   // margin-left: 15px;
}
.shopSearch3{
    height: 100%;
    background-color: $primaryColor;
    width: 92px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
.shopSearch3 h3{
  font-size: 12px;
  color: white;
}
.shopSearch3:hover{
 background-color: $hoverColor;
 cursor: pointer;
}
.loginCont{
    display: none;
    font-size: 16px;
    margin-right: 38px;
}
.loginCont p{
    display: flex;
    color: white;
    font-weight: 600;
    margin-right: 3px;
    margin-left: 3px;
}
.loginCont p:hover{
   cursor: pointer;
   color: $primaryColor;
}
.signindivider{
    margin-top: 1px;
}

.cart2{
font-size:23px;
margin-right:7px;
color:#fff
}
/*.divCart2{
    background-color: yellow;
    display: flex;
    align-items: center;
    position: relative;
    width: 70px;
}*/
.newLogIn{
    height: 153px;
    width: 192px;
    background-color: white;
    position: absolute;
    z-index: 1;
    right: 0;
    margin-right: 123px;
    margin-top: 38px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
    border-radius: 7px;
    display: none;
}
.accMenu:hover{
    cursor: pointer;
}
.logOutNav:hover{
    cursor: pointer;
}
.searchDiv{
    display: none;
}

@media all and (max-width: 869px) {
  
    .shopSearchCont1{
        display: none;
    }
.shopSearchCont p{
    font-size: 12px;
}
.shopSearch{
    height: 42px;
    width: 76px;
}
.shopSearch h4{
    font-size: 11px;
}
.helpDiv{
   // display: none;
}
.shopNav2{
   justify-content: space-between;
}
.shopSearchCont2{
    display: flex;
    margin-bottom: 3px;
}
}
@media all and (max-width: 538px) {
    .helpDiv p{
        font-size: 16px;
        color: white;
      }
      .cartDiv p{
        font-size: 16px;
     }

.shopNav2{
    justify-content: space-between;
    margin-right: 15px;
    height: 8vh;
    min-width: 48px;
}
.NavLogo img{
    height:30px;
    width:115px;
}
.shopNav{
    font-family: 'Roboto', sans-serif;
    //display: none;
}
}
@media all and (max-width: 461px) {
 .shopSearchCont2{
     height: 34px;
 }
 .shopSearch2B{
   
    margin-right: 0em;
    height: 34px;
    border-radius: 7px;
  
}
.loginCont{
    display: none;
    font-size: 13px;
    margin-right: 38px;
}
.shopSearchCont2A{
    display: flex;
    margin-top: 0em;
}
}
@media all and (max-width: 384px) {
    .shopSearchCont2A{
        display: none;
        margin-top: 0px;
    }
    .helpDiv{
        display: none;
    }
    .searchDiv{
        display: flex;
    }
    .NavLogo img{
        height:35px;
        width:92px;
    }
    .shopSearchCont2{
        height: 30px;
        box-sizing: border-box;
        //width: 70%;
       // background-color: yellowgreen;
    }
    .shopSearch2B{
      
       margin-right: 0em;
       height: 30px;
       border-radius: 7px;
     
   }
   .loginCont{
       display: none;
       font-size: 13px;
       margin-right: 38px;
   }
   .shopSearch3B{
    width: 38px;
    border-radius: 0em 7px 7px 0em;
}
.cartDiv p{
    display: none;
}

   }





   @media all and (max-width: 307px) {
       .shopNav{
        padding-top: 0px;
        padding-bottom: 3px;
       }
    .NavLogo img{
        height:23px;
        width:76px;
    }
    .cartDiv2{
        height: 19px;
        width: 19px;
         margin-top: 1px;
        margin-left: 7px;
        }
        .cartDiv2 h2{
         font-size: 12px;
        }
        .cartIc{
            font-size:19px;
            margin-right:0em;   
        }

   }


