$primaryColor: #FF7C59;

.mainContainer{
    background-color: whitesmoke;
    font-family: 'Poppins', sans-serif;
}
.container1{
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    padding: 30px 50px;
}
.container1 h2{
 margin-top: 10px;
 font-size: 16px;
}
.imgDiv{
  position: relative;
  height:80vh;
 }
 .imgDiv img{
   width:100%;
   height:80vh;
   min-height: 310px;
   object-fit:cover;
 }
 .imgDiv h1{
  font-size: 60px;
  color: black;
  font-family: 'Teko', sans-serif;
  z-index: 8;
  position: absolute;
  color: white;
  bottom: 0;
  background-color: black;
  margin-bottom:-30px;
  padding: 5px 20px;
  margin-left: 40px;
  font-weight: 500;
 }

.subTitle{
    padding-bottom: 10px;
    font-size: 16px;
    margin-top: 20px;
    
}
.princDiv{
  margin-top: 30px;
  //box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
 // padding: 20px 0px 20px 20px;
}
.subTitle2{
    font-size: 20px;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
}
.sellCont{
    display: flex;
    overflow: hidden;
}
.sellCont1{
    display: flex;
    justify-content:center;
    align-items: center;
    width: 50%;
    
}
.sellCont2{
    display: flex;
    flex-direction: column;
    justify-content:center;
    width: 50%;
    padding: 30px;
}
#sellContB{
    padding: 10px 10px 10px 0px;
}
.sellCont2 h3{
    font-size: 20px;
    margin-bottom: 10px;
}
.sellCont2 p{
    font-size: 18px;
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
}

.sellImg{
    height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding-top: 7px;
    //padding-bottom: 7px;
   
  }
  .sellImg img{
    height: 95%;
  }

  #sellContA{
      order: 2;
  }
  #sellContB{
    order: 1;
}
  @media all and (max-width: 900px) {
    .sellCont2 h3{
        font-size: 20px;
        margin-bottom: 10px;
    }
    .sellCont2 p{
        font-size: 14px;
        margin-bottom: 10px;
    }

  }
  @media all and (max-width: 700px) {
    .sellCont{
       flex-direction: column;
    }
    .sellCont1{
        width: 99%;
       // border: 1px solid rgb(235, 235, 235);
        //background-color:white;
       // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05);
    }
    .sellCont2{
        width: 98%;
        padding: 30px 5px 30px 5px;
       // padding-top: 30px;
    }
    #sellContA{
        order: 1;
    }
    #sellContB{
      order: 2;
  }
  .sellImg{
    height: 360px;
    width: 100%;
  }
  .sellImg img{
    height: 100%;
  }
 
  .sellCont2 h3{
    font-size: 18px;
    margin-bottom: 10px;
}
.sellCont2 p{
    font-size: 12px;
    margin-bottom: 10px;
}
  }
  @media all and (max-width: 500px) {

  .sellImg{
    height: 300px;
    width: 100%;
  }
  .sellImg img{
    height: 100%;
  }

  .imgDiv{
    position: relative;
    height:55vh;
   }
   .imgDiv img{
     width:100%;
     height:55vh;
     min-height: 300px;
     object-fit:cover;
   }
  .imgDiv h1{
    font-size: 30px;
    margin-bottom:-30px;
    padding: 5px 40px;
    margin-left: 10px;
   }
  }
  @media all and (max-width: 400px) {

    .sellCont2{
        width: 98%;
        padding: 10px 5px 20px 5px;
       // padding-top: 30px;
    }
    .container1{
      padding: 30px 10px;
  }
  }