$primaryColor: #FF7C59;
$hoverColor:#FBCD00;
.container{
    box-sizing: border-box;
    height: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding: 20px 20% 50px 20%;
    background-color: whitesmoke;

}
.div1{
    background-color: white;
    height: auto;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
    padding: 20px 10% 50px 10%;
}
.tit1{
    margin-top: 30px;
    margin-bottom: 30px;
}
.inputDiv{
 border: 1px solid rgb(173, 173, 173);
 display: flex;
 justify-content: space-between;
 padding-left: 10px;
 padding-right: 10px;
 padding-top: 5px;
 padding-bottom: 5px;
 margin-bottom: 20px;
}
.inputDiv input{
    border: none;
    outline: none;
}
.inputDiv1{
    border: 1px solid rgb(173, 173, 173);
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  
   
   }
   .inputDiv1 input{
       border: none;
       outline: none;
   }
   .inputDiv1:hover{
       cursor: pointer;
   }
   .catP{
       font-size: 14px;
       border-bottom: 1px solid whitesmoke;
       padding: 10px 10px 10px 10px;
   }
.title1{
    font-size: 14px;
    padding-bottom: 5px;
    margin-top: 20px;
}
.dropFilesDiv h4{
    margin-top: 50px;
}
.addImagesDiv{
    margin-top: 20px;
    height: 45px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.addImagesDiv label{
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    background-color: black;
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

  .addImagesDiv label:hover{
    cursor: pointer;
  }
  .secImageCont2{
    height: auto;
    display: flex;
    flex-wrap: wrap;
}
.photoInfoDiv{
    display: flex;
    height: auto;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: whitesmoke;
    justify-content: space-between;
    align-items: center;
    background-color: white;
   
}
#photoDel:hover{
    cursor: pointer;
  }
  #photoDel{
    //height: 50px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}
#photoDel h5{
    margin: 0px;
    padding: 10px 5px;
    font-size: 12px;
    font-weight: 500;
    color: $primaryColor;
}

.photoInfoDiv p{
    font-weight: 400;
}
#photoPro{
    //height: 50px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: whitesmoke;//1612903772854azerd16
    color: black; 
}
#photoPro h5{
    margin: 0px;
    padding: 10px 5px;
    font-size: 12px;
    font-weight: 500;
}
#photoPro:hover{
    cursor: pointer;
}
.photosListDiv{
    flex: 0 0 auto;
    box-sizing: border-box;
    width: 30%;
    background-color:white;
    height: auto;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05);
   
}
.photosListDiv h4{
      position: absolute;
      top: 0;
      margin-top: 20px;
      margin-left: 20px;
      background-color: $primaryColor;
      color: white;
      padding: 5px 20px 5px 20px;
      border-radius: 30px;
      font-weight: 500;
}
.homeImgDiv{
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .homeProImg{
    height: 90%;
  }
  .modalDiv{
      height: auto;
      width: 100%;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05);
      padding-bottom: 20px;
  }
  .catP:hover{
      cursor: pointer;
      background-color: whitesmoke;
  }