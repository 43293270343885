//cart module
$primaryColor: #eb3e32;
$secondaryColor: #FED800;
$hoverColor:#FBCD00;
$shopColor:#bfaf80;
@mixin maxlinelength {
    overflow: hidden;
     text-overflow: ellipsis;
     display: -webkit-box;
     /* number of lines to show */
     -webkit-box-orient: vertical;
  }
.mainContainer{
    background-color: whitesmoke;
    box-sizing: border-box;
    //font-family: 'Roboto', sans-serif;
    font-family: 'Poppins', sans-serif;
}
.container{
    box-sizing: border-box;
    height: auto;
    min-height: 300px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-left: 15%;
    padding-right: 15%;
    //background-color: $primaryColor;
}
.checkOutCont{
    box-sizing: border-box;
    height: 70px;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
    margin-top: 76px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5%;
    margin-bottom: 76px;
   
}
.checkOutCont1{
    height: 38px;
    width: 246px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
    color: $primaryColor;
    margin-right: 15px;
}
.checkOutCont2{
    height: 38px;
    width: 246px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
    background-color: $primaryColor;
    color: white;
    text-decoration: none;
}
.checkOutCont1:hover{
    color: $hoverColor;
    cursor: pointer;
}
.checkOutCont2:hover{
    background-color: $hoverColor;
    cursor: pointer;
}
.cont1{
   // background-color: yellow;
   // margin-left: 15%;
    //margin-right: 15%;
}
.cont2{
    //background-color: $hoverColor;
    height: 23px;
    display: flex;
    padding-left: 7px;
}
.cont2 h1{
  width: 55%;  
  padding-top: 3px;
  font-size: 15px;
  color: rgb(158, 158, 158);
}
.cont2 h2{
    width: 15%;
    text-align: center;
    padding-top: 3px;
    font-size: 15px;
    color: rgb(158, 158, 158);
}
.title{
    //margin-left: 15%;
    padding-top: 38px;
    padding-bottom: 23px;
    font-size: 26px;
}
.cont3{
    margin-top: 7px;
    margin-bottom: 38px;
}
.itemsCont0{
    margin-bottom: 7px;
}
.itemsCont{
    height: auto;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
    background-color: white;
    display: flex;
   
}
.extraDiv{
    height: 30px;
    background-color: white;
    display: none;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    border-top-style: solid;
    border-top-color: whitesmoke;
    border-top-width: 1px;
}
.extraDivItems{
    display: flex;
}

.itemsCont1{
 width: 55%;
 display: flex;
 
 //background-color: whitesmoke;
}
.itemsCont2{
    width: 15%;
    //background-color: whitesmoke;
    border-left-color: whitesmoke;
    border-left-width:1px;
    border-left-style: solid;
    display: flex;
    //flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.qutP{
    font-size: 23px;
    margin-right: 15px;
}
.imgDiv{
    width: 115px;
    height: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right-style: solid;
    border-right-color: whitesmoke;
    border-right-width: 1px;
   // background-color: yellowgreen;
}
.imgDiv img{
  width: 70%;
  //background-color: yellow;
}
.calcDiv{
    height: auto;
   // background-color: $hoverColor;
    //margin-bottom: 38px;
    display: flex;
    justify-content: flex-end;
}
.calcDiv1{
    display: flex;
    justify-content: flex-end;
}
.subTotP{
    font-size: 17px;
   
}
.vatP{
    font-size: 17px;
    margin-top: 11px;
    color: rgb(182, 163, 129);
}
.totalP{
    font-size: 19px;
    margin-top: 15px;
    font-weight: 700;
}
.calcDiv3{
    margin-left: 38px;
}
.quantityIconsSub{
    font-size: 19px;
    margin-bottom: 7px;
    
}
.quantityIconsSub:hover{
    color: $primaryColor;
    cursor: pointer;
    
}
.quantityIconsAdd{
    font-size: 19px;
   
}
.quantityIconsAdd:hover{
    color: $primaryColor;
    cursor: pointer;
}
.quantityIcons{
    display: flex;
    flex-direction: column;
}
.moveIconsDiv{
    display: flex;
    height: 30px;
    align-items: center;
    margin-top: 7px;
}
.moveIconsDiv p{
    font-size: 13px;
    font-weight: 500;
}
.moveIcon1{
    font-size: 15px;
    margin-right: 7px;
}
.likeDiv,.deleteDiv{
    display: flex;
    height: 23px;
    align-items: center;
    margin-right: 15px;
    color: $primaryColor;
}
.likeDiv:hover,.deleteDiv:hover{
    color: $hoverColor;
    cursor: pointer;
}
.moveIcon2{
    font-size: 15px;
    margin-right: 7px;
}
.proDetDiv{
    height: 115px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
}
.proTitleP{
  font-size: 16px;
  font-weight: 550;
}
.proTitleP2{
  font-size: 13px;
  margin-top: 3px;
  font-weight: 500;
  color: rgba(145, 143, 143, 0.933);
}
.subTot{
    font-size: 16px;
    font-weight: 500;
    color: $primaryColor;
}


@media all and (max-width: 1200px) {

    .container{
     padding-right: 10%;
     padding-left: 10%;
    }
    .likeDiv p{
      font-size: 12px;
    }
    .deleteDiv p{
        font-size: 12px;
    }
}
@media all and (max-width: 900px) {

    .container{
     padding-right: 5%;
     padding-left: 5%;
     //background-color: $primaryColor;
    }
    
}

@media all and (max-width: 700px) {

    .container{
     padding-right: 20px;
     padding-left: 20px;
     //background-color: $primaryColor;
    }
   /* .likeDiv p{
        font-size: 10px;
      }
      .deleteDiv p{
          font-size: 10px;
      }*/
    
}


@media all and (max-width: 600px) {
    .container{
        padding-right: 20px;
        padding-left: 20px;
        //background-color: $primaryColor;
       }


       .checkOutCont{
        box-sizing: border-box;
        height: 70px;
        margin-top: 40px;
        padding-right: 10px;
        padding-left: 10PX;
        margin-bottom: 70px;
       
    }
    .checkOutCont1{
        height: 38px;
        width: 246px;
        font-size: 16px;
        margin-right: 15px;
    }
    .checkOutCont2{
        font-size: 16px;
    }
   .title{
       font-size: 22px;
       padding-top: 15px;
       padding-bottom: 5px;
   }

       .extraDiv{
     display: flex;
    }
    .imgDiv{
        width: 90px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        //background-color: yellowgreen;
    }
    .imgDiv img{
      width: 70%;
      //background-color: yellow;
    }

//items

    .moveIconsDiv{
        height: 30px;
        margin-top: 2px;
    }
    .moveIconsDiv p{
        font-size: 12px;
        font-weight: 500;
    }
    .minus{
     color: white;
     background-color: $primaryColor;
     padding: 5px;
     border-radius: 20px;
     font-size: 12px;
     margin-right: 10px;
    }
    .plus{
        color: white;
        background-color: $primaryColor;
        padding: 5px;
        border-radius: 20px;
        font-size: 12px;
    }
    .extraDivItems h2{
        font-size: 16px;
        margin-right: 10px;
    }
    #itemsCont2{
        display: none;
    }
    .itemsCont1{
        width: 60%;
       }
       .itemsCont2{
        width: 20%;
       }
       .QuaP{
        display: none;
       }

       .cont2 h1{
        width: 60%;  
      }
      .cont2 h2{
          width: 20%;
          font-size: 16px;
      }
}

@media all and (max-width: 400px) {

    .container{
     padding-right: 10px;
     padding-left: 10px;
     background-color: whitesmoke;
    }
    .itemsCont{
        height: 90px;
    }
    .imgDiv{
        width: 70px;
        height: 90px;
    }
    .imgDiv img{
        width: 90%;
      }
    .proTitleP{
        font-size: 14px;
        font-weight: 550;
        -webkit-line-clamp: 2;
        @include maxlinelength();
        line-height: 15px;
      }
      .proTitleP2{
        font-size: 12px;
        margin-top: 1px;
        -webkit-line-clamp: 1;
        @include maxlinelength();
      }
      .likeDiv,.deleteDiv{
        display: flex;
        height: 23px;
        align-items: center;
        margin-right: 5px;
        color: $primaryColor;
    }
    .moveIconsDiv{
        height: 30px;
        margin-top: 2px;
    }
    .moveIconsDiv p{
        font-size: 12px;
        font-weight: 500;
    }
    .minus{
     color: white;
     background-color: $primaryColor;
     padding: 5px;
     border-radius: 20px;
     font-size: 12px;
     margin-right: 10px;
    }
    .plus{
        color: white;
        background-color: $primaryColor;
        padding: 5px;
        border-radius: 20px;
        font-size: 12px;
    }
    .extraDivItems h2{
        font-size: 16px;
        margin-right: 10px;
    }
    #itemsCont2{
        display: none;
    }
    .itemsCont1{
        width: 60%;
       }
       .itemsCont2{
        width: 20%;
       }
   .QuaP{
    display: none;
   }
   .cont2 h1{
    width: 60%;  
  }
  .cont2 h2{
      width: 20%;
      font-size: 12px;
  }
  .itemsCont2 p{
      text-align: center;
      margin: 4px;
      font-size: 12px;
  }

  .subTotP{
    font-size: 14px;
}
  .vatP{
    font-size: 14px;
    margin-top: 5px;
    color: rgb(182, 163, 129);
}
.totalP{
    font-size: 14px;
    margin-top: 5px;
    font-weight: 700;
}
.calcDiv3{
    margin-left: 15px;
}


.checkOutCont{
    box-sizing: border-box;
    height: 50px;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 30px;
    margin-top: 30px;
   
}
.checkOutCont1{
    height: 30px;
    width: 50%;
    font-size: 14px;
    margin-right: 5px;
}
.checkOutCont2{
    height: 30px;
    width: 50%;
    font-size: 14px;
   
}
.title{
    //margin-left: 15%;
    padding-top: 15px;
    padding-bottom: 10px;
    font-size: 20px;
}
.proDetDiv{
   // height: 115px;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 10%;
    margin-left: 5px;
    margin-right: 5px;
}

    }

    @media all and (max-width: 350px) {

        .checkOutCont1{
            height: 30px;
            width: 50%;
            font-size: 12px;
            margin-right: 5px;
        }
        .checkOutCont2{
            height: 30px;
            width: 50%;
            font-size: 12px;
           
        }
    }