//home donors
$primaryColor: #eb3e32;
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;700&display=swap');
.bodySection4{
  box-sizing: border-box;
    height: auto;
    overflow: hidden;
    background-color: white;
    padding-bottom: 38px;
    padding-top: 38px;
    padding-left: 2%;
    padding-right: 2%;
}

  .imghoverzoom:hover {
    cursor: pointer;
    transform: scale(1.03);
  }
  .donorsVolCont{
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;
   
  }
  .donorsVolCont h1{
    font-size: 45px;
    color: black;
    font-family: 'Teko', sans-serif;
    line-height: 0.8;
    text-align: center;
    margin-bottom: 20px;
  }
  .donorsVolCont p{
   width: 70%;
   text-align: center;
   font-size: 15px;
   margin-bottom: 38px;
   margin-top: 7px;
   font-family: 'Poppins', sans-serif;
  }
  .donorItemsCont{
    display:flex;
    flex:1;
    flex-wrap: wrap;
   
    justify-content: space-evenly;

  }
  .imghoverzoom{
    height:auto;
    width:22%;
    background-color:#F9F8F8;
    margin:7px;
    padding-bottom: 15px;
    transition: transform .5s ease;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
   }
   .imghoverzoom img{
     width:100%;
     height:300px;
     min-height: 50vh;
     object-fit:cover
   }

   .imghoverzoom h1{
    padding-top:7px;
    font-size:20px;
    font-weight:700;
    padding-right:7px;
    padding-left:7px;
    margin:0;
    font-family: 'Teko', sans-serif;
    line-height: 0.8;
   }
   .imghoverzoom h4{
    font-size:15px;
    padding-right:7px;
    font-weight:700;
    padding-left:7px;
    margin-top:3px;
     color:#A7A7A7;
     font-family: 'Teko', sans-serif;
    line-height: 0.8;
  }
  .imghoverzoom p{
    font-size:19px;
    padding-right:7px;
    font-weight:700;
    padding-left:7px;
    margin-top:3px;
     color:$primaryColor;
     font-family: 'Teko', sans-serif;
     line-height: 0.8;
  }
.beAdonor{
  box-sizing: border-box;
  height: 100px;
  width: 100%;
  margin-top: 30px;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 50px;
  
}
.beAdonor h3{
font-size: 40px;
margin-left: 30px;
font-family: 'Teko', sans-serif;
font-weight: 800;
}
.beAdonor p{
  width: 180px;
  background-color: $primaryColor;
  color: white;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  font-weight: 600;
  font-family: 'Teko', sans-serif;
  font-size: 22px;

}
  @media all and (max-width: 912px) {
    .donorsVolCont h1{
      font-size: 30px;
     }
     .donorsVolCont p{
      font-size: 12px;
     }
     .imghoverzoom{
    
      width:47%;
    }
    
    
}
@media all and (max-width: 608px) {
  .imghoverzoom img{
    min-height: 38vh;
    height:230px;
  }
    
}

@media all and (max-width: 585px) {
  
    .donorsVolCont h1{
      margin-top: -15px;
      font-size: 30px;
     }
     .donorsVolCont p{
      font-size: 11px;
      margin-bottom: 15px;
     }
.imghoverzoom{
 
  width:47%;
  margin:3px;
 
 }
 .imghoverzoom img{
  width:100%;
  height:38vh;
  min-height:230px;
  
 }
 .imghoverzoom h1{
  font-size:19px;
 }
 .imghoverzoom h4{
  font-size:13px;
  
}
.imghoverzoom p{
  font-size:16px;
  
}}

 @media all and (max-width: 494px) {
  .imghoverzoom{
    width:47%;
    margin:3px;
   
   }
   .imghoverzoom img{
    width:100%;
    min-height:190px;
    height: 31vh;
    
   }}
   @media all and (max-width: 380px) {
    .imghoverzoom{
      width:100%;
      margin:0px;
     
     }
     .imghoverzoom img{
      width:100%;
      min-height:250px;
      height: 45vh;
      
     }
     .donorsVolCont{
      display:flex;
      flex-direction:column;
      align-items:center;
      width:100%;
     
    }
    .donorsVolCont h1{
      width: 100%;
      text-align: left;
      margin-left: 10px;
      line-height: 30px;
     
    }
    .donorsVolCont p{
     width: 98%;
     text-align: left;
     margin-bottom: 20px;
     font-size: 16px;
     padding-left: 10px;
     padding-right: 10px;
     
    }
    .imghoverzoom h1{
      font-size: 25px;
     }
     .imghoverzoom h4{
      font-size:20px;
    
    }
    .imghoverzoom p{
      font-size:20px;
  
    }
    }
     @media all and (max-width: 342px) {
      
       .imghoverzoom img{
        width:100%;
        height:38vh;
        min-height: 230px;
       }
       .imghoverzoom h1{
        font-size: 20px;
       }
       .imghoverzoom h4{
        font-size:18px;
      
      }
      .imghoverzoom p{
        font-size:18px;
    
      }
      .donorsVolCont p{
        font-size: 14px;
       }
      }
