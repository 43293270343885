$primaryColor: #FF7C59;
$secondaryColor: #FED800;
$hoverColor:#FBCD00;
$shopColor:#bfaf80;
.container{
    height: auto;
    display: flex;
    justify-content: center;
    padding: 50px 0px 50px 0px;
}
.container1{
    width: 700px;
    height: auto;
    min-height: 800px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05);
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
    
}
.contLogIn{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    display: none;

}
.contLogInReg{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
  
}
.contLogInReg h6{
    text-align: center;
}
.contLogInReg h5{
    align-self: flex-end;
    margin-right: 20px;
    margin-top: 10px;
    font-weight: 500;
    font-size: 18px;
    color: $primaryColor;
}
.contLogInReg h5:hover{
color: $hoverColor;
cursor: pointer;
}
.contLogInReg break{

}
.contLogIn h3, .contLogInReg h3{
  font-size: 40px;
}
.contLogIn h6,.contLogInReg h6{
    font-size: 20px;
    font-weight: 400;
    padding-top: 20px;
  }
  .imgDiv{
      width: 150px;
      height:150px;
      overflow: hidden;
      align-self: center;
      margin-top: 100px;

  }
  .theImage{
      height: 100%;
  }
  .contLogInDet{
      //background-color: red;
      flex: 1;
      width: 90%;
      display: flex;
      flex-direction: column;
  }
  .contLogInDet p{
      margin-top: 30px;
      margin-bottom: 10px;
      font-size: 20px;
  }
  .input{
      box-sizing: border-box;
      width: 98%;
      padding: 15px 20px 15px 20px;
      outline: none;
      border: none;
      border-color: whitesmoke;
      border: 2px solid whitesmoke;
      font-size: 20px;
  }
  .divLogIn{
    box-sizing: border-box;
    width: 90%;
    height: 60px;
    background-color: black;
    font-size: 20px;
    margin-top: 70px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
}
.divLogIn h3{
    color: white;
    font-size: 25px;
}
.divLogIn:hover{
    cursor: pointer;
    background-color: $primaryColor;
}
.reg{
    color: $primaryColor;
    font-size: 20px;
}
.reg:hover{
    color: $hoverColor;
    cursor: pointer;
}
@media all and (max-width: 800px) {
   .container{
       background-color: white;
   }
   .container1{
       box-shadow: none;
   }
   .imgDiv{
    margin-top: 50px;

}
    }
    @media all and (max-width: 600px) {
       
        .imgDiv{
         margin-top: 30px;
     
     }
         }
         @media all and (max-width: 500px) {
       
            .imgDiv{
             margin-top: 10px;
             width: 100px;
             height:100px;
         }
         .contLogInReg h3{
            font-size: 30px;
          }
             }