
*{
  margin: 0;
 
}
html,body{
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}
body{

	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  .appCont1{
	  background-color: black;
	  box-sizing: border-box;
	  padding-left:30px;
	  padding-right:30px;
	  padding-top:20px;
	  padding-bottom:20px;
	  display:flex;
	  flex-direction:row;
	  align-items:center
  }
  #appCont2{
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	flex:0.3;
	margin-right: 5px;


}
#accP{
	color:'#fff';
	width:80px;
	background-color:#8d2855;
	padding-bottom:10px;
	padding-top:10px;
	padding-left:30px;
	padding-right:20px;
	border-width: 1px;
	border-color: #8d2855;
	border-style: solid;
	
}
#manP{
	color:#fff;
	width:80px;
	padding-bottom:10px;
	padding-top:10px;
	padding-left:30px;
	padding-right:20px;
	border-width: 1px;
	border-color: #fff;
	border-style: solid;
	margin-left:10px;

}
#appP1{
	color: #8d2855;
	font-weight:700;
	font-size: 18px;
}
#appP2{
	color: #fff;
	font-weight:300;
	font-size: 14px;
	margin-top: 5px;
}
#appP3{
	color: #fff;
	font-weight:300;
	font-size: 14px;
	margin-top: 5px;
}
@media all and (max-width: 600px) {
	#appCont2{
		flex-direction: column;
	}
	#manP{
		margin-left:0px;
	    margin-top: 10px;
	}
}
@media all and (max-width: 420px) {
	.appCont1{
		background-color: black;
		box-sizing: border-box;
		padding-left:30px;
		padding-right:30px;
		padding-top:20px;
		padding-bottom:20px;
		display:flex;
		flex-direction:column;
		align-items:center
	}
	#appCont2{
		flex: 1;
		width:100%
	}
	#accP{
		color:'#fff';
		width:auto;
		padding-bottom:10px;
		padding-top:10px;
		padding-left:0px;
		padding-right:0px;
		flex: 1;
		text-align: center;
		margin-top: 20px;
		
	}
	#manP{
		color:#fff;
		width:auto;
		padding-bottom:10px;
		padding-top:10px;
		padding-left:0px;
		padding-right:0px;
		border-width: 1px;
		border-color: #fff;
		border-style: solid;
		margin-left:0px;
		text-align: center;
		margin-top: 10px;
	
	}
	.appCont1{
		padding-left:20px;
		padding-right:20px;
		padding-top:20px;
		padding-bottom:20px;
	}
}
@media all and (max-width: 350px) {
	#appCont2{
		flex-direction: row;
		margin-top: 15px;
	}
	#accP{
		color:'#fff';
		width:140px;
		max-width:150px;
		padding-bottom:10px;
		padding-top:10px;
		padding-left:0px;
		padding-right:0px;
		text-align: center;
		margin-top: 0px;
		font-size: 12px;
	}
	#manP{
		color:#fff;
		width:140px;
		max-width:150px;
		padding-bottom:10px;
		padding-top:10px;
		padding-left:0px;
		padding-right:0px;
		border-width: 1px;
		border-color: #fff;
		border-style: solid;
		margin-left:10px;
		text-align: center;
		margin-top: 0px;
		font-size: 12px;
	
	}
	#appP1{
		font-size: 14px;
	}
	#appP2{
		font-size: 12px;
		margin-top: 5px;
	}
	#appP3{
		font-size: 12px;
		margin-top: 5px;
	}
}