$primaryColor: #8d2855;
$secColor: #d8b806;
$theGrey1: #5F6162;
$hoverColor:#d8b806;
@mixin limitLine {
    display: -webkit-box;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
.container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
   
}

.container1{
    width: 100%;
    height: 85vh;
    min-height: 530px;
    overflow: hidden;
      background-repeat: no-repeat;
     background-size: 100% 100%;
    background-size: cover;
    background-position: center;
   
    box-sizing: border-box;
}
.container1B{
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
}
.container2 h2{
    font-size: 61px;
    font-family: 'Roboto', sans-serif;
    line-height: 1.2;
    margin-bottom: 20px;
    color: $primaryColor;
    font-weight: 600;
   
    
}
.container2 p{
    padding-top:1px;
    font-size: 15px;
    line-height: 1.3;
   // padding-left: 7px;
   // padding-right: 7px;
   
    
}
.container1Det{
  margin-left: 5%;
  margin-right: 40%;
  width: '100%';
  overflow: hidden;

}
.title{
    color: white;
    font-size:20px;
    font-weight:800;
    letter-spacing: 1px;
    line-height: 1.2;
    font-family: 'Rubik', sans-serif;
    margin-left: 10%;
}
.details{
    margin:0px;
    font-size:40px;
    font-weight:700;
    color:#fff;
    letter-spacing: 1px;
    font-family: 'Rubik', sans-serif;
    width: 60%;
    margin-top: 10px;
    margin-left: 10%;
}
.details2{
    font-size: 18px;
    color: white;
    font-family: 'Poppins', sans-serif;
    margin-top: 10px;
    width: 80%;
    margin-left: 10%;
    line-height: 25px;
}
.contDonateMain{
    display: flex;
    margin-left: 10%;
}
.contDonate{
    box-sizing: border-box;
    background-color:$primaryColor;
     height: 40px;
     width:176px;
     display:flex;
     justify-content:center;
     align-items: center;
     margin-top:38px;
     letter-spacing: 1px;
     font-weight:700;
     font-family: 'Rubik', sans-serif;
     border-style: solid;
     border-color: $primaryColor;
     border-width: 1px;
     text-decoration: none;
     border-radius: 5px;
  }
  .contDonate1{
    box-sizing: border-box;
    //background-color:$primaryColor;
     height: 40px;
     width:150px;
     display:flex;
     justify-content:center;
     align-items: center;
     margin-top:38px;
     letter-spacing: 1px;
     font-weight:700;
     font-family: 'Rubik', sans-serif;
     border-style: solid;
     border-color: white;
     border-width: 1px;
     text-decoration: none;
     margin-left: 20px;
     border-radius: 5px;
  }
.contDonate1:hover{
    cursor: pointer;
}
  .contDonateP{
    font-size: 14px; 
    color: white;
    text-decoration: none;

}
  .contDonate:hover{
    border-color: $hoverColor;
    cursor: pointer;
    background-color: $hoverColor;
    
}



.contactUs{
    box-sizing: border-box;
    border-style: solid;
    border-color: white;
    border-width: 1px;
     height: 53px;
     width:176px;
     display:flex;
     justify-content:center;
     align-items: center;
     margin-top:38px;
     letter-spacing: 1px;
     font-weight:700;
     font-family: 'Rubik', sans-serif;
     margin-left: 23px;
  }
  .contactUs p{
      font-size: 19px;
      color: white;
  }
  .contactUs:hover{
    border: none;
    cursor: pointer;
    background-color: black;
    
}

//infolist
.infoList p{
    margin-bottom: 20px;
    line-height: 20px;
}

.container2{
    box-sizing: border-box;
    height: auto;
   // background-color: red;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    padding-bottom: 76px;
    width: 70%;
    align-self: center;
   // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin-right: 10px;
    
}
.container2 h2{
    margin-bottom: 20px;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    //text-align: center;
    //line-height: 20px;
}
.container21{
    box-sizing: border-box;
    display: flex;
    margin-left: 60px;
    margin-right: 60px;
    padding: 20px 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin-top: 40px;
    margin-bottom: 20px;
    background-color: white;
}
.container2B{
   box-sizing: border-box;
   width:40%;
   padding: 0px 20px;
   border-left: 1px solid whitesmoke;
  
}
.infoP{
    text-align: center;
    color: white;
    font-style: italic;
}
.contRight1 h1{
    padding-top: 20px;
    margin-left: 0px;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 10px;
}
.newPostCont{
    display: flex;
    margin-bottom: 11px;
    text-decoration: none;
    border: 1px solid whitesmoke;
    //box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
   // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
   // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.newPostCont:hover{
    background-color: whitesmoke;
    cursor: pointer;
}

.newPostCont2{
    display: flex;
    //background-color: yellowgreen;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
}
.newPostCont2 h2{
    font-size: 16px;
    -webkit-line-clamp: 2;
    @include limitLine();
    font-weight: 500;
    color: black;
}
.newPostCont2 p{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $primaryColor;
    margin-top:4px;
}
.imgCont2{
    height: 70px;
    width: 70px;
    display: flex;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
}
.container3A{
    height: auto;
    background-color: $primaryColor;
    margin-bottom: 50px;
    padding-top: 0px;
    padding-bottom: 20px;
}
.container3{
  display: flex;
  padding-top: 15px;
  margin-bottom: 20px;
}
.div1{
    
    flex: 1;
    padding: 5px;
    box-sizing: border-box;
    //background-color: yellow;
    display: flex;
    flex-direction: column;
    //align-items: center;
    //justify-content: center;
}
.det1{
  font-size: 20px;
  font-weight: 800;
  color: white;
  text-align: center;
}
.det2{
  margin-top: 5px;
  font-size: 16px;
  color: white;
  text-align: center;
  font-weight: 300;
}
.det3{
    margin-top: 5px;
    font-size: 14px;
    color: white;
    text-align: center;
}
.div2{
   
    flex: 1;
    padding: 5px;
    //background-color: yellow;
    display: flex;
    flex-direction: column;
    //align-items: center;
   // justify-content: center;
}
.div3{
    padding: 5px;
    flex: 1;
    display: flex;
    flex-direction: column;
    //align-items: center;
    //justify-content: center;
}
.div3B{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 10px;
}
.div3B p{
    color: white;
    font-size: 14px;
    padding-right: 10px;
    padding-bottom: 5px;
    //margin-left: 50px;
}
.div3B ul{
 display:flex;
 color: white;
 list-style: none;
 
}
.div3B ul li{
    padding-right: 50px;
}
.container2 p{
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
}
.container50{
    padding-left: 7%;
    padding-right: 7%;
    margin-bottom: 30px;
}
.container50 h2{
    margin-bottom: 20px;
    font-size: 20px;
    font-family: 'Rubik', sans-serif;
    line-height: 30px;
}
.container5{
   
    display: flex;
}
.container6{
    padding-right: 30px;
    width:40%;
}
.container7{
    padding-left:0px;
    width:60%;
}
.container7 h4{
    margin-bottom: 20px;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    line-height: 20px;
    display: none;
}

.container7 p{
    margin-bottom: 20px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
}
.supportDiv{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.supportDiv h3{
    font-size: 18px;
    color:$primaryColor;
    font-weight: 600;
}
.supportDiv p{
    font-size: 16px;
    color:rgb(82, 82, 82);
}
.supportIc{
    font-size: 30px;
    margin-right: 20px;
}
.supportIc2{
    font-size: 25px;
    margin-right: 10px;
}
.container8{
    display: flex;
    flex-direction: column;
    margin-left: 15%;
    margin-right: 15%;
    border: 1px solid black;
    padding: 30px ;
    margin-bottom: 50px;
}
.container8 h4{
    font-size: 30px;
    font-weight:500;
}
.container8 p{
    font-size: 18px;
    margin-top: 10px;
    color: rgb(109, 109, 109);
}
@media all and (max-width: 1000px) {
    .container2{
         padding-left: 10%;
        padding-right: 10%;
    }
    .title{
        font-size:20px;
        margin-left: 10%;
    }
    .details{
        font-size:40px;
        width: 100%;
        margin-top: 10px;
        margin-left: 10%;
    }
    .details2{
        font-size: 18px;
        margin-top: 10px;
        width: 100%;
        margin-left: 10%;
    }
    .container1Det{
        margin-left: 5%;
        margin-right: 30%;
      
      }
      .contDonate{
         height: 50px;
         width:170px;
         margin-top:38px;
      }
      .contDonateP{
        font-size: 15px; 
        color: white;
    }
      
      .container21{
        box-sizing: border-box;
        display: flex;
        margin-left: 0px;
        margin-right: 0px;
       
    }

    //omly %
    .container3{
        margin-bottom:20px;
        display: flex;
      }
     
      .det1{
        font-size: 25px;
        font-weight: 800;
       
      }
      .det2{
        margin-top: 5px;
        font-size: 14px;
        
      }
      .det3{
          margin-top: 5px;
          font-size: 12px;
    
      }
     
}
@media all and (max-width: 700px) {
    .container21{
        //background-color: #eb3e32;
        flex-direction: column;
    }
    .container2{
        width: 100%;
        padding-top: 40px;
        padding-bottom: 20px;
    }
    .container2B{
        width: 100%;
        margin-left: 10%;
       // background-color: green;
        padding-top: 0px;
    }
    .contRight1 h1{
        padding-top: 0px;
        margin-left: 0px;
        font-size: 30px;
        margin-bottom: 10px;
    }


    //omly %
    .container3{
       // height: 120px;
        //background-color: black;
       // margin-bottom: 50px;
        display: flex;
      }
      .div1{
         
          margin:2px;
      }
      .det1{
        font-size: 20px;
        font-weight: 800;
       
      }
      .det2{
        margin-top: 5px;
        font-size: 12px;
        
      }
      .det3{
          margin-top: 5px;
          font-size: 10px;
      }
      .div2{
         
          margin:2px;
      }
      .div3{
          
          margin:2px;
      }

      //help
      .container5{
     flex-direction: column;
    }
    .container6{
        width: 80%;
        order: 2;
    }
    .container7{
        width: 100%;
        order: 1;
    }
    .container50 h2{
        margin-bottom: 0px;
        font-size: 20px;
        line-height: 25px;
    }

}
@media all and (max-width: 800px) {
 //help
 .container50{
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 30px;
    //background-color: #FBCD00;
}
.container3{
   // height: 150px;
    margin-bottom: 0px;
  
  }
  .container50 h2{
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 25px;
}


//questions
.container8{
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    margin-right: 50px;
    border: 1px solid black;
    padding: 20px ;
    margin-bottom: 50px;
}
.container8 h4{
    font-size: 20px;
}
.container8 p{
    font-size: 16px;
    margin-top: 5px;
    color: rgb(109, 109, 109);
}

}
@media all and (max-width: 600px) {

    .container2{
        padding-left: 10%;
       padding-right: 10%;
   }
   .title{
       font-size:20px;
       margin-left: 5%;
   }
   .details{
       font-size:40px;
       width: 100%;
       margin-top: 10px;
       margin-left: 5%;
   }
   .details2{
       font-size: 18px;
       margin-top: 10px;
       width: 100%;
       margin-left: 5%;
   }
   .container1Det{
       margin-left: 5%;
       margin-right: 20%;
     
     }
     .contDonateMain{
        margin-left: 5%;
    }
     .contDonate{
        height: 50px;
        width:170px;
        margin-top:38px;
     }
     .contDonateP{
        font-size: 15px; 
       
    }
}
@media all and (max-width: 500px) {
    .container1{
        height: 60vh;
        min-height: 360px;
    }
    .container2{
        padding-left: 10%;
       padding-right: 10%;
   }
   .title{
       font-size:18px;
       margin-left: 5%;
   }
   .details{
       font-size:30px;
       width: 100%;
       margin-top: 10px;
       margin-left: 5%;
   }
   .details2{
       font-size: 16px;
       margin-top: 10px;
       width: 100%;
       margin-left: 5%;
       line-height: 22px;
   }
   .container1Det{
       margin-left: 5%;
       margin-right: 20%;
     
     }
     .contDonateMain{
        margin-left: 5%;
    }
     .contDonate{
        height: 50px;
        width:170px;
        margin-top:38px;
     }
     .contDonateP{
        font-size: 15px;
    }

     //HOW THE PROGRAM
     .container2{
        padding-left: 20px;
        padding-right: 20px;
    }
    .container2 h2{
        font-size: 30px;
        font-family: 'Teko', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
    }
    .container2 p{
        padding-top:1px;
        font-size: 15px;
        line-height: 1.3; 
    }
    .contRight1 h1{
        padding-top: 0px;
        margin-left: 0px;
        font-size: 30px;
        margin-bottom: 10px;
    }
    .container2B{
        margin-left: 20px;
    }

    //help
    .container7{
        margin-bottom: 20px;
    }
    .container7 p{
        margin-bottom: 5px;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
    }
    .container6{
        width: 100%;
    }
    .supportDiv h3{
        font-size: 16px;
        
    }
    .supportDiv p{
        font-size: 14px;
        color:rgb(82, 82, 82);
    }
    .supportIc{
        font-size: 30px;
        margin-right: 10px;
    }
    .container50{
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 20px;
       
    }
    .container3{
       // margin-bottom: 30px;
        display: flex;
      }


      //questions
.container8{
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-right: 30px;
    border: 1px solid black;
    padding: 15px ;
    margin-bottom: 50px;
}
.container8 h4{
    font-size: 18px;
}
.container8 p{
    font-size: 14px;
    margin-top: 5px;
    color: rgb(109, 109, 109);
}
}

@media all and (max-width: 420px) {
    .div3B p{
        font-size: 12px;
    }
    .container1{
        height: 60vh;
        min-height: 360px;
    }
   .title{
       font-size:14px;
       margin-left: 10px;
   }
   .details{
       font-size:20px;
       width: 100%;
       margin-top: 10px;
       margin-left: 10px;
   }
   .details2{
       font-size: 16px;
       margin-top: 10px;
       width: 100%;
       margin-left: 10px;
   }
   .container1Det{
       margin-left: 3%;
       margin-right: 5%;
     
     }
     .contDonateMain{
        margin-left: 10px;
    }
     .contDonate{
        height: 45px;
        width:140px;
        margin-top:25px;
     }
     .contDonate1{
        height: 45px;
        width:140px;
        margin-top:25px;
     }
.contDonateP{
    font-size: 14px;   
}
          //HOW THE PROGRAM
          .container2{
            padding-left: 15px;
            padding-right: 15px;
        }
        .container2 h2{
            font-size: 25px;
            font-family: 'Teko', sans-serif;
            line-height: 1.2;
            margin-bottom: 5px;
        }
        .container2 p{
            font-size: 14px;
            line-height: 1.3; 
        }
        .contRight1 h1{
            font-size: 22px;
            margin-bottom: 10px;
        }
        .newPostCont2 h2{
            font-size: 16px;
        }
        .newPostCont2 p{
            font-size: 14px;
        }


        //omly %
    .container3{
       // height: 120px;
        //background-color: black;
        //margin-bottom: 20px;
        display: flex;
      }
      .div1{
         
          margin:1px;
      }
      .det1{
        font-size: 14px;
        font-weight: 800;
        
       
      }
      .det2{
        margin-top: 5px;
        font-size: 10px;
        
      }
      .det3{
          margin-top: 5px;
          font-size: 8px;
      }
      .div2{
        
          margin:1px;
      }
      .div3{
         
          margin:1px;
      }
      .container50 h2{
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 25px;
    }

          //questions
.container8{
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px ;
    margin-bottom: 50px;
}
.container8 h4{
    font-size: 16px;
}
.container8 p{
    font-size: 14px;
    margin-top: 5px;
    color: rgb(109, 109, 109);
}
.container2B{
 margin-left: 10px;
 padding: 0px 10px 0px 0px;
 border-left:none;
}
.infoP{
   font-size: 14px;
}
.div3B ul li{
    padding-right: 10px;
}
}
@media all and (max-width: 350px) {

    .newPostCont2 h2{
        font-size: 14px;
    }
    .contDonate{
        height: 40px;
        width:120px;
        margin-top:25px;
        font-size: 10px;
     }
     .contDonate1{
        height: 40px;
        width:120px;
        margin-top:25px;
       
     }
     .contDonate p{
        font-size: 10px;
     }
     .contDonate1 p{
        font-size: 10px;  
    }
    .container2{
        padding-left: 10px;
        padding-right: 10px;
    }
    .div3B p{
        font-size: 10px;
    }
}