

 $primaryColor: #8d2855;
 $secondaryColor: #db5d96;
 $hoverColor:#d8b806;
 $theGrey1: #5F6162;
@mixin maxlinelength {
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   /* number of lines to show */
   -webkit-box-orient: vertical;
}
.container01{
  background-color: white;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
  padding-bottom: 15px;
  
}
.statusBar{
  //height: 70px;
  //background-color: $primaryColor;
}
.imgDiv{
 position: relative;
 height:40vh;
 min-height:300px;
}
.imgDiv img{
  width:100%;
  height: 100%;
  object-fit:cover;
}
.container{
   box-sizing: border-box;
    height: auto;
    overflow: hidden;
    padding-left: 3%;
    padding-right: 3%;
   
}
.donateDiv3{
    background-color:$primaryColor;
     width:136px;
     height: 38px;
     display:flex;
     justify-content:center;
     align-items: center;
     margin-left: 7px;
     font-family: 'Teko', sans-serif;
  }
  .causedivzoom .donateDiv3 p{
    color:#fff;
    font-weight:700;
    margin:0;
    letter-spacing:0px;
    font-size: 15px;
  }
  .donateDiv3:hover{
    cursor: pointer;
    background-color: $hoverColor;
    
}
.causedivzoom{
  height: auto;
  padding-bottom: 15px;
  width:31%;
  background-color:#F9F8F8;
  margin:10px;
 justify-content:space-evenly;
 transition: transform .5s ease;
 border: 1px solid #eee;
 text-decoration: none;
 color: black;
 box-sizing: border-box;
 border-radius: 5px;
 cursor: pointer;

}
.causedivzoom img{
  width:100%;
  height:35vh;
  min-height:200px;
  object-fit:cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom:5px solid $primaryColor;
}

  .causesDetCont{
    display:flex;
    flex-direction:column;
    width:100%;
    padding: 0px 10px;
   
  }
  .imgDiv h1{
   font-size: 60px;
   color: black;
   //font-family: 'Poppins', sans-serif;
   color: white;
   padding: 5px 20px;
   margin-left: 40px;
   font-weight: 600;
   position: absolute;
   top:0;left:0;
   margin-top: 200px;
   
  }
  .causesDetCont h4{
    font-size: 32px;
    margin-bottom:0px;
    margin-top: 30px;
    font-family: 'Rubik', sans-serif;
    font-weight:800;
    color: $primaryColor;
  }
  .causesDetCont p{
   //width: 100%;
  // text-align: center;
   font-size: 18px;
   margin-bottom:40px;
   margin-top:10px;
   font-family: 'Rubik', sans-serif;
   border-bottom: 5px solid $primaryColor;
   padding-bottom: 40px;
  
   line-height: 26px;
   margin-right: 20px;
  }
  .causesItemsCont{
    display:flex;
    flex:1;
    flex-wrap: wrap;
    padding-left:40; 
    padding-right:40;
    //justify-content: space-between;

  }
  .causedivzoom h1{
    padding-top:5px;
    font-size:22px;
    padding-right:7px;
    padding-left:7px;

  }
  .causedivzoom p{
    font-size:16px;
    padding-right:5px;
    padding-left:7px;
    margin-top:3px;
    margin-bottom:7px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.3;
    -webkit-line-clamp: 4;
    @include maxlinelength();
  }
  


  @media all and (max-width: 950px) {
   
    .causesDetCont h1{
      font-size: 38px;
     }
     .causesDetCont h4{
      font-size: 30px;
    }
     .causesDetCont p{
      font-size: 18px;
     }
     .img-hover-zoom{
    
      width:30%;
    }
    .causedivzoom{

      width:46%;
    }
    
}
@media all and (max-width: 760px) {
  
  
  .causedivzoom img{
     height:40vh; 
     min-height: 240px;   
  }
  .container{
  
     margin-top: 7px;
  }
}
@media all and (max-width: 684px) {
  
  .causesDetCont h1{
    font-size: 30px;
   }
   .causedivzoom{
    width:45%;
  }
  .causedivzoom h1{
   font-size:22px;
    }
    .causedivzoom p{
      font-size:10px;
       }
   
}
@media all and (max-width: 608px) {

  .causedivzoom img{
     height:40vh;    
     min-height: 235px;
  }
}




@media all and (max-width: 500px) {
  
  .causesDetCont h1{
    font-size: 30px;
   }
   .causesDetCont h4{
    margin-top: 10px;
    font-size: 24px;
  }
   .causesDetCont p{
    font-size: 15px;
    padding-top:10px;
   }
  .container{
    
     padding-left: 2%;
     padding-right: 2%;
 }
  
  .causedivzoom{
    width:45%;
  }
  .causedivzoom h1{
   font-size:19px;
    }
    .causedivzoom p{
      font-size:10px;
       }
       .causedivzoom img{
        height:36vh;   
        min-height:220px; 
     }
     .imgDiv{
      position: relative;
      height:18vh;
      min-height:150px;
      //height:55vh;
     }
    
    .imgDiv h1{
      font-size: 30px;
      margin-left: 0px;
      padding: 0px 10px;
      margin-top: 100px;
     }
}
   @media all and (max-width: 420px) {

    .container{
    
      padding-left: 1%;
      padding-right: 1%;
  }
    .causedivzoom{
      width:100%;
    }
    .causedivzoom h1{
     font-size:22px;
      }
      .causedivzoom p{
        width: 100%;
        font-size:14px;
         }
         .causedivzoom img{
          min-height:190px; 
          height:31vh;   
       }
   }
   @media all and (max-width: 380px) {
    .causedivzoom{
      width:100%;
    }
    .container{
    
      padding-left: 0%;
      padding-right: 0%;
  }
  .causesDetCont{
    align-items:flex-start;
  }
  .causesDetCont h1{
   margin-left: 10px;
  }
  .causesDetCont h4{
    margin-top: 10px;
    font-size: 22px;
  }
  .causesDetCont p{
    text-align: left;
    margin-bottom: 20px;
    margin-top: 1px;
   }

   }
     @media all and (max-width: 342px) {
      .img-hover-zoom{
        width:100%;
       
       
       }
       .img-hover-zoom img{
        width:100%;
        height:228px;
        
       }
       .causedivzoom h1{
        font-size:20px;
         }
         .causedivzoom p{
          font-size:12px;
           }
      }



