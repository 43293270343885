$primaryColor: #8d2855;
$secondaryColor: #db5d96;
$hoverColor:#d8b806;
$theGrey1: #5F6162;
.statusBar{
    height: 70px;
    background-color: $primaryColor;
  }
.container{
    height: auto;
    background-color: whitesmoke;
    padding: 3% 20% 5% 20%;
}
.container1{
    height: auto;
    background-color: white;
    min-height: 80vh;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
    padding: 30px 40px 20px 40px;
}
.tit{
  font-size: 20px;
}
.subTit{
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 30px;
    margin-right: 30px;
    font-weight: 400;
    flex-wrap: wrap;
}
.inputsDiv{
    width:100%;
}
.inputsDiv1{
 display: flex;
 justify-content: space-between;
 margin-bottom: 10px;
}
.inputsDiv2{
    display: flex;
    justify-content: space-between;
   }
.name{
    box-sizing: border-box;
    width: 48%;
    padding: 10px;
    outline: none;
    border: 1px solid rgb(175, 175, 175);
  }
  .phone{
    box-sizing: border-box;
    width: 48%;
    padding: 10px;
    outline: none;
    border: 1px solid rgb(175, 175, 175);
  }
.inputsDiv2{
    
}

.email{
    box-sizing: border-box;
    width: 48%;
    padding: 10px;
    outline: none;
    border: 1px solid rgb(175, 175, 175);
}
.age{
    box-sizing: border-box;
    width: 48%;
    padding: 10px;
    outline: none;
    border: 1px solid rgb(175, 175, 175);
}
.genderDiv{
    display: flex;
    align-items: center;
}
.gH{
    margin-top: 20px;
    margin-bottom: 10px;
}
.genderDiv1{
    height: 18px;
    width:18px;
    border: 1px solid rgb(0, 0, 0);
    margin-right: 10px;
   
}
.genderDiv1:hover{
    cursor: pointer;
}
.genderDiv2:hover{
    cursor: pointer;
}
.genderDiv h3{
    font-size: 14px;
    font-weight: 400;

}
.genderDiv2{
    height: 18px;
    width:18px;
    border: 1px solid rgb(0, 0, 0);
    margin-right: 10px;
    margin-left: 20px;
}
.selectorDiv{
    display: flex;
    width:100%;
    justify-content: space-between;
}
.selectorDiv p{
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.contDiv{
    width:48%;
}

.selectCont{
    display: flex;
    flex: 1;
    height: 50px;
    border-color: whitesmoke;
    border-width: 1px;
    border-style: solid;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;

}
.selectCont:hover{
    cursor: pointer;
}
.contDiv:hover{
    color: $primaryColor;
    cursor: pointer;
}

.selectDiv{
    flex: 1;
    border: none;
    font-size: 14px;
    outline: none;
    background-color:white;
    box-sizing: border-box;
    overflow: hidden;
}
.selectDiv option{
    margin-top: 10px;
    background-color:white;
}
.selectDiv option:hover{
    color: white;
}
.selectDiv:hover{
    cursor: pointer;
}

.selectFile{
    display: flex;
    margin-top: 20px;
   // background-color:yellow;
    //border:1px solid whitesmoke;
   // align-items: center;
}
.addImagesDiv{
    margin-top: 10px;
    height: 40px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.addImagesDiv label{
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    background-color: black;
    height: 100%;
    width: 100%;
}
.addImagesDiv2{
    margin-top: 10px;
    height: 40px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.addImagesDiv2 label{
    display: flex;
    justify-content: center;
    color: black;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    border: 1px solid rgb(182, 182, 182); 
    height: 100%;
    width: 100%;
}
.dropFilesDiv{
    margin-top: 0px;
    width: 90%;
    height: 80px;

}
.dropFilesDiv h4{
 font-size: 14px;
 font-weight: 300;
}
.addImagesDiv label:hover{
    cursor: pointer;
    background-color:$primaryColor
}
.addImagesDiv2 label:hover{
    cursor: pointer;
    background-color:$primaryColor;
    color: white;
}
.uploadDiv{
    background-color: $primaryColor;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
    color: white;
    font-weight: 700;
}
.uploadDiv:hover{
    cursor: pointer;
    background-color:$hoverColor;
}
@media all and (max-width: 800px) {
    .container{
        padding:30px 30px;
    }
    .tit{
        font-size: 16px;
      }
      .subTit{
          font-size: 14px;
          margin-top: 10px;
          margin-bottom: 30px;
          margin-right: 30px;
      }
      .gH{
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 10px;
    }
 }
 @media all and (max-width: 400px) {
    .container{
        padding:0px 0px;
    }
    .container1{
        padding: 20px 20px 20px 20px;
    }
 }