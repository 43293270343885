
//categories
$primaryColor: #eb3e32;
$secondaryColor: #FED800;
$hoverColor:#FBCD00;
$shopColor:#bfaf80;
.mainCont{
   
    margin-left: 3%;
    margin-right: 3%;
    padding-bottom: 38px; 
    min-height: 769px;
    font-family: 'Poppins', sans-serif;
    //font-family: 'Rubik', sans-serif;
    //font-family: 'Poppins', sans-serif;
}
.headCont{
    display: flex;
    height: 60px;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    //background-color: red;
}


.container{
    height: auto;
    display: flex;
   
}

.container1{
    display: flex;
    width: 25%;
    background-color: whitesmoke;
    flex-direction: column;

}
.container2{
    padding-top: 15px;
    height: auto;
    display: flex;
    width: 75%;
    background-color: white;
    flex-wrap: wrap;
    overflow: hidden;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
}
.backArrow{
    font-size: 40px;
}
.backP{
    margin-right: 5px;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    font-size: 20px;
   
    
}
.backP2{
    margin-right: 5px;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    font-size: 20px;
}
.backP:hover{
    color: $primaryColor;
    cursor: pointer;
}

.contRight1{
    height: 430px;
    background-color: white;
    width: 95%;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}
.contRightNav{
    height: 46px;
    background-color: $primaryColor;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    display: flex;
    align-items: center;
    padding-left: 15px;
   
}
.contRightNav p{
    font-size: 16px;
    font-weight: 700;
    color: white;
}


.catListCont {
    height: 46px;
    display: flex;
    align-items: center;
    padding-left: 3px;
    border-bottom-color: whitesmoke;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    color: black;
    font-size: 15px;   
    text-align: center;
    text-decoration: none;
    
}
.catListCont:hover{
    cursor: pointer;
    background-color: whitesmoke;
  }
  .imageDiv2{
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      //background-color: yellow;
  }
  .theImage2{
      height: 40%;
      width: 40%;
     // background-color: yellow;
  }
  .catListCont:hover .imageDiv2{
      background-color: whitesmoke;
  }

.myItemsListCont{
    width: 22.5%;
    height: 384px;
    margin-left: 2%;
    text-decoration: none;
    padding-bottom: 7px;
    //background-color: yellow;
    margin-bottom: 23px;
    //margin-top: -153px;
   
  }
 .myItemsListCont:hover{
    
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
 }
  .imageDiv{
    
    height: 269px;
    border-bottom-color: whitesmoke;
    border-bottom-width:1px;
    border-bottom-style: solid;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    position: relative;
  }
  .theImage{
    height: 70%;
    //border-radius: 15px;
 }
 .clothListDetCont{
    display: flex;
    flex-direction: column;
    height: 76px;
    align-items: center;
    //margin-bottom: 15px;
}
.clothListDetCont p{
    font-size:16px;
    font-weight: 400;
     padding-top: 7px;
     color: #000;
     font-family: 'Rubik', sans-serif;
    // font-family: 'Poppins', sans-serif;
     //font-family: 'Ubuntu', sans-serif;
     
}
.clothListDetCont h4{
  font-size:16px;
  font-weight: 500;
  padding-top: 0px;
  //padding-bottom: 7px;
  color: $primaryColor;
  font-family: 'Poppins', sans-serif;
  //font-family: 'Ubuntu', sans-serif;
}
.addToCart{
    min-height: 30px;
    border-radius: 15px;
    width: 115px;
    display: flex;
    align-items: center;
    background-color: black;
    align-self: center;
    justify-content: center;
  }
  .addToCart:hover{
      background-color: $primaryColor;
  }
  
  .addToCart h6{
      font-size: 11px;
      color:white
  }
  .cartIcon{
      //background-color:$shopColor;
      color: white;
      padding: 7px;
      font-size: 15px;
      margin-right: 2px;
      border-style: solid;
      border-color: whitesmoke;
      border-width:1px;
  }
  .cartIcon:hover{
      background-color: whitesmoke;
      color: $shopColor;
      cursor: pointer;
  }
  .ratingDiv{
    padding-top: 7px;
  }
  .ratingIcon{
      color: rgb(255, 238, 0);
      
  }
  .headCont1{
      display: flex;
      //padding-top: 3px;
     
  }
  .shopName{
      margin-top: 7px;
      font-size: 20px;
      font-family: 'Rubik', sans-serif;
      font-weight: 600;  
  }
  .shopName2{
    margin-top: 0px;
    font-size: 20px;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;  
  }
  .headCont1:hover{
    color: $primaryColor;
    cursor: pointer;
  }
  .contRight2{
      box-sizing: border-box;
      height: 307px;
      background-color: white;
      width: 95%;
      padding: 7px 15px 7px 15px;
      margin-top: 23px;
  }
  .contRight3{
    box-sizing: border-box;
    height: auto;
    background-color: white;
    width: 95%;
    padding: 7px 15px 7px 15px;
    margin-top: 23px;
    padding-bottom: 15px;
  }
  .minPriceCont{
      height: 38px;
      border-style: solid;
      border-color: whitesmoke;
      border-width:1px;
      display: flex;
      margin-bottom: 15px;
    
  }
  .maxPriceCont{
    height: 38px;
    border-style: solid;
    border-color: whitesmoke;
    border-width:1px;
    display: flex;
       
}
.filterPrice{
    height: 46px;
    background-color: black;
    margin-right: 15px;
    margin-top: 30px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
}
.resetPrice{
    box-sizing: border-box;
    height: 46px;
    margin-top: 30px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    border-style: solid;
    border-color: black;
    border-width:1px;
    color: black;
}
.resetPrice h3{
   
    font-size: 15px;
   
    //letter-spacing: 2px;
}
.resetPrice:hover{
    cursor: pointer;
    background-color: $primaryColor;
    color: white;
    border-style: solid;
    border-color: $primaryColor;
    border-width:1px;
}
.filterPrice h3{
    color: white;
    font-size: 15px;
    //letter-spacing: 2px;
}
.filterPrice:hover{
    cursor: pointer;
    background-color: $primaryColor;
}
.contRight2 h4{
    margin-top: 7px;
    margin-bottom: 7px;
    font-size: 15px;
}
.contRight2 p{
    margin-top: 15px;
    margin-bottom: 7px;
    font-size: 13px;
}
.inputPrice{
    outline: none;
    border-style: none;
    margin-left: 7px;
    margin-right: 7px;
    font-size: 13px;
}
.inputPriceP{
    font-size: 13px;
    color: rgb(206, 206, 206);
    margin-left: 7px;
    margin-right: 7px;
}
.sortDiv1{
    height: 38px;
    border-style: solid;
    border-width:1px;
    border-color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 7px;
    padding-right: 7px;
    
}
.sortDiv1 h6{
    font-size: 13px;
    font-weight: 500;
}
.sortIcon{
    font-size: 19px;
}
.sortDiv2{
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
    
}
.sortDiv2 h6{
    font-size: 13px;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 7px;
    padding-right: 7px;
    font-weight: 500;
}
.sortDiv2 h6:hover{
    color: white;
   background-color: $primaryColor;
   cursor: pointer;
}
.contRight3 h4{
    margin-top: 7px;
    margin-bottom: 7px;
    font-size: 15px;
}
.sortDiv1:hover{
    cursor: pointer;
}
.filterDiv{
    display: flex;
    
}

@media all and (max-width: 1153px) {
.container2{
    //background-color: red;
}

.myItemsListCont{
    width: 30%;
  }

}
@media all and (max-width: 923px) {
    .container2{
        //background-color: red;
    }
      .container1{
          width: 35%;
    }
      .container2{
         width: 65%;
      }
      .myItemsListCont{
        width: 48%;
      }
    
    }
    @media all and (max-width: 615px) {
        .container{
           flex-direction: column;
        }
        .container1{
            width: 100%;
            
      }
      .container2{
        width: 100%;
     }
     .contRight1{
        width: 100%;
        height: auto;
        border-radius: 7px;
        margin-bottom: 20px;
    }
    .contRightItems{
        display: flex;
        flex-wrap: wrap;
        
    }
    .catListCont{
        flex-direction: column;
        width: 23.8%;
        border: none;
        height: 76px;
        border-style: solid;
        border-color: whitesmoke;
        border-width: 0px;
        font-size: 11px;
        text-decoration: none;
    }
   .contRight2{
       display: none;
   }
   .contRight3{
    display: none;
   }
   .contRightNav{
    display: none;
   }
   .container1{
       height: auto;
       margin-bottom: 15px;
   }
   .shopName{
    margin-top: 5px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;  
}
.shopName2{
  margin-top: 0px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;  
}
.theImage2{
    height: 60%;
    width: 60%;
   // background-color: yellow;
}
.catTitle2{
margin-right: 5px;
font-family: 'Rubik', sans-serif;
font-weight: 600;
font-size: 20px;
}
  
    }

    @media all and (max-width: 461px) {

        .container{
            font-family: 'Roboto', sans-serif;
        }
        .headingTitle{
            padding-left: 15px;
            padding-top: 15px;
            padding-bottom: 0em;
            font-size: 15px;
    
        }
        .myItemsListCont{
            height: 307px;
            margin-bottom: 7px;
            //background-color: yellow;
        }
        .imageDiv{
            height: 192px;
           }
           .clothListDetCont p{
            font-size:15px;
            font-weight: 500;
            padding-top: 3px;  
        }
        .clothListDetCont h4{
          font-size:16px;
          padding-top: 3px;
          padding-bottom: 3px;
      }
      .catListCont{
        width: 23.5%;
        font-size: 10px;
        height: 61px;
        
    }
    .contRight1{
        margin-bottom: 10px;
    }
    }
    @media all and (max-width: 384px) {
        .catListCont p{
            margin-top: -3px;
        }
        /*.imageDiv2{
            height: 38px;
            width: 38px;
           
        }*/
        .catTitle2{
            margin-left: 10px;
            margin-top: 20px;
            font-family: 'Rubik', sans-serif;
            font-weight: 600;
            font-size: 18px;

            
        }
        .backP{
            margin-right: 5px;
            font-weight: 600;
            font-size: 18px;
            margin-left: 10px;
           
        }
        .backP2{
            margin-right: 5px;
            font-weight: 600;
            font-size: 18px;
        }
        .mainCont{
            margin-left: 0%;
            margin-right: 0%;
            padding-bottom: 38px; 
        }
        .clothListDetCont p{
            font-size:13px;
            font-weight: 500;
            padding-top: 3px;
             
             
        }
        .clothListDetCont h4{
          font-size:15px;
          padding-top: 3px;
          padding-bottom: 3px;
          
      }
    .myItemsListCont{
        height: 300px;
        margin-left: 1%;
        margin-right: 1%;
      }
    .addToCart{
        min-height: 26px;
        border-radius: 15px;
        width: 100px;
        display: flex;
        align-items: center;
        background-color: black;
        align-self: center;
        justify-content: center;
      }
     
      .addToCart h6{
          font-size: 10px;
      }
      /*.catListCont{
        width: 23%;
        font-size: 8px;
        height: 57px;
    }*/
    .contRightItems{
     background-color: whitesmoke;
    }
    .imageDiv2{
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .catListCont{
        height: 70px;
        box-shadow: none;
        border-bottom: none;
      //  background-color: red;
       // margin: 1px;
        width: 23%;
       
       
    }
    .catListCont p{
        font-size: 10px;
        margin-top: 5px;
        color: black;
        
    }
    }
   
    
    @media all and (max-width: 350px) {
        .catListCont p{
            font-size: 9px;
            margin-top: 5px;
            color: black;
        }
    }
    