

//footer
$primaryColor2: #301a22;
$primaryColor: #301a22;
$hoverColor:#FBCD00;
.footerDiv{
    width:100%;
    padding-bottom: 38px;
    background-color: #301a22;
    display: flex;
    padding-top: 30px;
    padding-left: 61px;
    box-sizing: border-box;
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
}
.footerDivs{
     width: 25%;
    display: flex;
    padding-right: 38px;
    margin: 7px;
    flex-direction: column;
}
.contactsInput{
    border: 1px solid rgb(109, 108, 108);
    margin-top:12px;
    width:80%;
    height:30px;
    background-color:#241319;
    color:"#fff";
    outline: none;
    padding-right: 7px;
    padding-left: 7px;
    //border-radius: 10px;
}
.lineDiv{
     padding-top:11px;
     border-top-style: solid;
     border-top-color: #6B6C6C;
     border-top-width:1px;
     display: flex;
     flex-direction: column;
     justify-content: center;
}
.ulFooter{
margin-left: -15px;
margin-top: 3px;
}
 .FooterLi{
    color: white;
    list-style: none;
    font-size:16px;
    font-weight: 600;
    text-decoration: none; 
}
.ulFooter li{
    margin-top: 11px;
    list-style: none;
}
.FooterLi:hover{
    cursor: pointer;
    color: $primaryColor;
    text-decoration:underline;
    padding-bottom: 3px;
   /* border-bottom: solid;
    border-bottom-color: #FEEB03;
    border-bottom-width: 2px;
    cursor: pointer;
    content: "";
    left: 0; */
}
.footerSendDiv{
    //background-color:$hoverColor;
    border: 1px solid rgb(105, 105, 105);
     width:153px;
     height:34px;
    margin-top:15px;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor: pointer;
    border-radius: 20px;
}

.contactUs{
   
     font-size:19px;
     font-weight:700;
     color:#fff;
}



@media all and (max-width: 923px) {
   
    .footerDivs{
        width: 40%;
    }
    .contactUs{
         margin-top: 15px;
        
    }
    .contactsInput{
        width: 100%;
    }
    
}
@media all and (max-width: 615px) {
   
    .footerDiv{
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding-left: 30px;
       
        
    }
    .footerDivs{
        width: 80%;
       
    }
    
    .contactUs,.divCentreHead1{
        text-align: center;
    }
    .footerSendDiv{
        width: 100%;
       
    }
    .aboutUsDivB{
        align-self: center;
    }
    .contactsInput{
        width: 100%;
    }
    
}
