
 $primaryColor: #8d2855;
 $secondaryColor: #db5d96;
 $hoverColor:#d8b806;
 $theGrey1: #5F6162;
.container{
    height: auto;
    background-color: whitesmoke;
    padding-bottom: 38px;
    font-family: 'Poppins', sans-serif;
    padding-top: 50px;
}
.statusBar{
    height: 70px;
    background-color: $primaryColor;
  }
.containerA{
    display: flex;
    height: auto;
    box-sizing: border-box;
    margin-left: 7%;
    margin-right: 7%;
    position: relative;
    justify-content: center;
    
}
.container1{
 background-color: white;
 flex: 1;
 display: flex;
 flex-direction: column;
 overflow: hidden;
 
}
.container2{
    height: 676px;
    box-sizing: border-box;
    background-color:white;
    flex: 1;
    padding-left: 15px;
    padding-right: 15px;
   // border-left-style: solid;
    border-left-width:1px;
    border-left-color: whitesmoke;
   
}
.container1A{
    display: flex;
    height: 522px;
    background-color: white;
    align-items: center;
    justify-content: center;
    
}
.myImgCont{
    height: 153px;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding-right:1px;
}
.container1B1{
    box-sizing: border-box;
    overflow: hidden;
    height: 153px;
    background-color: white;
    width: 99%;
    display: flex;
    align-items: center;
    overflow-x: auto;
}

.container1B1::-webkit-scrollbar {
   // width: 0.5em;
    background-color: #fff;
    height: 10px;
    //height: 1em;
  }
 .container1B1::-webkit-scrollbar-thumb {
    background-color: rgb(236, 236, 236);
  
  }
.containerB{
    min-height: 230px;
    padding-top: 15px;
    padding-left: 23px;
    padding-right: 15px;
    padding-bottom: 38px;
    background-color: white;
    margin-top: 1px;
    margin-left: 7%;
    margin-right: 7%;
}
.prdTitle{
    font-size: 23px;
    font-weight: 700;
    margin-top: 76px;
}
.prdPrice{
    font-size: 30px;
    margin-top: 15px;
    color: $primaryColor;
}
.prdDet{
    font-size: 13px;
    font-weight: 500;
    margin-top: 7px;
    padding-right: 20%;
    line-height: 1.5;
    color: #7a7a7a;
}
.prdQty{
    margin-top: 29px;
    margin-left: 7px;
    font-size:13px;
}
.theCartDiv{
    display: flex;
    margin-top: 15px;
}
.prdCart{
    display: flex;
    width: 155px;
    height: 40px;
    background-color: $primaryColor;
    justify-content: center;
    border-radius: 23px;
   
}
.prdCart p{
   align-self: center;
   color: white;
   font-weight: 600;
}
.prdCart:hover{
    background-color: $secondaryColor;
    cursor: pointer;
}
.addQuantityDiv{
    box-sizing: border-box;
    display: flex;
    width: 115px;
    height: 38px;
    background-color: white;
    justify-content: space-between;
    margin-right: 15px;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    border-radius: 23px;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15px;
    font-weight: 600;
}
.addQuantityDiv:hover{
    border-color: $primaryColor;
}
.cartHeart{
    display: flex;
    width: 38px;
    height: 38px;
    background-color: white;
    justify-content: center;
    margin-left: 15px;
    align-items: center;
    border-radius: 38px;
    font-size: 15px;
    border-color: black;
    border-width: 1px;
    border-style: solid;
}
.cartHeart:hover{
    cursor: pointer;
    color: $primaryColor;
    border-color: $primaryColor;
}

.prdWish h3{
   font-size: 16px;
   margin-right: 20px;
}

.prdWish{
    margin-top: 23px;
    display: flex;
}
.prdWish h3:hover{
    color: $primaryColor;
    cursor: pointer;
    text-decoration: underline;
}
.prdShare{
    margin-top: 13px;
    display: flex;
    flex-direction: column;
}
.orderDiv{
    color: #fff;
    background-color: $primaryColor;
    border-radius: 5px;
    width: 120px;
    text-align: center;
    padding: 5px 4px;
    margin-top: 10px;
    cursor: pointer;
}
.prdShare p{
   font-size: 15px;
   margin-top: 11px;
   font-weight: 700;
}
.prdShare p span{
  color: #7a7a7a;
}
    
.secImageCont2{
    height: 92px;
    width: auto;
    display: flex;
    align-items: center;
    padding-right: 15px;  
}


.otherProductImages{
    height: 115px;
    min-width: 115px;
    width: 76px;
    margin: 3px;
    display: flex;
   align-items: center;
   justify-content: center;
}
.theSecImages{
    height: 95%;
}
.otherProductImages:hover{
 
    transform: scale(1.02);
    padding: 1px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
}

 .thePrimImageDiv{
     width: 350px;
     height: 350px; 
     display: flex;
     justify-content: center;
     //background-color: yellow;
 }
 .thePrimImage{
    height: 98%;
   
 }
  .thePrimImageDiv:hover{
    position: absolute;
    //transform: scale(1.5);
    cursor: -webkit-zoom-in;
   // margin-top: 76px;
   
}
.zoomedImgSnackDiv{
    min-height: 600px;
    height: 100vh;
    background-color:rgba($color: #000000, $alpha: 0.2);
    width: 100%;
    z-index: 1;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
}
.snackDiv1{
   height: 95%;
   width: 50%;
   background-color: white;
   border-radius: 10px;
   padding-bottom: 10px;
  
}
.snackDiv1A{
    box-sizing: border-box;
    //background-color: yellow;
    height: 10%;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
}
.snackDiv1A h2{
  font-size: 20px;
}
.snackDiv1B{
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: whitesmoke;
    border-width: 1px 0px 1px 0px;
    border-style: solid;
}
.thePrimImage2{
    height: 90%;
}
.thePrimImage2:hover{
    height: 100%;
    cursor: zoom-in;
}
.snackDiv1C{
    //background-color: yellow;
    height: 20%;
    overflow: hidden;
}
.snackClose{
    font-size: 30px;
}
.snackClose:hover{
    cursor: pointer;
    color: $primaryColor;
}

.myImgCont2{
    height: 120px;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding-right:1px;
    //background-color: yellow;
}
.container1B12{
    box-sizing: border-box;
    overflow: hidden;
    height: 120px;
    background-color: white;
    width: 99%;
    display: flex;
    align-items: center;
    overflow-x: auto;
    -ms-overflow-style: none;
}
.container1B12::-webkit-scrollbar {
    background-color: white;
    height: 10px;
  }
  .container1B12::-webkit-scrollbar-thumb:hover {
   cursor: pointer;
  }
  .container1B12::-webkit-scrollbar-thumb {
    background-color: #eee;
  
  }
.otherProductImages2{
    height: 100px;
    min-width: 100px;
    width: 76px;
    margin: 3px;
    display: flex;
   align-items: center;
   justify-content: center;
}
.otherProductImages2:hover{
    transform: scale(1.02);
    padding: 1px;
    //box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
    border-style: solid;
    border-color: $primaryColor;
    border-width: 1px;
    cursor: pointer;
}
/*.theSecImagesDiv:hover{
    border-style: solid;
    border-width: 2px;
    border-color: $primaryColor;
    transform: scale(1.02);
    padding: 2px;
    cursor: pointer;
}*/

.featLi{
    padding-top: 9px;
    color: black;
    font-size: 15px;
    margin-left: 7px;
}
.containerB h4{
    font-size: 19px;
    margin-top: 11px;
    font-weight: 400;
    margin-right: 23px;
}
.containerBHead{
  display: flex;
}
.containerBHead h4{
    padding: 9px 15px 9px 15px;
    border-radius:0px;
    font-size: 15px;
    font-weight: 500;
    margin-left: 10px;
}
.containerBHead h4:hover{
    border-style: solid;
    border-width:1px;
    border-color: black;
    cursor: pointer;
}
.prdQtyCount{
    height: 38px;
    width: 38px;
    border-style: solid;
    border-color: whitesmoke;
    border-width:1px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.prdQtyDiv{
    display: flex;
}
.prdQtyCount p{
    font-size: 15px;
    margin-top: 23px;
    
}
.prdCat{
    font-weight: 800;
    letter-spacing: 1px;
    color: rgb(148, 148, 148);
    margin-top: 3px;
    font-size: 15px;
    text-decoration: underline;
}
.containerHead{
    background-color: whitesmoke;
    height: 38px;
    display: flex;
    align-items: center;
    padding-left: 7%;
    align-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.backP{
    margin-right: 5px;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: black;
    
}
.backP2{
    margin-right: 5px;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: black;
}
.backP:hover{
    color: $primaryColor;
    cursor: pointer;
}

.addIcon:hover{
color: $primaryColor;
cursor: pointer;
}
.subtractIcon:hover{
    color: $primaryColor;
    cursor: pointer;
}
.reviewListCont{
    border-bottom-color: whitesmoke;
    border-bottom-style: solid;
    border-bottom-width:1px;
    padding-bottom: 15px;
}
.reviewListCont h4{
    padding-top:1px;
    font-size: 15px;
    font-weight: 500;
}
.reviewListCont p{
    padding-top: 7px;
    font-size: 13px;
    
}
.contMagnify{
    height: 307px;
    width: 307px;
    background-color: white;
    position: absolute;
    margin-top: 23px;
    border-color: whitesmoke;
    border-style: solid;
    border-width:1px;
   
    //margin-left: 40%;
   // align-self: center;
}
.ratingInfoDiv{
    display:flex;
    color:#FFAE00;
    align-items:center;
    margin-top:15px
    }
.rev2{
    padding-left: 20px;
}
.modal{
    position:fixed;
    top:0;bottom:0;left:0;right:0;
    background-color: #00000055;
    z-index: 9999;
    display:flex;
   align-items: center;
   justify-content: center;
}
.modalCont{
   height:280px;
   width:350px;
   box-sizing: border-box;
   background-color:#fff;
   border-radius: 10px;
   padding: 20px ;
 }
 .modalCont h5{
  font-size: 18px;
  margin-bottom: 5px;
}
.contDiv{
    display:flex;
    margin-top: 10px;
    align-items: center;
  }
  .contDiv p{
    margin-left: 5px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  .book{
    font-size: 16px;
    
  }
  #close{
    width:100%;
    background-color:$primaryColor;
    border-radius: 5px;
    margin-top: 20px;
    padding:5px 0px 5px 0px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
  }
//npm i react-image-magnifiers
//npm install react-image-magnify
@media all and (max-width: 1000px) {
    
    .containerA{
        height: 676px;
        margin-left: 30px;
        margin-right: 30px;
    }
    .containerHead{
        height: 38px;
        padding-left: 30px;
        align-content: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .prdShare{
       padding-bottom: 0px;
    }
    .containerB{
        min-height: 230px;
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 30px;
        margin-top: 0px;
        margin-left: 30px;
        margin-right: 30px;
    }
    .snackDiv1{
        height: 95%;
        width: 70%;
       
     }

}
@media all and (max-width: 800px) {
 
.container{
        height: auto;
        padding-bottom: 38px;
    }
    .prdTitle{
        margin-top: 30px;
    }
    .containerA{
        height: auto;
        margin-left: 20px;
        margin-right: 20px;
        flex-direction: column;
    }
    .container1A{
        display: flex;
        height: 400px;
        background-color: white;
        align-items: center;
        justify-content: center;
    }
    .containerHead{
        height: 38px;
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .containerB{
        min-height: 230px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .snackDiv1{
        height: 95%;
        width: 80%;
       
     }
     .prdDet{
        padding-right: 5%;
        
    }
}
@media all and (max-width: 550px) {
    .snackDiv1{
        height: 80vh;
        width: 90%;
        min-height: 420px;
     }

}
@media all and (max-width: 400px) {
    .containerA{
        margin-left: 10px;
        margin-right: 10px;
    }
    .containerHead{
        height: 38px;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .containerB{
        margin-left: 10px;
        margin-right: 10px;
    }
    .backP{
        margin-right: 5px;
        font-size: 18px;
    }
    .backP2{
        margin-right: 5px;
        font-size: 18px;
    }
    .container1A{
        display: flex;
        height: 380px;
        background-color: white;
        align-items: center;
        justify-content: center;
        
    }
    .thePrimImageDiv{
        width: 300px;
        height: 300px; 
    }
    .thePrimImage{
       height: 98%;
    }
    .otherProductImages{
        height: 90px;
        min-width: 90px;
        width: 76px;
        margin: 2px;
    }
    .myImgCont{
        height: 110px;
    }
    .container1B1{
        height: 110px;
        width: 99%;
    }
    .prdTitle{
        font-size: 18px;
        font-weight: 700;
        margin-top: 20px;
    }
    .prdPrice{
        font-size: 20px;
        margin-top: 5px;
    }
    .prdDet{
        font-size: 13px;
        font-weight: 500;
        margin-top: 5px;
        padding-right: 10px;
        line-height: 1.5;
    }
    .prdCart p{
        align-self: center;
        font-size: 14px;
     }
     .cartHeart{
        width: 35px;
        height: 35px;
        margin-left: 15px;
        border-radius: 50px;
        font-size: 15px;
    }
    .prdWish{
        margin-top: 15px;
        justify-content: space-between;
    }
    .prdWish h3{
        font-size: 14px;
        margin-right: 0px;
        margin-top: 10px;
     }
     .prdShare{
        margin-top: 0px;
    }
    .prdShare p{
       font-size: 14px;
       margin-top:10px;
    }
    .containerB{
        min-height: 230px;
        padding-top: 15px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 20px;
        background-color: white;
       
    }
    .featLi{
        padding-top: 3px;
        font-size: 14px;
        margin-left: 7px;
    }
   .revStyle{
        padding: 9px 15px 9px 15px;
        margin-left: 100px;
    }
    .rev2{
        padding-left: 10px;
    }
    .snackDiv1{
        height: 100vh;
        width: 100%;
        min-height: 420px;
     }
     .myImgCont2{
        height: 110px;
        width: 100%;
    }
    .container1B12{
        height: 110px;
    }
    .otherProductImages2{
        border-color: rgb(168, 168, 168);
        border-width: 1px;
        border-style: solid;
        height: 90px;
        min-width: 90px;
        width: 90px;
        margin: 3px;
        border-radius: 5px;
    }
    .container1B12::-webkit-scrollbar {
        background-color: white;
        height: 0px;
      }
}