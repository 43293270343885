//sanaa
$primaryColor: #eb3e32;
$hoverColor:#FBCD00;
.container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
   
   
}
.container1{
    width: 100%;
    height: 85vh;
    min-height: 530px;
    overflow: hidden;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.7), 
        rgba(0, 0, 0, 0.7)
      ),url('https://images.pexels.com/photos/6140712/pexels-photo-6140712.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
      background-repeat: no-repeat;
     background-size: 100% 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}
.container1Det{
    padding-left: 50px;
}
.title{
    color: white;
    font-size:30px;
    font-weight:600;
    letter-spacing: 1px;
    line-height: 1.2;
    font-family: 'Rubik', sans-serif;
    background-color: black;
    padding: 5px 15px;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.details2{
    font-size:14px;
    font-weight:400;
    color:#fff;
    line-height: 1.2;
    letter-spacing: 1px;
    margin-top: 20px;
    width:60%;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 20px 20px;
    font-size: 20px;
   
}
.contDonate{
     box-sizing: border-box;
     background-color:$primaryColor;
     height: 53px;
     width:176px;
     display:flex;
     justify-content:center;
     align-items: center;
     letter-spacing: 1px;
     font-weight:700;
     font-family: 'Rubik', sans-serif;
     margin-bottom: 20px;
     font-size: 19px;
     color: white;
     margin-top :30px;
  }
  .contDonate:hover{
    cursor: pointer;
    background-color: $hoverColor;
    
}
@media all and (max-width: 961px) {
    .title{
        font-size:25px;
        font-weight:600;
        padding: 5px 15px;
        width: 80%;
    }
    .details2{
        font-size:16px;
        font-weight:400;
        line-height: 1.2;
        letter-spacing: 1px;
        margin-top: 20px;
        width:80%;
        padding: 20px 20px;
       
    }
    .contDonate{
         height: 53px;
         width:176px;
         font-weight:700;
         margin-bottom: 20px;
         font-size: 19px;
         margin-top :30px;
      }  
   
}
@media all and (max-width: 700px) {
    .title{
        font-size:20px;
        font-weight:600;
        padding: 5px 15px;
        width: 85%;
    }
    .details2{
        font-size:16px;
        font-weight:400;
        line-height: 1.2;
        letter-spacing: 1px;
        margin-top: 20px;
        width:85%;
        padding: 10px 10px;
       
    }
    .contDonate{
         height: 45px;
         width:150px;
         font-weight:700;
         margin-bottom: 20px;
         font-size: 16px;
         margin-top :30px;
      }  
   
}
@media all and (max-width: 600px) {
   
    .container1{
        height: 74vh;
        min-height: 461px;
    }
}
@media all and (max-width: 420px) {
    .container1Det{
        padding-left: 20px;
        padding-right: 20px;
    }
    .container1{
        height: 62vh;
        min-height: 384px;
    }
    
    .title{
        font-size:18px;
        font-weight:500;
        padding: 5px 5px;
        width: auto;
    }
    .details2{
        box-sizing: 'border-box';
        font-size:14px;
        font-weight:300;
        line-height: 1.2;
        letter-spacing: 1px;
        margin-top: 20px;
        width:auto;
        padding: 10px 20px; 
    }
    .contDonate{
         height: 35px;
         width:130px;
         font-weight:700;
         margin-bottom: 20px;
         font-size: 14px;
         margin-top :30px;
      }  
   
}
@media all and (max-width: 384px) {
    .container1{
        height: 307px;
    }
        
 }
/*.container1Det{
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display:flex;
  flex-direction: column;
  justify-content:center;
  padding-left: 10%;
  padding-right: 10%;
  position: relative;
}
.title{
    color: white;
    font-size:30px;
    font-weight:600;
    letter-spacing: 1px;
    line-height: 1.2;
    font-family: 'Rubik', sans-serif;
    background-color: black;
    padding: 5px 15px;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.details{
  
    font-size:40px;
    font-weight:700;
    color:#fff;
    line-height: 1.2;
    letter-spacing: 1px;
    font-family: 'Rubik', sans-serif;
    text-align: center;
}
.imgDiv{
    position: relative;
    height:80vh;
   }
   .imgDiv img{
     width:100%;
     height:80vh;
     min-height: 310px;
     object-fit:cover;
   }
   .imgDiv h1{
    font-size: 60px;
    color: black;
    font-family: 'Teko', sans-serif;
    z-index: 8;
    position: absolute;
    color: white;
    bottom: 0;
    background-color: black;
    margin-bottom:-30px;
    padding: 5px 20px;
    margin-left: 40px;
    font-weight: 500;
   }
.details2{
    font-size:14px;
    font-weight:400;
    color:#fff;
    line-height: 1.2;
    letter-spacing: 1px;
    margin-top: 20px;
    width:60%;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 20px 20px;
    font-size: 20px;
   
}
.contDonate{
     box-sizing: border-box;
     background-color:$primaryColor;
     height: 53px;
     width:176px;
     display:flex;
     justify-content:center;
     align-items: center;
     letter-spacing: 1px;
     font-weight:700;
     font-family: 'Rubik', sans-serif;
     position: absolute;
     bottom: 0;
     z-index: 10;
     margin-bottom: 20px;
     
  }
  .contDonate p{
      font-size: 19px;
      color: white;
      
  }
  .contDonate:hover{
    
    cursor: pointer;
    background-color: $hoverColor;
    
}



@media all and (max-width: 961px) {
    
    .container1Det{
       
        margin-right: 20%;
      }
}
@media all and (max-width: 769px) {
  
    .container1Det{
       
        margin-right: 10%;
      }
      .details{
       
        font-size:38px;
    }
 
    .title{
        color: white;
        font-size:18px;
        padding: 20px 10px;
        width: 100%;
    }
    .details2{
        font-size:12px;
        margin-top: 20px;
        width:100%;
        padding: 20px 20px;
        font-size: 16px;
       
    }
}
@media all and (max-width: 615px) {
    .title{
        font-size:20px;    
    }
    .container1{
        height: 74vh;
        min-height: 461px;
    }
}
@media all and (max-width: 461px) {
  
    .container1{
        height: 62vh;
        min-height: 384px;
    }
    .details{
        font-size: 38px;
        margin-top: 15px;
       }
       .details2{
        font-size:12px;
        font-weight:300;
        margin-top: 10px;
    }
}
@media all and (max-width: 384px) {
    .container1{
        height: 307px;
    }
    .details{
        font-size: 23px;
        margin-top: 15px;
        line-height: 30px;
       }

    .title{
        font-size:14px;    
    }
    .container1Det{
        margin-left: 10px;
        margin-right: 10px;
        
      }
}*/


