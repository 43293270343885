 //navbar
 $primaryColor: #8d2855;
 $secondaryColor: #db5d96;
 $hoverColor:#d8b806;
.nav{
    height: auto;
    width: 100%;
    transition: 0.25s;
    box-sizing: border-box;
    z-index:11;
    padding-right: 50px;
    padding-left: 50px;
    font-family: 'Poppins', sans-serif;
    position: absolute;
}
.scrolled{
    width: 100%;
    position: fixed;
    background-color:$primaryColor;
    z-index:12;

}
.nav2{
    display: flex;
    height: 10vh;
    min-width: 60px;
    z-index: 9;
    //background-color: aqua;

}
.NavLogo{
    align-self: center;
    z-index: 100;
}
.centerNenuDiv{
    flex: 1;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    box-sizing: border-box;
    //padding-right: 53px;
    //padding-left: 38px;
    
    
}

.centerNenuDiv img{
    height:38px;
    width:115px;
    margin-top:6px;
    margin-bottom: 6px;
    //background-color: yellow;
   
   
}
.centerNenuDiv img:hover {
    transform: scale(1.06);
  }
.logInCont{
    display:flex;
    align-items: center;
    letter-spacing: 1px;
    color: white;
    font-size: 13px;
    //padding-left: 2%;
    font-weight: 600;
    flex-wrap: wrap;
   
    
}
.NavLogIn{
    color: white;
    text-decoration: none;
    font-size: 17px;
}

.logInCont p:hover{
   color: $primaryColor;
   cursor: pointer;
   border-bottom: solid;
    border-bottom-color: $primaryColor;
   border-bottom-width: 3px;
}
.signInIcon{
    margin-right: 5px;
    font-size: 25px;
}
/*.centerNenuDiv li{
    margin-left: 2%;
    font-weight: 650;
    font-size: 18px;
    align-self: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    color: white;
    padding-bottom: 5px;
    letter-spacing: 1.2px;
   
}*/
.centerNenuDiv li:after{
    content: '';
    display: block;
    margin: auto;
    height: 2px;
    width: 0em;
    background: transparent;
    transition: width .5s ease, background-color .5s ease;
}
.navIcons{
    margin-left:15px;
    color: #fff;
}
.navIcons:hover{
    color: #FEEB03;
    cursor: pointer;  
    border-bottom: solid;
    border-bottom-color: #FEEB03;
    padding-bottom: 3px;
    border-bottom-width: 1px;
}
.navBarUl{
    display:flex;
    flex: 1;
    flex-direction:row;
    list-style:none;
    box-sizing: border-box;
    //background-color: yellow;
    justify-content: flex-end;
    height: 100%;   
}
#ulDep2{
    list-style: none;
    display:flex;
    padding-left: 0px;
    flex-direction:column
}
#ulDep{
    position: absolute;
    list-style: none;
    color: black;
    display: none;
    padding-top: 30px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
    padding: 10px 0px 10px 10px;
    border-radius: 5px;
    background-color: white;
    z-index: 5;
}
#ulDep li{
    color:black;
    font-weight: 400;
    padding-bottom: 10px;
    //font-family: 'Poppins', sans-serif;
    //text-align: left;
}
#ulDep2 li{
    color:white;
    font-weight: 300;
    padding-bottom: 0px;
    font-family: 'Poppins', sans-serif;
    margin-top: -5px;
    margin-left: 20px;
}
#ulDep2 li:hover{
    color:$hoverColor;
    border-bottom: none;
}
#ulDep li:hover{
    cursor: pointer;
    color:$hoverColor
}
#ulDep a{
    padding-top: 50px;
   
}
#depLi:hover #ulDep{
    display: block;
}
.navBarUl li,.NavLi{
   
    font-weight: 400;
    font-size: 14px;
    align-self: center;
    padding-top: 3px;
    color: white;
    text-decoration: none;
    padding-bottom: 3px;
    margin-right: 20px;
   // height: 100%;
   // margin-left: 3%;
   
}
.NavLi:hover{
    color: $hoverColor;
    cursor: pointer;
    text-align: center;
    
  
}
#link2{
    font-weight: 500;
    font-size: 14px;
    align-self: center;
    padding-top: 3px;
    color: black;
    text-decoration: none;
    padding-bottom: 3px;
    margin-right: 20px; 
}
#link2:hover{
    color: $primaryColor;
    cursor: pointer;
    text-align: center;
    
  
}
.navBarUl .shopLi{
    background-color: $hoverColor;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    padding-bottom:7px;
    margin-left: 23px;
    text-decoration: none;
    color: white;
    font-size: 14px;
    font-weight: 600;
    
}
.navBarUl .shopLi:hover{
    color: white;
    border-bottom: solid;
    background-color: $hoverColor;
    border-bottom-width: 0em;
    cursor: pointer;
    text-align: center;
}

.openCloseIcons{
display: none;
align-items: center;
z-index: 100;
}
.navOpenIcon, .navCloseIcon{
    margin-left:15px;
    color: #fff;
}
.navOpenIcon:hover, .navCloseIcon:hover{
    color: $primaryColor;
    cursor: pointer;  
   
}

.menu2Div{
    padding-top: 20px;
    width:100%;
    padding-bottom: 20px;
    z-index: 9;
    background-color: #000;
    position: fixed;
    top:0;left:0;right:0;
    display: none;
}









.navBarUl2{
    flex-direction: column;
    list-style: none;
    padding-bottom: 15px;
    padding-top: 40px;
    background-color: black;
    width:100%;
    padding-left:5%;
}
.navBarUl2 li{
    font-weight: 700;
    font-size: 18px;
    padding-top: 23px;
    color: white;
    //text-align: center;
}
.navBarUl2 li:hover{
    color: $primaryColor;
    cursor: pointer;
}
.NavLi2{
    text-decoration: none;
    color: white;
    cursor: pointer;
}
.NavLi2:hover{
    //color: $hoverColor;
}
    

@media all and (max-width: 1200px) {
.logInCont{
    padding-left: 0px;
   
}
.navBarUl .shopLi{
    margin-left: 7px;
}

}
@media all and (max-width: 1000px) {
    .nav{
        padding-right: 20px;
        padding-left: 20px;
    }
    .navBarUl{
        padding-left: 0px;
    }
  
    }
    @media all and (max-width: 923px) {
        .logInCont p{
           // font-size: 12px;
            //color: $primaryColor;
           
        }
        .navBarUl li{
            margin-left: 2%;
           // font-size: 12px;
      
   }

   
   .navBarUl{
       margin-left: -15px;
   }
        
    }
@media all and (max-width: 846px) {

  
    .rightMenuDiv{
        display: none;
    }
    .navBarUl{
        display: none;
    }
    .openCloseIcons{
        display: flex;
        align-items: center;
       
        }
        .nav{
            padding-right: 53px;
            padding-left: 30px;
        }
        .menu2Div{display: block;}
        .navBarUl2{
            display: flex;
            }

}
@media all and (max-width: 538px) {
.scrolled{
  padding-top: 7px;
}
.nav{
    padding-right: 20px;
    padding-left: 20px;
}}
@media all and (max-width: 400px) {
    .nav{
        padding-right: 10px;
        padding-left: 10px;
    }
    .NavLogo{
        margin-top: 10px;
    }
}
@media all and (max-width: 384px) {
    
   }
   @media all and (max-width: 346px) {
    
   }


