$primaryColor: #8d2855;
$secondaryColor: #db5d96;
$hoverColor:#d8b806;
$theGrey1: #5F6162;
@mixin limitLine {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
  .statusBar{
    height: 70px;
    background-color: $primaryColor;
  }
.container{
    height: auto;
    width: 100%;
    min-height: 400px;
    padding-bottom: 50px;
}
.contVid{
  height: auto;
  color: black;
  display: flex;
  flex-direction: column;
  padding-left: 6%;
  padding-right: 6%;
  padding-bottom: 70px;
}
.headDiv{
  display:flex;
  padding-left:50px;
  padding-top: 15px;
  padding-bottom:30px;
  align-items: center;
}
.headDiv h2{
 font-weight:700;
 font-size: 14px;
 margin-right: 10px;
 cursor: pointer;
}
.headP1{
  border-radius: 5px;
  padding:5px 10px;
  color: $primaryColor;
}
.causesItemsCont{
    display:flex;
    flex:1;
    flex-wrap: wrap;
    padding-left:40px; 
    padding-right:40px;
  }
  .causesItemsCont2{
    display:flex;
    flex:1;
    flex-wrap: wrap;
    padding-left:50px; 
    padding-right:40px;
    padding-top: 10px;
  }
  .causedivzoom{
    box-sizing: border-box;
    height: auto;
    width:23%;
    background-color:#fff;
   justify-content:space-evenly;
   transition: transform .5s ease;
   text-decoration: none;
   color: black;
   //margin-top:-4px;
   margin: 3px;
   padding: 5px 5px 2px 5px;
   box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
 }
 .causedivzoom img{
    width:100%;
    height:50vh;
    min-height: 320px;
    object-fit:cover;
 }

.reactPlayerDiv{
  width: 31.5%;
  height: 320px;
  margin-right: 15px;
  margin-bottom: 30px;
  position: relative;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
  //background-color: yellow;
 
}
.listVidCont{
width: 100%;
height: 263px;
}
.artName{
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  @include limitLine();
  font-family: 'Rubik', sans-serif;
  margin-top: 10px;
  margin-left: 10px;
  color: $primaryColor;
  margin-bottom:1px;
}
.artTitle{
  font-size: 16px;
  font-weight: 500;
  @include limitLine();
  line-height: 20px;
  font-family: 'Rubik', sans-serif;
  margin-left: 10px;
}
.reactPlayerDiv2{
  height: 80%;
  width: 100%;
  background-color: white;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;


  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.playIC{
color: white;
background-color: $primaryColor;
padding: 10px 15px 10px 15px;
border-radius: 10px;
font-size: 20px;
}
.playIC:hover{
  background-color: red;
  transform: scale(1.1);
  cursor: pointer;
  
}
.reactPlayerDiv3{
    height: 80%;
    width: 100%;
    background-color: black;
}
 @media all and (max-width: 1000px) {
  .causesItemsCont{
    padding-left:20px; 
    padding-right:20px;
    
  }
  .causedivzoom{
    width:31%;
    margin:10px;
 }
 .causedivzoom img{
    width:100%;
    height:70vh;
    min-height: 310px;
 }
 .container p{
  margin-left:25px;
  font-size: 20px;
  
 }
 }
 @media all and (max-width: 900px) {
  .causesItemsCont{
    padding-left:20px; 
    padding-right:20px;
    
  }
  .causedivzoom{
    height: auto;
    width:45%;
    margin:10px;
 
 }
 .causedivzoom img{
    height:400px;
    min-height: 400px; 
 }
 }
 @media all and (max-width: 700px) {
  .causesItemsCont{
    padding-left:20px; 
    padding-right:20px;
    
  }
  .causedivzoom{
    width:46%;
    margin:5px;
  
 }
 .causedivzoom img{
    height:300px;
    min-height: 300px;
    
 }
 .container p{
  margin-left:5px;
  font-size: 16px;
  padding-top:10px;
 }
 }
 @media all and (max-width: 400px) {
  .causesItemsCont{
    padding-left:0px; 
    padding-right:0px;
    
  }
  .causedivzoom{
    height: auto;
    width:46%;
    background-color:#F9F8F8;
    margin:5px;
 }
 .causedivzoom img{
    height:250px;
    min-height: 250px;
 }
}
@media all and (max-width: 975px) {
  .reactPlayerDiv{
      width: 48%;
      height: 300px;
      margin-right: 15px;
      margin-bottom: 15px;
     
  }

}
@media all and (max-width: 900px) {
  .reactPlayerDiv{
      width: 47%;
      margin-right: 15px;
      margin-bottom: 23px;
     
  }

}
@media all and (max-width: 675px) {
  .reactPlayerDiv{
      width: 100%;
      height: 412px;
      margin-bottom: 23px;
     
  }
  .causesItemsCont2{
    padding-left:20px; 
    padding-right:20px;
    padding-top: 10px;
  }
  .headDiv{
    padding-left:20px;
    padding-top: 15px;
    padding-bottom: 5px;
  }

}
@media all and (max-width: 525px) {
  .reactPlayerDiv{
      width: 100%;
      height: 322px;
      margin-bottom: 23px;
      margin-right: 0px;
  }
  .headDiv h2{
    font-weight:500;
    font-size: 12px;
    margin-right: 5px;
   }

}
@media all and (max-width: 450px) {
  .reactPlayerDiv{
      width: 100%;
      height: 300px;
      margin-bottom: 23px; 
  }
  .causesItemsCont2{
    padding-left:10px; 
    padding-right:10px;
    padding-top: 10px;
  }
  .headDiv{
    padding-left:10px;
    padding-top: 15px;
    padding-bottom: 5px;
  }
  .headP1{
    border-radius: 5px;
    padding:5px 5px
  }
}
@media all and (max-width: 375px) {
  .reactPlayerDiv{
      width: 100%;
      height: 300px;
      margin-bottom: 23px;
     
  }

}
@media all and (max-width: 300px) {
  .reactPlayerDiv{
      width: 100%;
      height: 187px;
      margin-bottom: 15px;
     
  }
  .artName{
      font-size: 14px;
      font-weight: 800;
      margin-top: 4px;
      line-height: 19px;
     
  }
  .artTitle{
      font-size: 14px;
      font-weight: 400;
      @include limitLine();
     // font-family: 'Roboto', sans-serif;
  }

}