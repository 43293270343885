

 $primaryColor: #8d2855;
 $secondaryColor: #db5d96;
 $hoverColor:#d8b806;
 $theGrey1: #5F6162;
@mixin maxlinelength {
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   /* number of lines to show */
   -webkit-box-orient: vertical;
}
.container01{
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
    padding-bottom: 15px;
    
  }
  .modal{
      position:fixed;
      top:0;bottom:0;left:0;right:0;
      background-color: #00000022;
      width: 100%;
      z-index: 99;
      display: flex;
      justify-content: center;
  }
  .modal2{
      box-sizing: border-box;
      background-color: #fff;
      width: 500px;
      height: 95vh;
      //margin: 20px 0px;
      overflow-y: auto;
      padding: 0px 5px 20px 5px;
  }
  .ICDiv2A{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: -20px;
  }
  #ICDiv2P{
    white-space: pre-line
  }
.statusBar{
   // height: 70px;
   // background-color: $primaryColor;
  }
  .imgDiv{
   position: relative;
   height:40vh;
   min-height:300px;
  }
  .imgDiv img{
    width:100%;
    height: 100%;
    object-fit:cover;
  }
  .imgDiv h1{
    font-size: 60px;
    color: black;
    //font-family: 'Poppins', sans-serif;
    color: white;
    padding: 5px 20px;
    margin-left: 40px;
    font-weight: 600;
    position: absolute;
    top:0;left:0;
    margin-top: 200px;
    
   }
   .causedivzoom{
    display: flex;
    flex-direction: column;
     height: auto;
     padding-bottom: 15px;
     width:31%;
     background-color:#F9F8F8;
     margin:10px;
    justify-content:center;
    transition: transform .5s ease;
    align-items: center;
    border: 1px solid #eee;
    text-decoration: none;
    color: black;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;

  }
  .modal2 h4{
    font-weight: 400;
    font-size: 14px;
    color:$primaryColor;
    margin-top: -5px;
    margin-bottom:10px;
    text-decoration: underline;
}
  .causedivzoom h4{
      font-weight: 400;
      font-size: 14px;
      color:$primaryColor;
      margin-top: -5px;
      margin-bottom:10px;
      text-decoration: underline;
  }
  .ICDiv{
      background-color: #fbe8f1;
      border-radius: 80px;
      margin-top: 20px;
      padding: 20px;
      margin-bottom: 10px;
  }
  .ICDiv2{
    background-color: #fbe8f1;
    border-radius: 80px;
    margin-top: 20px;
    padding: 20px;
    margin-bottom: 10px;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    align-self: center;
}
  .causedivzoom h1{
    padding-top:5px;
    font-size:22px;
    padding-right:7px;
    padding-left:7px;
    text-align: center;

  }
  .modal2 h1{
    padding-top:5px;
    font-size:22px;
    padding-right:7px;
    padding-left:7px;
  }
  .modal2 p{
    font-size:16px;
    padding-right:5px;
    padding-left:7px;
    margin-top:3px;
    margin-bottom:7px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.3;
    
  }
  .causedivzoom p{
    font-size:16px;
    padding-right:5px;
    padding-left:7px;
    margin-top:3px;
    margin-bottom:7px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.3;
    -webkit-line-clamp: 8;
    @include maxlinelength();
    text-align: center;
  }
  .causedivzoom h5{
    font-size:16px;
    padding-right:5px;
    padding-left:7px;
    margin-top:3px;
    margin-bottom:7px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.3;
    font-weight: 500;
  }
  .causesItemsCont{
    display:flex;
    flex:1;
    flex-wrap: wrap;
    padding-left:40; 
    padding-right:40;
    //justify-content: space-between;

  }
  .donateDiv3{
    background-color:$primaryColor;
     width:136px;
     height: 38px;
     display:flex;
     justify-content:center;
     align-items: center;
     margin-left: 7px;
     border-radius: 5px;
     align-self: center;
     text-decoration: none;
  }
  .causedivzoom .donateDiv3 p{
    color:#fff;
    font-weight:700;
   // margin:0;
    letter-spacing:0px;
    font-size: 15px;
    text-align: 'center';
  }
  .donateDiv3:hover{
    cursor: pointer;
    background-color: $hoverColor;
    
}
.container2{
    padding: 50px 50px;
}
  @media all and (max-width: 950px) {
   .causedivzoom{
     width:46%;
   }
   
}
@media all and (max-width: 684px) {
    .causedivzoom{
     width:45%;
   }
   .causedivzoom h1{
    font-size:22px;
     }
}
   @media all and (max-width: 500px) {
    .imgDiv{
        position: relative;
        height:18vh;
        min-height:150px;
        //height:55vh;
       }
       .causedivzoom h1{
        font-size:19px;
         }
      .imgDiv h1{
        font-size: 30px;
        margin-left: 0px;
        padding: 0px 10px;
        margin-top: 100px;
       }
       .causedivzoom{
        width:45%;
      }
      .container2{
        padding: 10px 0px;
    }
   }
   @media all and (max-width: 420px) {
    .causedivzoom{
      width:100%;
    }
    .causedivzoom h1{
        font-size:20px;
         }
}
@media all and (max-width: 342px) {
     .causedivzoom h1{
      font-size:20px;
       }
     
    }