
//orderontransit
$primaryColor: #eb3e32;
$secondaryColor: #FED800;
$hoverColor:#FBCD00;
$shopColor:#bfaf80;
@mixin maxlinelength {
    overflow: hidden;
     text-overflow: ellipsis;
     display: -webkit-box;
     /* number of lines to show */
     -webkit-box-orient: vertical;
  }
.container{
    height: auto;
    font-family: 'Poppins', sans-serif;
   
}
.headingTitle{
    padding-left: 23px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 19px;
    font-weight: 600;
   }
   .contOT{
    display: flex;
    flex: 1;
   }
   .contOTA{
      // background-color: yellow;
       width: 100%;
       height: auto;
       display: flex;
       flex-direction: column;
       padding-left: 23px;
       padding-right: 23px;
       padding-bottom: 38px;
      
   }
   .container2{
    margin-bottom: 50px;
    //background-color: $primaryColor;
   }
   .itemsList1{
       height: 25vh;
       min-height: 150px;
       border-style: solid;
       border-width:1px;
       border-color: whitesmoke;
       border-radius: 3px;
       display: flex;
       line-height: 19px;
   }
   .imageDiv{
       height: 25vh;
       width: 25vh;
       min-height: 150px;
       border-right-style: solid;
       border-right-width:1px;
       border-right-color: whitesmoke;
       display: flex;
       align-items: center;
       justify-content: center;
   }
   .imageDiv img{
       height: 70%;
   }
   .postDetails{
       padding: 15px 23px 15px 23px;
       display: flex;
       flex-direction: column;
       flex: 1;
   }
   .postDetails1{
       width: 100%;
       display: flex;
       justify-content: space-between;
   }
   .postDetails1 p{
     font-size: 16px;
     -webkit-line-clamp: 1;
     @include maxlinelength();
   }
   .postDetails1 h5{
       font-size: 13px;
       //background-color: wheat;
       border-radius: 3px;
       padding:2px 5px;
       color: $primaryColor;
       font-weight: 400;
       border-color: $primaryColor;
       border-width:1px;
       border-style: solid;
     }
     .postDetails1 h5:hover{
      color: white;
      cursor: pointer;
      background-color: black;
      border-color: black;
      }
     .postDetailsP1{
       font-size: 15px;
       margin-top: 3px;
     }
     .postDetailsP2{
       font-size: 19px;
       font-weight: 600;
       margin-top: 5px;
     }
     .postDetailsP3{
      background-color:$primaryColor;
      display: flex;
      color: white;
      width: 100px;
      padding: 3px 7px 3px 11px;
      border-radius: 3px;
      margin-top: 5px;
     }
     .postDetailsP4A{
         display: flex;
         margin-top: 7px;  
     }
     .postDetailsP4B{
         margin-right: 30px;
         font-size: 15px;
         font-weight: 500;
     }
     .postDetailsP4C{
       margin-right: 30px;
       font-size: 15px;
       color: rgb(0, 0, 0);
       font-weight: 500;
   }
   .postDetailsP4D{
    font-size: 15px;
    font-weight: 500;
    border-radius: 5px;
   }
   .statusDiv{
       display: flex;
       align-items: center;
   }
   .statusDiv h5{
       padding-top: 3px;
       font-size: 16px;
       font-weight: 400;
       margin-right: 7px;
       
   }
   .contBottom{
       height: 30px;
      // background-color: yellow;
       display: flex;
       border-color: whitesmoke;
       border-style: solid;
       border-width: 0px 1px 1px 1px;
       display: flex;
   }
   .contBottom1{
     border-right-color: whitesmoke;
     border-right-style: solid;
     border-right-width: 1px;
     width: 35%;
     display: flex;
     align-items: center;
     justify-content: center;
     padding-left: 5px;
     padding-right: 5px;
   }
   .contBottom2{
    border-right-color: whitesmoke;
    border-right-style: solid;
    border-right-width: 1px; 
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
     padding-right: 5px;
}
.contBottom3{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
     padding-right: 5px;
     color: $primaryColor;
}
.contBottom3:hover{
    background-color: $primaryColor;
    color: white;
}
#postDetailsP12{
    display: none;
}
.contBottom{
    display: none;
}
   @media all and (max-width: 1153px) {
   .container{
       //background-color: red;
   }
   .postDetails1 p{
    font-size: 15px;
  }
  .postDetails1 h5{
      font-size: 12px;
    }
    .postDetailsP4B{
        font-size: 13px;
        margin-right: 15px;
       
    }
    .postDetailsP4C{
      font-size: 13px;
      margin-right: 7px;
  }
  .statusDiv h5{
    padding-top: 3px;
    font-size: 15px;
    font-weight: 400;
    margin-right: 7px;
}
.postDetailsP3{
    background-color:$primaryColor;
    display: flex;
    color: white;
    width: 80px;
    height: 23px;
    padding: 0em 0em 0em 0em;
    border-radius: 3px;
    margin-top: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
   }
   .postDetails{
    padding: 15px 15px 15px 15px;
}
   }
   @media all and (max-width: 1000px) {
    .container{
        //background-color: red;
    }
    /*.itemsList1{
        height: 180px;
    }
    .imageDiv{
        height: 180px;
        width: 180px;
    }*/
    .postDetailsP4A{
        flex-direction: column;
    }
    .postDetails1 p{
        font-size: 13px;
      }
      .postDetails1 h5{
          font-size: 11px;
        }
        .postDetailsP4B{
            font-size: 10px;
            margin-right: 15px;
             
        }
        .postDetailsP4C{
          font-size: 10px;
          margin-right: 7px;
      }
      .statusDiv h5{
        padding-top: 3px;
        font-size: 13px;
        font-weight: 400;
        margin-right: 7px;
    }
    .postDetailsP3{
        background-color:$primaryColor;
        display: flex;
        color: white;
        width: 76px;
        height: 21px;
        padding: 0em 0em 0em 0em;
        border-radius: 3px;
        margin-top: 5px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
       }
       .postDetails{
        padding: 11px 23px 15px 23px;
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    .postDetailsP2{
        font-size: 16px;
        font-weight: 600;
        margin-top: 5px;
      }
      .postDetailsP1{
        font-size: 13px;
        margin-top: 0em;
      }
}

@media all and (max-width: 538px) {
    .postDetails{
        padding: 11px 7px 15px 11px;

    }
    .postDetails1 h5{
        font-size: 10px;
        padding:1px 2px 2px;
       
      }


      .itemsList1{
        height: 20vh;
        min-height: 120px;
        line-height: 19px;
    }
    .imageDiv{
        height: 20vh;
        width: 20vh;
        min-height: 120px;

    }
    .imageDiv img{
        height: 70%;
    }
    .postDetailsP4A{
        display: none;
    }
    .postDetails1 h5{
        display: none;
    }
    .statusDiv h5{
        display: none;
    }
    .contBottom{
        display: flex;
    }
    .postDetails1 p{
        font-weight: 600;
        -webkit-line-clamp: 2;
        @include maxlinelength();
      }
      .container2{
        margin-bottom: 30px;
        //background-color: $primaryColor;
       }
}
@media all and (max-width: 450px) {
    .container{
        font-family: 'Poppins', sans-serif;
    }
    
}
@media all and (max-width: 400px) {
    .headingTitle{
        font-size: 16px;
        padding-left: 10px;
       }
       
       .postDetailsP1{
        font-size: 13px;
        margin-top: 5px;
      }
       #postDetailsP12{
        display: flex;
    }
    #postDetailsP11{
        display: none;
    }
      .itemsList1{
        height: 20vh;
        min-height: 120px;
        line-height: 19px;
    }
    .imageDiv{
        height: 20vh;
        width: 15vh;
        min-height: 120px;

    }
    .imageDiv img{
        height: 50%;
    }
    .contOTA{
         padding-left: 5px;
         padding-right: 5px;
         padding-bottom: 38px;
        
     }
     .postDetailsP4B{
        font-size: 10px;
        font-family: 'Rubik', sans-serif;
        font-weight: 700;
       
    }
    .postDetailsP4C{
        font-size: 10px;
        font-family: 'Rubik', sans-serif;
        font-weight: 700;
       
    }
    .container2{
        margin-bottom: 20px;
    }
   
}


