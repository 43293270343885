
 $primaryColor: #8d2855;
 $secondaryColor: #db5d96;
 $hoverColor:#d8b806;
 $theGrey1: #5F6162;
.container{
    height: auto;
    width: 100%;
    background-color: whitesmoke;
    padding: 0px 10px;
    box-sizing: border-box;
    
}
.statusBar{
    height: 70px;
    background-color: $primaryColor;
  }
.container2{
    height: auto;
    width:500px;
    padding-bottom: 30px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: white;
    min-height: 400px;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.8), 
        rgba(51, 0, 0, 0.8)
      ),url('https://images.pexels.com/photos/1029783/pexels-photo-1029783.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
      background-repeat: no-repeat;
     background-size: 100% 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.container1{
    display: flex;
    width:100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
}
.dP1{
    text-align: center;
    color: white;
    margin-top: 20px;
    font-size: 25px;
}
.dP2{
    text-align: center;
    font-size: 12px;
    color: white;
    padding: 10px 20px 10px 20px;
    line-height: 20px;
    letter-spacing: 1px;
    font-weight: 300;
}
.dP2A{
    text-align: center;
    font-size: 14px;
    color: white;
    padding: 0px 20px 5px 20px;
    line-height: 20px;
    letter-spacing: 1px;
    font-weight: 300;  
}
.dP2b{
    margin-top: 20px;
    color: white;
}
.dP3{
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 16px;
}
.dP4{
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
    letter-spacing:1px;
    border: 1px solid white;
    padding:2px 5px;
}
.dP4B{
    text-align: center;
    color: white;
    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;
    letter-spacing:1px;
    border: 1px solid white;
    padding:2px 5px; 
    cursor: pointer;
    text-decoration: none;
}
.theImage{
    height: 30px;
    width: 60px;
    background-color: white; 
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.theImage2{
    height: 30px;
    width: 60px;
    background-color: white; 
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 5px;
}
@media all and (max-width: 500px) {
    .container2{
        width:400px;
       
    }
}
@media all and (max-width: 500px) {
    .container2{
        width:100vw;
       margin-top: 80px;
    }
   
}

