$primaryColor: #8d2855;
$secColor: #d8b806;
$theGrey1: #5F6162;
.teamDiv{
    margin-top: 50px;
}
.container{
    box-sizing: border-box;
    overflow:hidden}
    .theCont{
        padding: 30px 50px 0px 50px;
        position: relative;   
    }
    .GDiv4{
        height: 360px;
        width: 100%;
       /* background: linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url("https://images.pexels.com/photos/3184418/pexels-photo-3184418.jpeg?auto=compress&cs=tinysrgb&w=600");
       background-repeat: no-repeat;
       background-position: center;
       background-size: 100% auto;
       background-size: cover;
       box-sizing: border-box;
       background-attachment: fixed;*/
       padding: 0px 20px;
       margin-bottom: 50px;
      }
    
    .servP1{
        text-align: center;
        font-size: 24px;
        color:$theGrey1;
        font-weight: 500;
        margin: 0px 20px;
        }
        .servP2{
            font-weight: 600;
            text-align: center;  
            font-size: 34px;
            margin-bottom:30px;
            color:$primaryColor;
            font-family: 'Roboto', sans-serif;
           
        }
        .partDiv{
            display: flex;
            justify-content: center;
          }
        .listCont{
            min-height:200px;
            min-width:200px;
            height:200px;
            width:200px;
            margin:20px;
            overflow: hidden;
            position: relative;
            border: 2px solid #817c7e;
            box-sizing: border-box;
            padding: 10px;
            background-color: #fff;
            //border-radius:100px;
           
          }
          .listImg{
            width:100%;
            height: 100%;
            object-fit: contain;
            border-radius:100px;
           
          }
          .causesDetCont{
            display:flex;
            flex-direction:column;
            align-items:center;
            width:100%;
            justify-content: center;
            
           
          }
          .causesDetCont h3{
            margin: 0em;
           font-size: 26px;
           font-weight: 700;
           font-family: 'Poppins', sans-serif;
           color: $theGrey1;
           margin-top: 20px;
           margin-bottom: 5px;
           
          }
          .causesDetCont p{
           font-size:36px;
           font-weight: 700;
           padding-bottom: 0px;
           font-family: 'Roboto', sans-serif;
           align-self: center;
           color: $primaryColor;
           margin-bottom: 5px;
           
          }
          .GDiv{
            height: 300px;
            width: 100%;
            background-color: black;
            background: linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url("https://images.pexels.com/photos/7063760/pexels-photo-7063760.jpeg?auto=compress&cs=tinysrgb&w=600");
           background-repeat: no-repeat;
           background-position: center;
           background-size: 100% auto;
           background-size: cover;
           background-color: rgba(0,0,0,0.25);
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content:center;
           background-attachment: fixed;
           margin-top: 40px;
          }
    @media all and (max-width:1100px) {
        .theCont{
            padding: 30px 50px 0px 50px;
        }}
        @media all and (max-width: 1000px) {
            .listCont{
              min-height:150px;
              min-width:150px;
              height:150px;
              width:150px;
              margin:10px;
              overflow: hidden;
              position: relative;
            }
            .GDiv4{
                height: 300px;
                width: 100%;
                padding: 0px 20px;
              }
        }
            @media all and (max-width: 700px) {
                .listCont{
                  min-height:120px;
                  min-width:120px;
                  height:120px;
                  width:120px;
                  margin:5px;
                  overflow: hidden;
                  position: relative;
                }
            .theCont{
                padding: 30px 30px 0px 30px;
            }}
            @media all and (max-width:600px) {
                .theCont{
                    padding: 30px 20px 0px 20px;
                }}
                @media all and (max-width:500px) {
                .listCont{
                    min-height:100px;
                    min-width:100px;
                    height:100px;
                    width:100px;
                    margin:10px;
                  }
                  .GDiv4{
                    height: 250px;
                    width: 100%;
                    padding: 0px 20px;
                  }
                  .causesDetCont h3{
                    font-size: 30px;
                    font-weight: 700;
                    margin-top: 20px;
                    margin-bottom: 5px;
                    
                   }
                   .causesDetCont p{
                    font-size: 20px;
                    font-weight: 700;
                    padding-bottom: 0px;
                    margin-bottom: 5px;
                    text-align: center;
                    
                   }
                }
                @media all and (max-width:420px) {
                    .theCont{
                        padding: 30px 10px 0px 10px;
                    }
                    .servP1{
                        font-size: 18px;
                        font-weight: 500;
                        margin: 0px 20px;
                        margin-bottom: 5px;
                        }
                       
                        .servP2{
                            //font-weight: 500;
                            font-size:22px;
                            margin-bottom:0px;
                        }
                        .listCont{
                            min-height:80px;
                            min-width:80px;
                            height:80px;
                            width:80px;
                            margin:5px;
                          }
                          .GDiv4{
                            height: 240px;
                            width: 100%;
                            padding: 0px 20px;
                          }
                }
                @media all and (max-width: 360px) {
                    .GDiv4{
                      height: 230px;
                      width: 100%;
                      padding: 0px 20px;
                    }
                    .listCont{
                      min-height:70px;
                      min-width:70px;
                      height:70px;
                      width:70px;
                      margin:4px;
                    }
                    .causesDetCont h3{
                        font-size: 25px;
                      }
                }