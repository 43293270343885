//recently viewed
$primaryColor: #FF7C59;
$secondaryColor: #FED800;
$hoverColor:#FBCD00;
$shopColor:#bfaf80;
.container{
    height: auto;
    font-family: 'Poppins', sans-serif;
}
.headingTitle{
    padding-left: 23px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 19px;
    font-weight: 600;
   }

.container2{
    padding-top: 15px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: space-between;
   
   
}
.myItemsListCont{
    width: 22.5%;
    height: 384px;
    margin-right: 1%;
    margin-left: 1%;
    text-decoration: none;
    padding-bottom: 7px;
    margin-bottom: 23px;
    //background-color: yellow;
  }
 .myItemsListCont:hover{
    
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
 }
  .imageDiv{
    
    height: 269px;
    border-bottom-color: whitesmoke;
    border-bottom-width:1px;
    border-bottom-style: solid;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    position: relative;
  }
  .theImage{
    height: 70%;
    //border-radius: 15px;
 }
 .clothListDetCont{
    display: flex;
    flex-direction: column;
    height: 76px;
    align-items: center;
    //margin-bottom: 15px;
}
.clothListDetCont p{
    font-size:16px;
    font-weight: 400;
     padding-top: 7px;
     color: #000;
     font-family: 'Rubik', sans-serif;
    // font-family: 'Poppins', sans-serif;
     //font-family: 'Ubuntu', sans-serif;
     
}
.clothListDetCont h4{
  font-size:16px;
  font-weight: 500;
  padding-top: 0em;
  //padding-bottom: 7px;
  color: $primaryColor;
  font-family: 'Poppins', sans-serif;
  //font-family: 'Ubuntu', sans-serif;
}
.addToCart{
    min-height: 30px;
    border-radius: 15px;
    width: 115px;
    display: flex;
    align-items: center;
    background-color: black;
    align-self: center;
    justify-content: center;
  }
  .addToCart:hover{
      background-color: $primaryColor;
  }
  
  .addToCart h6{
      font-size: 11px;
      color:white
  }
  .cartIcon{
      //background-color:$shopColor;
      color: white;
      padding: 7px;
      font-size: 15px;
      margin-right: 2px;
      border-style: solid;
      border-color: whitesmoke;
      border-width:1px;
  }
  .cartIcon:hover{
      background-color: whitesmoke;
      color: $shopColor;
      cursor: pointer;
  }
  .ratingDiv{
    padding-top: 7px;
  }
  .ratingIcon{
      color: rgb(255, 238, 0);
      
  }

  @media all and (max-width: 1307px) {
    .myItemsListCont{
        width: 31%;
        height: 384px;
        //margin-left: 2%;
      
       
      }
    
}
@media all and (max-width: 923px) {
    .myItemsListCont{
        width: 48%;
        height: 384px;
        //margin-left: 2%;
      
      }
    
}
@media all and (max-width: 692px) {
    .myItemsListCont{
        width: 31%;
        height: 384px;
        //margin-left: 2%;
      }
    
}
@media all and (max-width: 615px) {
    .myItemsListCont{
        width: 48%;
        height: 384px;
        //margin-left: 2%;
      }
    
}
@media all and (max-width: 461px) {

    .container{
        font-family: 'Roboto', sans-serif;
    }
    .headingTitle{
        padding-left: 15px;
        padding-top: 15px;
        padding-bottom: 0px;
        font-size: 16px;

    }
    .myItemsListCont{
        height: 307px;
        margin-bottom: 7px;
        //background-color: yellow;
    }
    .imageDiv{
        height: 192px;
       }
       .clothListDetCont p{
        font-size:15px;
        font-weight: 500;
        padding-top: 3px;  
    }
    .clothListDetCont h4{
      font-size:16px;
      padding-top: 3px;
      padding-bottom: 3px;
  }
}

@media all and (max-width: 384px) {
    .clothListDetCont p{
        font-size:13px;
        font-weight: 500;
        padding-top: 3px;
         
         
    }
    .clothListDetCont h4{
      font-size:15px;
      padding-top: 3px;
      padding-bottom: 3px;
      
  }
.myItemsListCont{
    height: 300px;
  }
.addToCart{
    min-height: 26px;
    border-radius: 15px;
    width: 100px;
    display: flex;
    align-items: center;
    background-color: black;
    align-self: center;
    justify-content: center;
  }
 
  .addToCart h6{
      font-size: 10px;
  }
  
}

@media all and (max-width: 292px) {
    .myItemsListCont{
        height: 246px;
      
      }
    .imageDiv{
        height: 153px;
       }
    .clothListDetCont p{
        font-size:11px;
        font-weight: 500;
        padding-top: 3px;
         
         
    }
    .clothListDetCont h4{
      font-size:13px;
      padding-top: 3px;
      padding-bottom: 3px;
      
  }
}



