
//members
$primaryColor: #8d2855;
$secColor: #d8b806;
@mixin maxlinelength {
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   /* number of lines to show */
   -webkit-box-orient: vertical;
}

.bodySection3{
   box-sizing: border-box;
    height: auto;
    overflow: hidden;
    padding-bottom: 38px;
    //background-color: #FBCD00;
}

.causedivzoom{
    height: auto;
    width:23%;
    background-color:$secColor;
   // margin:7px;
    justify-content:space-evenly;
    transition: transform .5s ease;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
    margin-bottom: 15px;
   // margin-right: 5px;
   // margin-left: 5px;
    position: relative;
 
  }
  .causedivzoom img{
    width:100%;
    height:298px;
    min-height: 45vh;
    object-fit:cover;
  }
  .causedivzoom h2{
    padding: 0;
    margin: 0;
    font-size:20px;
    font-weight:500;
    padding-right:7px;
    padding-left:7px;
    padding-top:10px;
    -webkit-line-clamp: 1;
    @include maxlinelength();
    text-align: center;
    color:#FFF;
   
  }
  .causedivzoom h3{
    padding-right:7px;
    padding-left:7px;
    font-size: 14px;
    color: #FFF;
    -webkit-line-clamp: 1;
    @include maxlinelength();
    text-align: center;
    font-weight: 500;
    margin-top: 2px;
  
  }
  .causedivzoom p{
    font-size:14px;
    padding-right:7px;
    padding-left:7px;
    margin-top:10px;
    margin-bottom:20px;
    color: black;
    -webkit-line-clamp: 3;
    @include maxlinelength();
    line-height: 20px;
    //text-align: center;
  }
  .causedivzoom2{
    background-color: $primaryColor;
    padding-bottom:20px;
    padding-top: 5px;
  }
  .linkDiv{
    background-color: $primaryColor;
    padding:5px 5px 2px 5px;
    position: absolute;
    top:0;left:0;
    z-index: 5;
    margin: 10px 0px 0px 10px;
    border-radius: 5px;
  }
 .linked{
   color:#fff;
   font-size: 20px;
 }
  .causesDetCont{
    display:flex;
    flex-direction:column;
    align-items:center;
    height: 152px;
    background-color: #4b0161;
    width:100%;
    justify-content: center;
   
   
  }
  .causesDetCont h2{
    margin: 0em;
   font-size: 45px;
   color: white;
   padding-bottom: 15px;
   font-family: 'Teko', sans-serif;
   
  }

  .causesItemsCont{
    display:flex;
    flex:1;
    flex-wrap: wrap;
    justify-content: space-between;
    
  }
  
  @media all and (max-width: 1200px) {
  
  }

  @media all and (max-width: 950px) {
    .causesDetCont h2{
      font-size: 30px;
     }
     .img-hover-zoom{
      width:30%;
    }
    .causedivzoom{
      width:47%;
     
    }
    
}
@media all and (max-width: 760px) {
  .causedivzoom img{
     min-height:150px; 
     height:25vh;   
  }
  

}
@media all and (max-width: 684px) {
  
  .causesDetCont h2{
    font-size: 30px;
   }
   .causedivzoom{
    width:47%;
    margin-right: 5px;
    
  }
  .causedivzoom h2{
   font-size:25px;
   padding-top:10px;
    }
    .causedivzoom p{
      font-size:10px;
       }
   
}
@media all and (max-width: 608px) {

 /* .causedivzoom img{
     height:228px;    
  }*/
}




@media all and (max-width: 532px) {
  .causesDetCont h2{
    font-size: 30px;
   }

  .causedivzoom{
    width:47%;
    margin-top: 0px;
  }
  .causedivzoom h2{
   font-size:20px;
    }
    .causedivzoom p{
      font-size:10px;
       }
      
}

 @media all and (max-width: 456px) {
 
  .causedivzoom{
    width:46%;
  }
  .causedivzoom h2{
   font-size:25px;
    }
    .causedivzoom p{
      font-size:10px;
       }
    
     .causesDetCont h2{
      font-size: 30px;
     }
}
   @media all and (max-width: 420px) {
    .bodySection3{
     margin-top: 30px;
   }
    .causedivzoom{
      width:100%;
    }
    .causedivzoom h2{
     font-size:20px;
     padding: 0px 10px;
     padding-top:10px;
    // margin-top: 10px;
      }
      .causedivzoom p{
        font-size:16px;
        padding: 0px 10px;
         }
         .causedivzoom img{
          height:40vh; 
          min-height:270px;   
       }
       .causedivzoom h3{
        font-size:16px;
        padding: 0px 10px;
      
      }
   }
   @media all and (max-width: 380px) {
    
  .causedivzoom h2{
    font-size:22px;
    padding: 0px 10px;
    padding-top:10px;
     }
     .causedivzoom h3{
      font-size:16px;
      padding: 0px 10px;
    
    }
  .causedivzoom p{
    font-size:14px;
    padding: 0px 10px;
     }
   }
     @media all and (max-width: 342px) {
      .img-hover-zoom{
        width:100%;
        margin:3px;
       
       }
       .img-hover-zoom img{
        width:100%;
        height:266px;
        
       }
       .causedivzoom p{
        font-size:12px;
         }
         .causedivzoom h2{
          font-size:20px;
           }
           .causesDetCont h2{
            font-size: 25px;
           }
      }
