
//home welcome
$primaryColor: #8d2855;
$hoverColor:#FBCD00;
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;700&display=swap');

.mainContainer{
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    //margin-bottom: 15px;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 30px;
    overflow: hidden;
    //background-color: aqua;
    width: 100%;

}
.welcomeCont{
    background-color:white;
    padding: 80px 0px;
}
.welcomeCont2{
    height: 114px;
    background-color:white;
    margin-top: 15px;
    margin-bottom: -22px;
    display: none;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
}
.welcomeCont2 p{
    color: black;
    font-size: 38px;
    font-family: 'Teko', sans-serif;
    font-weight: 700;
    
}

.leftSideBar{
    //width: 100%;
    display: flex;
    transition: transform .5s ease;
    justify-content: space-around;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
    border: 5px solid $primaryColor;
}
.rightSideBar{
    width: 60%;
    display: flex;
    align-items: center;
    padding-left: 30px;
    //background-color: #8d2855;

}
.leftSideBarA{
    
    height: 100%;
    display: flex;
    align-items: center;
    width:40%;
    
    //background-color: #8d2855;
    //height: 320px;
}
.leftSideBar1{
 display: flex;
 flex:1;
 height: 150px;
}
.leftSideBar2{
    display: flex;
    flex:1;
    //margin-top: 10px;
   }
.leftSideBar1 img{
    object-fit:cover; 
    margin-right:0px; 
    height: 200px;
    width: 200px;
    max-height: 200px;
}
.leftSideBar2 img{
    height: 200px;
    object-fit:cover;  
    margin-right:0px;
    max-height: 200px;
    width: 200px;
    margin-top: 5px;
}
.img2{
    margin-left:5px;
}
.img4{
    margin-left:5px;
}

.rightSideBar .container{
   // padding-right: 76px;
    align-content: center;
    display: flex;
    flex-direction: column;
    padding-right: 30px;
}
.donateDiv{
     display:flex;
     background-color:$primaryColor;
     width:152px;
     height: 45px;
     justify-content:center;
     align-items: center;
     margin-top:15px;
     //border-radius: 22px;
     font-family: 'Teko', sans-serif;
     text-decoration: none;
     
}
.donateDiv:hover{
    cursor: pointer;
    background-color:$hoverColor;
}
.donateDiv p{
    font-size:20px;
    color:#fff;
    font-weight:700;
    letter-spacing:1.2;
    font-family: 'Poppins', sans-serif;
}

.rightSideBar .container h1{
    font-size:36px;
    font-weight:700;
    line-height: 40px;
    color: #000;
    margin-bottom: 10px;
    font-family: 'Roboto', sans-serif;
}
.rightSideBar .container h3{
    font-size:32px;
    font-weight:600;
    line-height: 1;
    color: $primaryColor;
    letter-spacing:1px;
    font-family: 'Roboto', sans-serif;
}
.enpowerP2{
    font-size:18px;
    margin-top:10px;
    margin-bottom: 10px;
    line-height:30px;
    font-family: 'Poppins', sans-serif;
}
@media all and (max-width: 1064px) {
    .mainContainer{
        padding-left: 5%;
        padding-right: 5%;
    }
    .leftSideBar1 img{
        height: 150px;
        width: 150px;
        max-height: 150px;
    }
    .leftSideBar2 img{
        height: 150px;
        max-height: 150px;
        width: 150px;
        margin-top: 5px;
    }
}
@media all and (max-width: 988px) {
  
}
@media all and (max-width: 836px) {
          
  }
  @media all and (max-width: 745px) {
    .leftSideBarA{
        height: 100%;
        width:100%;
        justify-content: center;
        order: 2;
        //height: 320px;
    }
    .leftSideBar1 img{
        object-fit:cover; 
        margin-right:0px; 
        height: 200px;
        width: 250px;
        max-height: 200px;
    }
    .leftSideBar2 img{
        height: 200px;
        object-fit:cover;  
        margin-right:0px;
        max-height: 200px;
        width: 250px;
        margin-top: 5px;
    }
      .welcomeCont2{
          //display: flex;
      }
   
      .rightSideBar{
       // background-color: yellow;
        display: flex;
        justify-content: center;
        width: 100%;
        order: 1;
      }
    .mainContainer{
       
      flex-direction: column;
      padding-left: 5%;
      padding-right: 5%;
    }
    .donateDiv{
        margin-bottom: 30px;
        border-radius: 30px;
    }
   
}

@media all and (max-width: 608px) {
    .welcomeCont2{
        height: 91px;
    }
    .welcomeCont2 p{
        font-size: 30px;
        
    }
}

@media all and (max-width: 532px) {
    
    .mainContainer{
        
        padding-left: 3%;
        padding-right:3%;
      }

    .rightSideBar {
        box-sizing: border-box;
       width: 100%;
       padding-left: 20px;
       padding-right: 10px;
       display: flex;
       align-items: center;
    }
    .container  .donateDiv{
        width:130px;
        height:35px;
        border-radius:30px;
    }
    .container  .donateDiv p{
        font-size:15px;
        
    }
    .rightSideBar .container h1{
        font-size:30px;
        line-height:35px;
        margin-bottom: 10px;
    }
    .rightSideBar .container h3{
        font-size:24px;
    }
    .welcomeCont{
        padding: 40px 0px 20px 0px;
        }
  }
  
   @media all and (max-width: 420px) {
    .welcomeCont{
    padding: 40px 0px;
    }
.rightSideBar .container h1{
    font-size:26px;
    line-height: 25px;
    margin-bottom: 10px;
}
.rightSideBar .container h3{
    font-size:20px;
}
    .leftSideBar{
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 20px;
    }
    .welcomeCont2{
        height: 91px;
    }
    .welcomeCont2 p{
        font-size: 20px;
        
    }
       /*.leftSideBar{
           display: flex;
          
          
       }
       .leftSideBar img{
        height:550px;
        width:90%;
        align-self: center;
         margin-left: 40px;
      
       
    }*/
    .leftSideBar1 img{
        object-fit:cover; 
        margin-right:0px; 
        height: 150px;
        width: 150px;
        max-height: 150px;
    }
    .leftSideBar2 img{
        height: 150px;
        object-fit:cover;  
        margin-right:0px;
        max-height: 150px;
        width: 150px;
        margin-top: 5px;
    }
      
  }
       @media all and (max-width: 380px) {
       
        .welcomeCont2 p{
            font-size: 18px;
            
        }
        .mainContainer{
            padding-left: 0%;
            padding-right:0%;
          }
        .donateDiv{
           align-self: flex-start; 
       }
    }
    @media all and (max-width: 350px) {
       
        .welcomeCont2 p{
            font-size: 18px;
            
        }
    }
  
