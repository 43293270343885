//account profile
$primaryColor: #FF7C59;
$secondaryColor: #FED800;
$hoverColor:#FBCD00;
$shopColor:#bfaf80;
.container{
    height: auto;
    min-height: auto;
    font-family: 'Poppins', sans-serif;
}
.headingTitle{
    padding-left: 23px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 19px;
    font-weight: 600;
   }
   .container2{
      height: auto;
      display: flex;
      padding-left: 23px; 
      padding-right: 23px;
   }
   .container3{
     height: auto;
     border-style: solid;
     border-width:1px;
     border-color: whitesmoke;
     width: 50%;
     margin-right: 7px;
     border-radius: 7px;
     padding-bottom: 38px;
   }
   .container4{
    height: auto;
    border-style: solid;
    border-width:1px;
    border-color: whitesmoke;
    width: 50%;
    margin-left: 7px;
    border-radius: 7px;
    padding-bottom: 38px;
   }
   .editAcc{
       background-color: black;
       width: 30%;
       margin-left: 23px;
       margin-top: 23px;
       height: 38px;
       display: flex;
       align-items: center;
       justify-content: center;
       color: white;
       border-radius: 7px;
       text-decoration: none;
   }
   .editAcc:hover{
       background-color: $primaryColor;
       cursor: pointer;
      
   }
   .container3 h2{
   border-bottom-color: whitesmoke;
   border-bottom-width:1px;
   border-bottom-style: solid;
   padding: 7px 15px 7px 15px;
   font-weight: 500;
   }
   .container4 h2{
    border-bottom-color: whitesmoke;
    border-bottom-width:1px;
    border-bottom-style: solid;
    padding: 7px 15px 7px 15px;
    font-weight: 500;
}
.container3 p,.container4 p{
    padding: 15px 15px 0em 15px;
}
.detName{
    font-size: 16px;
    font-weight: 500;
}
.detEmail{
    font-size: 15px;
    font-weight: 500;
}
.detLoc{
    font-size: 15px;
    font-weight: 500;
    color: $primaryColor;
}
.detPhone{
    font-size: 15px;
    font-weight: 500;
}
@media all and (max-width: 1076px) {
    .container2{
        flex-direction: column;
     }
     .container3{
        width: 100%;
       
      }
      .container4{
       box-sizing: border-box;
       margin-top: 23px;   
       width: 100%;
       margin-right: 7px;
       margin-left: 0em;
      }
      .editAcc{
          width: 230px;
          margin-bottom: 23px;
      }
}
@media all and (max-width: 461px) {
 .container h3{
  font-size: 16px;
 }
 .container2 h2{
    font-size: 15px;
}
.detName{
    font-size: 15px;
    font-weight: 500;
}
.detLoc{
    font-size: 13px;
    font-weight: 400;
}
/*.container3{
    height: 300px;
}
.container4{
    height: 300px;
}*/
.container3 p,.container4 p{
    padding: 7px 15px 0em 15px;
}
.container2{
    padding-left: 7px; 
    padding-right: 7px;
 }
}
@media all and (max-width: 384px) {
.editAcc{
    width: 192px;
}
.editAcc h4{
    font-size: 11px;
}
.container{
    font-family: 'Poppins', sans-serif;
}
.container3{
    height: auto;
    padding-bottom: 15px;
}
.container4{
    height: auto;
    padding-bottom: 15px;
}
}
@media all and (max-width: 384px) {
    .container h3{
     font-size: 16px;
    }
    .container2 h2{
       font-size: 16px;
   }
   .detName, .detEmail, .detPhone{
       font-size: 16px;
       font-weight: 500;
   }
   .detLoc{
       font-size: 16px;
       font-weight: 400;
   }
 
   .container3 p,.container4 p{
       padding: 7px 15px 0em 15px;
   }
   .container2{
       padding-left: 7px; 
       padding-right: 7px;
    }
   }

