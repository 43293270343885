$primaryColor: #8d2855;
$secondaryColor: #db5d96;
$hoverColor:#d8b806;
$theGrey1: #5F6162;
.container{
    height: auto;
    padding-bottom: 50px;
    padding-top: 0px;
}
.container4{
    margin-top: 50px;
}
.statusBar{
    //height: 70px;
    //background-color: $primaryColor;
  }
  .imgDiv{
   position: relative;
   height:40vh;
   min-height:300px;
  }
  .imgDiv img{
    width:100%;
    height: 100%;
    object-fit:cover;
  }
  .imgDiv h1{
    font-size: 60px;
    color: black;
    //font-family: 'Poppins', sans-serif;
    color: white;
    padding: 5px 20px;
    margin-left: 40px;
    position: absolute;
    top:0;left:0;
    margin-top: 200px;
    
   }
   
  .causesDetCont{
    box-sizing: border-box;
    display:flex;
    flex-direction:column;
    width:100%;
    padding: 0px 50px;
   
  }
  .modal{
    position: fixed;
    top:0;bottom: 0;left: 0;right: 0;
    background-color: #00000022;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modalItems{
    width: 400px;
    height: auto;
    min-height: 400px;
    background-color: #fff;
    border-radius: 5px;
    padding: 40px 30px;
    box-sizing: border-box;
  }
  .modalP1{
   margin-bottom: 10px;
   font-size: 18px;
   font-weight: 500;
   font-weight: 600;
  }
  .modalP2{
    font-size: 18px;
    margin-bottom: 10px;
  }
  .modalP3{
    text-decoration: underline;
    font-weight: 500;
    margin-bottom: 10px;
  }
  ul li{
    margin-bottom: 10px;
  }
  .modalP4{
    margin-bottom: 30px;
    font-weight: 600;
  }
  .modalP5{
    box-sizing: border-box;
    background-color: $primaryColor;
    color: #fff;
    width: 120px;
    font-size: 16px;
    padding-left:18px ;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    
  }
  .causesDetCont h4{
    font-size: 32px;
    margin-bottom:0px;
    margin-top: 30px;
    font-family: 'Rubik', sans-serif;
    //font-weight:800;
    color: $primaryColor;
  }
  .causesDetCont p{
   width: 100%;
  // text-align: center;
   font-size: 20px;
   margin-bottom:40px;
   margin-top:10px;
   font-family: 'Roboto', sans-serif;
   border-bottom: 5px solid $primaryColor;
   padding-bottom: 40px;
   //font-weight: 600;
   line-height: 26px;
  }
  @media all and (max-width: 950px) {
   
    .causesDetCont h1{
      font-size: 38px;
     }
     .causesDetCont h4{
      font-size: 30px;
    }
     .causesDetCont p{
      font-size: 18px;
     }}
@media all and (max-width: 500px) {
   .imgDiv{
    position: relative;
    height:18vh;
    min-height:150px;
    //height:55vh;
   }
  
  .imgDiv h1{
    font-size: 30px;
    margin-left: 0px;
    padding: 0px 10px;
    margin-top: 100px;
   }
   .causesDetCont{
    padding: 0px 10px 0px 20px;
   }
   .causesDetCont h1{
    font-size: 30px;
   }
   .causesDetCont h4{
    margin-top: 10px;
    font-size: 24px;
  }
   .causesDetCont p{
    font-size: 15px;
    padding-top:10px;
   }
}
@media all and (max-width: 380px) {
  .causesDetCont{
    align-items:flex-start;
    padding: 0px 10px 0px 10px;
  }
  .causesDetCont h1{
   margin-left: 10px;
  }
  .causesDetCont h4{
    margin-top: 10px;
    font-size: 22px;
  }
  .causesDetCont p{
    text-align: left;
    margin-bottom: 20px;
    margin-top: 1px;
   }}
