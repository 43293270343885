
//members
$primaryColor: #FF7C59;
$hoverColor:#FBCD00;
@mixin maxlinelength {
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   /* number of lines to show */
   -webkit-box-orient: vertical;
}

.bodySection3{
   box-sizing: border-box;
    height: auto;
    overflow: hidden;
    background-color: whitesmoke;
    padding-bottom: 38px;
}

.causedivzoom{
     height: auto;
     padding-bottom: 15px;
     width:24%;
    background-color:white;
   // margin:7px;
    justify-content:space-evenly;
    transition: transform .5s ease;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
 
  }

  .causedivzoom:hover {
    transform: scale(1.03);
    cursor: pointer;
  }
  .causedivzoom img{
    width:100%;
     height:300px;
     min-height: 50vh;
     object-fit:cover;
  }
  .causedivzoom h1{
    padding: 0;
    margin: 0;
    font-size:25px;
    font-family: 'Teko', sans-serif;
    font-weight:700;
    padding-right:7px;
    padding-left:7px;
    padding-top: 3px;
    -webkit-line-clamp: 1;
    @include maxlinelength();
   
  }
  .causedivzoom h3{
    padding-right:7px;
    padding-left:7px;
    font-size: 17px;
    color: #839192;
    font-weight: 700;
    margin-top: -3px;
    font-family: 'Teko', sans-serif;
    -webkit-line-clamp: 1;
    @include maxlinelength();
  
  }
  .causedivzoom p{
    font-size:12px;
    padding-right:7px;
    padding-left:7px;
    margin-top:3px;
    margin-bottom:7px;
    font-weight: 500;
    color: black;
    font-family: 'Poppins', sans-serif;
    //-webkit-line-clamp: 7;
   // @include maxlinelength();
   
    
    
  }


  .causesDetCont{
    display:flex;
    flex-direction:column;
    align-items:center;
    height: 152px;
    background-color: black;
    width:100%;
    justify-content: center;
   
   
  }
  .causesDetCont h1{
    margin: 0em;
   font-size: 45px;
   color: white;
   padding-bottom: 15px;
   font-family: 'Teko', sans-serif;
   
  }

  .causesItemsCont{
    display:flex;
    flex:1;
    padding-top: 38px;
    flex-wrap: wrap;
    margin-left: 3%;
    margin-right: 3%; 
    justify-content: space-between;

  }
  
  

  @media all and (max-width: 950px) {
    .causesDetCont h1{
      font-size: 30px;
     }
     .img-hover-zoom{
      width:30%;
    }
    .causedivzoom{

      width:47%;
    }
    .causesItemsCont{
      margin-left: 1.5%;
      margin-right: 1.5%;
    } 
}
@media all and (max-width: 760px) {
  .causedivzoom img{
     min-height:230px; 
     height:38vh;   
  }
  .causesItemsCont{
     margin-top: 7px;
  }
}
@media all and (max-width: 684px) {
  
  .causesDetCont h1{
    font-size: 30px;
   }
   .causedivzoom{
    width:46%;
  }
  .causedivzoom h1{
   font-size:25px;
    }
    .causedivzoom p{
      font-size:10px;
       }
   
}
@media all and (max-width: 608px) {

 /* .causedivzoom img{
     height:228px;    
  }*/
}




@media all and (max-width: 532px) {
  .causesDetCont h1{
    font-size: 30px;
   }

  .causedivzoom{
    width:46%;
  }
  .causedivzoom h1{
   font-size:25px;
    }
    .causedivzoom p{
      font-size:10px;
       }
       .causedivzoom img{
        height:35vh;  
        min-height: 212px;  
     }
     .causesItemsCont{
     
      margin-left: 0.3%;
      margin-right: 0.3%;
  }
}

 @media all and (max-width: 456px) {
 
  .causedivzoom{
    width:46%;
  }
  .causedivzoom h1{
   font-size:25px;
    }
    .causedivzoom p{
      font-size:10px;
       }
       .causedivzoom img{
        height: 37vh; 
        min-width: 190px;   
     }
     .causesDetCont h1{
      font-size: 30px;
     }
}
   @media all and (max-width: 418px) {

   
    .causedivzoom{
      width:100%;
    }
    .causedivzoom h1{
     font-size:25px;
      }
      .causedivzoom p{
        font-size:16px;
         }
         .causedivzoom img{
          height:50vh; 
          min-height:380px;   
       }
   }
   @media all and (max-width: 380px) {
    .causesItemsCont{
      margin-left: 0%;
     margin-left: 0%;
  }
  .causedivzoom h1{
    font-size:22px;
     }
  .causedivzoom p{
    font-size:14px;
     }
   }
     @media all and (max-width: 342px) {
      .img-hover-zoom{
        width:100%;
        margin:3px;
       
       }
       .img-hover-zoom img{
        width:100%;
        height:266px;
        
       }
       .causedivzoom p{
        font-size:12px;
         }
         .causedivzoom h1{
          font-size:20px;
           }
           .causesDetCont h1{
            font-size: 25px;
           }
      }
