
  //shops reel
  $primaryColor: #8d2855;
  $secColor: #db5d96;
  .boduTitleMainCont{
    min-height:600px;
    height: 85vh;
    width:100%;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
    
  }
  .titleDivCont{
    min-height:600px;
    height: 85vh;
    width:100%;
     position: relative;
     overflow:hidden;
  }
 
#titleH4{
  color: white;
  font-size: 34px;
  font-weight: 600;
  margin-top: 6%;
  margin-left: 0px;
}
.detailsP{
  color: white;
  font-size: 56px;
  font-weight: 400;
  margin-top: 20px;
  line-height: 70px;
}
.seeP{
  background-color: $primaryColor;
  color:#fff;
  width:130px;
  font-weight: 500;
  text-align: center;
  padding: 12px 0px;
  margin-top: 40px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}
.aroowsDiv{
  position: absolute;
  margin-top: 550px;
  display: flex;
  margin-left: 48%;
  align-items: center;
  z-index: 999;
}
.titleCont{
  height:100%;
  padding-left:7px;
  position:absolute;
 // align-items: center;
  display:flex;
  flex-direction:column;
  padding-top: 10%;
  background-color: #00000099;
  width: 100%;
}
.titleCont2{
  width:60%;
  display:flex;
  //justify-content: center;
  flex-direction:column;
  margin-left:100px;
  //align-items: center;
}
.theImage{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.backFang{
  font-size: 20px;
  color: #fff;
  background-color: $primaryColor;
  border-radius: 50px;
  padding:5px;
  
}
.frontFang{
  font-size: 20px;
  color: #fff;
  background-color:$primaryColor;
  border-radius: 50px;
  margin-left: 10px;
  padding:5px
}
@media all and (max-width: 1000px) {
  .titleCont2{
    width:70%;
  }
  #titleH4{
    margin-top: 15%;
  }
}

@media all and (max-width: 769px) {
  .boduTitleMainCont{
    min-height:450px;
    height: 45vh;
  }
  .aroowsDiv{
    margin-top: 400px;
  }
  .detailsP{
    font-size: 30px;
    line-height: 40px;
    
  }
  .titleDivCont{
    min-height:450px;
    height: 45vh;
  }
  .titleCont2{
    width:80%;
    margin-left:20px;
  }
}
@media all and (max-width: 615px) {

  #titleH4{
    font-size: 24px;
    margin-top: 10%;
  }
  .detailsP{
    font-size: 35px;
    font-weight: 400;
    line-height: 40px;
  }
 

  .aroowsDiv{
  margin-top: 390px;
   
  }
  .seeP{
    width:130px;
    padding: 8px 0px;
    margin-top: 30px;
   
  }
}
  @media all and (max-width: 500px) {
    .boduTitleMainCont{
      min-height:400px;
      height: 40vh;
    }
    .titleDivCont{
      min-height:400px;
      height: 40vh;
    }
   
    .aroowsDiv{
 
      margin-top: 350px;
     
    }
   
  
    #titleH4{
      font-size: 24px;
      margin-top: 10%;
    }
}
  @media all and (max-width: 420px) {
 
    
    .titleCont2{
      width:90%;
      margin-left:10px;
    }
    .aroowsDiv{
      margin-left: 40%;
     /* width: 98%;
      margin-top: 200px;
      margin-left: 3px;
      margin-right: 10px;
      justify-content: space-between;
      display: flex;*/
    }
    .detailsP{
      margin-top: 10px;
      font-size: 25px;
      margin-bottom: 10px;
      line-height: 35px;
    }
    .theImage{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .backFang{
      font-size: 20px;
     
    }
    .frontFang{
      font-size: 20px;
      
    }
    #titleH4{
      font-size: 24px;
      margin-top: 25%;
    }
}
@media all and (max-width: 307px) {
 
  
  .detailsP{
   
    font-size:25px;
   
  }

  .aroowsDiv{
 
    margin-top: 183px;
   
  }
}

