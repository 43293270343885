$primaryColor: #eb3e32;
$secondaryColor: #FED800;
$hoverColor:#FBCD00;
$shopColor:#bfaf80;

@mixin limitLine {
    display: -webkit-box;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
.container{
    margin-top: 0px;
    height: auto;
    background-color: white;
    font-size: 0.75px;
    font-family: 'Rubik', sans-serif;
   
}
.contHeader{
    box-sizing: border-box;
    display: flex;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: whitesmoke;
    align-items: center;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 40px;
    padding-top: 30px;
    padding-bottom: 20px;
    //background-color: red;
  }
  .contHeader h2{
     font-size: 30px;
     background-color: white;
     border-top-left-radius: 00px;
     border-top-right-radius: 00px;
     margin-left: 23px;
  }
.backIcon{
    font-size: 30px;
    color: black;
  }
  .backIcon:hover{
    color: black;
    cursor: pointer;
    transform: scale(1.05);
  }
.contBody{
    box-sizing: border-box;
    display: flex;
    padding-bottom: 100px;
}
.contLeft{
 width: 70%;
 background-color: white;
 height: auto;
 padding-left: 5%;
 padding-right: 5%;
 box-sizing: border-box;

}
.contRight{
    box-sizing: border-box;
    width: 30%;
    height: auto;
    margin-right: 5%;
    
}
.imgCont{
    height: 450px;
    display: flex;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
}

.contTitle{
    font-size: 41px;
    padding-left: 5%;
    margin: 0;
    margin-top: 23px;
    margin-bottom: 15px;
}
.contLeft h1{
    font-size: 38px;
    margin: 0;
    margin-top: 15px;
}
.contLeft p{
    font-size: 13px;
    margin: 0;
    font-weight: 400;
    line-height: 19px;
    margin-top: 4px;
    
}
.contShare{
    display: flex;
    align-items: center;
}
.contShare h4{
    font-size: 20em;
}
.contBtm{
    display: flex;
    height: 30px;
    justify-content: space-between;
    padding-right: 8px;
    align-items: center;
    margin-top: 2px;
    //background-color: red;
}
.iconFa{
    //margin-top: 5px;
    margin-left: 15px;
    font-size: 20em;
}
.iconTw{
   // margin-top: 5px;
    margin-left: 15px;
    font-size: 20em;
}

.listCont{
    margin-bottom: 38px;
    //box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    padding: 8px;
    padding-bottom: 15px;
    border-radius: 8px;
}
.listCont:hover{
  cursor: pointer;
  transform: scale(1.01);
}
.listCont h1{
  font-size: 30px;
}
.listCont p{
     font-size: 20em;
     line-height: 23px;
     -webkit-line-clamp: 6;

}
.contBtm h3{
    
    font-size: 20em;
}
.imgCont2{
    height: 75px;
    width: 75px;
    display: flex;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
}
.newPostCont{
    display: flex;
    margin-bottom: 11px;
   // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.newPostCont:hover{
    background-color: whitesmoke;
    cursor: pointer;
}

.newPostCont2{
    display: flex;
    //background-color: yellowgreen;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
}
.newPostCont2 h2{
    font-size: 25em;
    -webkit-line-clamp: 2;
    @include limitLine();
}
.newPostCont2 p{
    font-size: 20em;
    font-weight: 600;
    line-height: 20px;
    color: $primaryColor;
}

.contRight h1{
    font-size: 40em;
    margin-bottom: 15px;
   
}

.contRight2 h1{
  margin-top: 45px;
}
.contRight2 h2{
  font-size: 19px;
  text-decoration: underline;
  margin-top: 15px;
}
.contRight3 h1{
    margin-top: 45px;
}
.followIcons{
    font-size: 23px;
    margin-right: 15px;
}
.followIcons:hover{
    color: $primaryColor;
    cursor: pointer;
    transform: scale(1.1);
}
.footerDiv{
    height: 150px;
    background-color: yellow;
}
.newPostContCat h2:hover{
    color: #13bba4;
    cursor: pointer;
    transform: scale(1.02);
}

@media all and (max-width: 900px) {


.contBody{
    flex-direction: column;
}
 .contLeft{
    width: 100%;
    padding-left: 38px;
    padding-right: 38px;
   
   }
   .contRight{
    width: 100%;
    padding-left: 38px;
    padding-right: 38px;
    padding-bottom: 38px;
   // display: none;
 }
 .contRight3{
     display: none;
 }
   .imgCont{
    height: 355px; 
}
}
@media all and (max-width: 600px) {
    .contLeft{
        padding-left: 23px;
        padding-right: 23px;
       
       }
       .contRight{
        padding-left: 23px;
        padding-right: 23px;
     }
    .imgCont{
        height: 280px;
    }
    .listCont h1{
        font-size: 35em;
      }
      .listCont p{
           font-size: 18em;
           line-height: 23px;
      }
      .contTitle{
        font-size: 38px;
        padding-left: 24px;
        margin-top: 15px;
        margin-bottom: 8px;
    }
    .contHeader h2{
        font-size: 23px;
        margin-left: 15px;
     }
     .backIcon{
        font-size: 23px;
        
      }
      .contHeader{
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        //background-color: red;
      }
    }

    @media all and (max-width: 450px) {
        .imgCont{
            height: 270px;
        }
        .listCont h1{
            font-size: 30em;
            padding-left: 0px;
            padding-right: 0px;
          }
          .listCont p{
            padding-left: 0px;
            padding-right: 0px;
       }
       .contBtm h3{
        padding-left: 8px;
    }
    .iconTw{
        padding-right: 8px;
     }
        .listCont{
            margin-bottom: 23px;
            padding: 0px;
            //box-shadow: 0 3px 7px 0 rgba(0,0,0,0.1);
            padding-bottom: 8px;
           
        }
        .contRight h1{
            font-size: 35em;
        }

        .contHeader{
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 20px;
            padding-top: 30px;
            padding-bottom: 15px;
            //background-color: red;
          }
        
          .contHeader h2{
            font-size: 19px;
            margin-left: 15px;
         }
         .backIcon{
           font-size: 19px;
         }
        }

        @media all and (max-width: 375px) {
            .contLeft{
                padding-left: 15px;
                padding-right: 15px;
               
               }
               .contRight{
                padding-left: 15px;
                padding-right: 15px;
             }
             .contRight h1{
                font-size: 30em;
                margin-bottom: 8px;
            }
            .newPostCont2 h2{
                font-size: 20em;
            }
            .newPostCont2 p{
                font-size: 18em;
            }
          
            .contRight2 h1{
                margin-top: 23px;
              }
              .imgCont2{
                height: 60px;
                width: 60px;
            }
            .newPostContCat h2{
                font-size: 20em;
                line-height: 19px;
            }
            .contRight2 h2{
                margin-top: 11px;
              }


            .imgCont{
                height: 253px;
            }
            .listCont h1{
                font-size: 30em;
               
              }
              
            .listCont{
                margin-bottom: 23px;
                
            }
            .contTitle{
                font-size: 30px;
                padding-left: 15px;
                margin-top: 30px;
                margin-bottom: 8px;
            }
            .contBtm h3, .contBtm h4{
    
                font-size: 18em;
            }
            .iconFa{
                //margin-top: 5px;
                margin-left: 15px;
                font-size: 18em;
            }
            .iconTw{
               // margin-top: 5px;
                margin-left: 15px;
                font-size: 18em;
            }
            }

            @media all and (max-width: 300px) {
    
                .contLeft{
                     padding-left: 8px;
                    padding-right: 8px;
                   }
                .imgCont{
                    height: 215px;
                }
                .listCont h1{
                    font-size: 25em;
                  }
                  
                .contTitle{
                    font-size: 23px;
                }
                .listCont p{
                    font-size: 16em;
                    line-height: 17px;
               }

               .contRight{
                padding-left: 8px;
                padding-right: 8px;
             }
             .contRight h1{
                font-size: 25em;
                margin-bottom: 8px;
            }
            .newPostCont2 h2{
                font-size: 16em;
            }
            .newPostCont2 p{
                font-size: 16em;
            }
            .contRight2 h1{
                margin-top: 15px;
              }
              .imgCont2{
                height: 60px;
                width: 60px;
            }
            .newPostContCat h2{
                font-size: 18em;
                line-height: 19px;
            }
            .contRight2 h2{
                margin-top: 11px;
              }

                }