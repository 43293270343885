
//cart pay
$primaryColor: #eb3e32;
$secondaryColor: #FED800;
$hoverColor:#FBCD00;
$shopColor:#bfaf80;
.container{
    background-color: whitesmoke;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    padding-bottom: 50px;
    
}
.container h1{
    margin-top: 23px;
    margin-bottom: 7px;
    font-size: 30px;
}

.container1{
    display: flex;
    background-color: white;
    height: auto;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
    width: 70%;
    border-radius: 7px;
}
.contLeft{
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contRight{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 38px 15px 38px 15px;
    // align-items: center;
    border-left-color: whitesmoke;
    border-left-width:1px;
    border-left-style: solid;
    justify-content: center;
    padding-left: 10%;
}

.contLeft2{
    display: flex;
    background-color: white;
    height: 153px;
    flex-direction: column;
    margin-top: 38px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
    width: 330px;
    justify-content: center;
    align-items: center;
}
.contLeft2 h2{
color: $primaryColor;
font-size: 18px;
}
.contLeft2 p{
font-size: 15px;
}
.contLeft2 div{
    height: 38px;
    border-color: #eee;
    border-width:1px;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7px;
    font-size: 15px;
    width: 307px;
}
.contLeft2 div:hover{
 background-color: $primaryColor;
 color: white;
 cursor: pointer;
}
.phoneDiv{
    display: flex;
    border-color: yellow;
    height: 46px;
    
   
}
.phoneDiv h2{
 border-color: #eee;
 border-width:1px;
 border-style: solid;
 padding: 7px;
 font-weight: 400;
 color: rgb(139, 139, 139);
 background-color: #eee;
 font-size: 15px;
 text-align: center;
 display: flex;
 align-items: center;
}
.phoneDiv input{
    border: none;
    border-color: #eee;
    border-width:1px;
    border-style: solid;
    outline: none; 
    font-size: 19px;
    font-weight: 600;
    color: black;
    width: 246px;
   
}
.phoneDiv input:hover{
    border-color: #000; 
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: rgb(139, 139, 139);
    font-size: 13px;
  }
  .contRight h3{
    background-color: $primaryColor;
    color: white;
    width: 305px;
    text-align: center;
    margin-top: 30px;
    padding: 11px;
    font-size: 16px;
  }
.contRight h5{
 font-weight: 600;
 font-size: 15px;
 margin-left: 0em;
 margin-bottom: 7px;
}
.contRight h6{
    font-weight: 400;
    font-size: 11px;
    padding-right: 15px;
    margin-bottom: 15px;
    width: 80%;
   }
   .mpesaDiv{
    height: 61px;
    width: 115px;
    overflow: hidden;
    margin-top: -15px;
    margin-bottom: 23px;
}
.mpesaDiv img{
    width: 90%; 
}
.contLeft1{
    width: 70%;
    border-color: #eee;
    border-width: 1px;
    border-style: solid;
    padding: 19px;
}
.calcDiv1{
    flex: 1;
    display: flex;
    justify-content: space-between;
}
.contLeft1 h5{
    font-size: 19px;
    
}
.subTotP{
    font-size: 17px;
    font-weight: 600;
    margin-top: 3px;
    color: wheat;
}
.discP{
    font-size: 15px;
    margin-top: 3px;
}
.totalP{
    font-size: 17px;
    font-weight: 700;
    margin-top: 3px;
    color:  $primaryColor;
}
.totalP2{
    font-size: 17px;
    font-weight: 700;
    margin-top: 7px;
    border-style: solid;
    border-color: $primaryColor;
    border-width:1px 0em 0em;
    color: $primaryColor;
}

@media all and (max-width: 1300px) {
    .container1{
        display: flex;
        width: 80%;
    }
    .contRight{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 50%;
        margin: 38px 15px 38px 15px;
        padding-left: 8%;
    }
}
@media all and (max-width: 1100px) {
    .container1{
        display: flex;
        width: 90%;
    }
    .contRight{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 50%;
        margin: 38px 15px 38px 15px;
        padding-left: 5%;
    }

}
@media all and (max-width: 900px) {

    .contRight{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 50%;
        margin: 38px 15px 38px 15px;
        padding-left: 3%;
    } 
    .contLeft1{
        width: 80%;
        padding: 19px;
    }
}
@media all and (max-width: 800px) {
    .container1{
        flex-direction: column;
        width: 70%;
    }
    .contRight{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: auto;
        margin: 38px 15px 38px 15px;
        padding-left: 3%;
        padding-right: 3%;
        border-left-width: 0px;
        
    } 
    .contRight4{
        border-width: 1px;
        border-style: solid;
        border-color: whitesmoke;
        padding: 10px;
    }
    .contLeft{
        width: auto;
        padding: 19px;
        margin-top: 20px;
        
    }
    .contRight5{
        width: 100%;
    }
    .contRight h3{
        width: auto;
    }
    .contRight h3{
        width: auto;
    }
    .phoneDiv input{
        width: 100%;
       
    }
    .contLeft2{
        width: 100%;
    }
    .contLeft2 div{
        height: 38px;
        margin-top: 7px;
        font-size: 15px;
        width: 90%;
    }
    .contRight h6{
        font-size: 11px;
        padding-right: 15px;
        margin-bottom: 15px;
        width: auto;
       }

}
@media all and (max-width: 700px) {
    .container1{
        width: 80%;
    }

}
@media all and (max-width: 600px) {
    .container1{
        width: 90%;
    }

}
@media all and (max-width: 500px) {
    .container1{
        width: 100%;
        border-radius: 0px;
        box-shadow: none;
    }
    .contRight{
        margin: 10px 15px 30px 15px;
        padding-left: 0px;
        padding-right: 0px;
        
    } 
    .contLeft1{
        width: 95%;
        padding: 10px;
    }
    .container h1{
        margin-top: 20px;
        margin-bottom: 0px;
        font-size: 30px;
    }
    .mpesaDiv{
        height: 61px;
        width: 115px;
        overflow: hidden;
        margin-top: -20px;
        margin-bottom: 10px;
    }
   
    
}
@media all and (max-width: 400px) {

    .contLeft1 h5{
        font-size: 16px;
    }
    .subTotP{
        font-size: 16px;
        font-weight: 600;
        margin-top: 3px;
    
    }
    .discP{
        font-size: 14px;
        margin-top: 3px;
        font-weight: 700;
    }
    .totalP{
        font-size: 16px;
        font-weight: 700;
        margin-top: 6px;
    }
    .totalP2{
        font-size: 16px;
        font-weight: 800;
        margin-top: 7px;
       
    }
}