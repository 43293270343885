$primaryColor: #FF7C59;
$secondaryColor: #FED800;
$hoverColor:#FBCD00;
$shopColor:#bfaf80;
.cont{
    width: 100%;
    background-color: $primaryColor;
    height: 40px;
    position: fixed;
    top: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
}
.cont h3{
 color: white;
 font-weight: 500;
}