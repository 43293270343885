//check out
$primaryColor: #eb3e32;
$secondaryColor: #FED800;
$hoverColor:#FBCD00;
$shopColor:#bfaf80;
.mainConainer{
    height: auto;
    font-family: 'Poppins', sans-serif;
    min-height: 100vh;
   // overflow-x:auto;
    //width: 100%;
    //position: relative;
    //width: 100%;
    //display: flex;
}
.nav{
    height: 76px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
    background-color: white;
   
}
.container{
    display: flex;
    padding-top: 38px;
    padding-right: 10%;
    padding-left: 10%;
    box-sizing: border-box;
    padding-bottom: 38px;
   
}
.contLeft{
    display: flex;
    min-height: 615px;
    width: 30%;
  //  min-width: 300px;
    margin-right: 7px;
    height: auto;
    flex-direction: column;
}
.contLeft1{
    display: flex;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
    background-color: white;
    height: auto;
    flex-direction: column;
}

.contRight{
    display: flex;
    min-height: 615px;
   // min-width: 700px;
    height: auto;
    width: 70%;
    margin-left: 7px;
    flex-direction: column;
}
.contRight1{
    display: flex;
    height: auto;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
    background-color: white;
    flex-direction: column;
    flex: 1;
    padding: 15px;
}
.contLeft h2,.contRight h2{
    color: rgb(187, 187, 187);
    font-size: 15px;
    margin-bottom: 7px;
}

.contLeft1 h3{
    padding: 15px 7px 15px 7px;
    border-bottom-style: solid;
    border-bottom-width:1px;
    border-bottom-color: whitesmoke;

}
.itemsCont{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
   
}
.productsList{
    height: 76px;
    border-bottom-style: solid;
    border-bottom-width:1px;
    border-bottom-color: whitesmoke;
    display: flex;
    align-items: center;
}
.imgDiv{
    height: 76px;
    width: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.imgDiv img{
    width: 70%;
}
.proTitleP{
    font-size: 13px;
    font-weight: 600;
}
.proTitleP2{
 color: $primaryColor;
 font-size: 13px;
 font-weight: 500;
}
.calcDiv1{
    display: flex;
    justify-content: space-between;
    padding: 15px ;
}
.calcDiv1B{
    display: flex;
    justify-content: space-between;
    padding: 23px 38px 0em 38px;
}

.calcDiv1 p{
    padding-bottom: 7px;
    //font-size: 15px;
}
.totalP{
    font-size: 19px;
    font-weight: 600;
    color: $primaryColor;
}
.subTotP{
    font-size: 16px;
}
.calcDiv1B .calcDiv2 p{
    font-size: 19px;
}
.calcDiv1B .calcDiv3 p{
    font-size: 19px;
}
.vatP{
    font-size: 15px; 
    font-weight: 600;
    color: #bfaf80; 
}
 .calcDiv1B p{
    font-size: 23px;
}
.contLeft2{
    display: flex;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
    background-color: white;
    height: 115px;
    flex-direction: column;
    margin-top: 15px;
    padding: 15px;
}
.contLeft2 h2{
color: $primaryColor;
}
.contLeft2 p{
font-size: 15px;
}
.contLeft2 div{
    height: 38px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7px;
    font-size: 15px;
}
.contLeft2 div:hover{
 background-color: $primaryColor;
 color: white;
 cursor: pointer;
}
.contRight3{
    display: flex;
    margin-top: 15px;
    
    
}
.contRight3A{
    display: flex;
    justify-content: space-between;
}
.contRight3A h5{
    font-size: 15px;
    color: $primaryColor;
}
.contRight3A h5:hover{
    cursor: pointer;
    color: $hoverColor;
    font-size: 16px;
}
.selectorDiv{
    height: 23px;
    width: 23px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 38px;
    margin-right: 15px;
}
.selectorDiv:hover{
    cursor: pointer;
}
.deliverTitle{
    margin-top: 23px;
}
.contRight2 p{
  margin-top: 7px;
  font-size: 13px;
  margin-right: 15px;
}
.contRight2 h4{
    margin-top: 15px;
    font-size: 15px;
  }
  .contRight2 h3{
    font-size: 15px;
    margin-right: 15px;
  }
  .contProceed{
      box-sizing: border-box;
      background-color: $primaryColor;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      margin-right: 38px;
      margin-left: 38px;
      margin-top: 38px;
      border-radius: 7px;
      text-decoration: none;
  }
  .contProceed:hover{
      background-color: $hoverColor;
      cursor: pointer;
  }
  .contProceed h6{
      font-size: 15px;
      color: white;
      letter-spacing: 1px;
  }
  .contRight3P{
      margin-left: 38px;
      margin-right: 15px;
  }
  .pickUpLoc{
      font-weight: 400;
      margin-left: 38px;
      color: white;
      background-color: $primaryColor;
      width: 230px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      height: 30px;
  }
  .pickUpLoc:hover{
      background-color: $hoverColor;
      cursor: pointer;
  }

  @media all and (max-width: 1200px) {
    .container{
        padding-top: 30px;
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 40px;
       
    }
  }
  @media all and (max-width: 800px) {
    .container{
        flex-direction: column;
        padding-top: 30px;
        padding-right: 60px;
        padding-left: 60px;
        padding-bottom: 40px;
    }
    .contLeft{
        width: 100%;
        order: 2;
        margin-top: 30px;
    }
    .contRight{
        width: 100%;
        order: 1;
        margin-left: 0px;
    }
  }
  @media all and (max-width: 800px) {
    .container{
        flex-direction: column;
        padding-top: 30px;
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 40px;
    }
}
@media all and (max-width: 800px) {
    .container{
        flex-direction: column;
        padding-top: 30px;
        padding-right: 20px;
        padding-left: 20px;
        padding-bottom: 40px;
    }
}
  @media all and (max-width: 500px) {
    .container{
        flex-direction: column;
        padding-top: 30px;
        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 40px;
    }
}
@media all and (max-width: 400px) {
    .mainConainer{
      // background-color: red;
    }
    .container{
        flex-direction: column;
        padding-top: 30px;
        padding-right: 0px;
        padding-left: 0px;
        padding-bottom: 40px;
    }
  
    .contRight h2{
        color:black;
        font-size: 16px;
        margin-bottom: 7px;
        margin-left: 10px;
    }
    .contLeft .orderSum{
        color:black;
        font-size: 16px;
        margin-bottom: 7px;
        margin-left: 10px;
    }
    .contLeft1 h3{
       font-size: 16px;
    }

    .calcDiv1B{
        padding: 23px 10px 0em 38px;
    }

    .calcDiv1B .calcDiv2 p{
        font-size: 16px;
        font-weight: 700;
    }
    .calcDiv1B .calcDiv3 p{
        font-size: 16px;
        font-weight: 700;
    }
    .contProceed h6{
        font-size: 14px;
        color: white;
        letter-spacing: 1px;
    }
}
@media all and (max-width: 350px) {


}
