
 $primaryColor: #8d2855;
 $secondaryColor: #db5d96;
 $hoverColor:#d8b806;
 $theGrey1: #5F6162;
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;700&display=swap');

.mainContainer{
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    margin-bottom: 15px;
    padding-left: 4%;
    padding-right: 4%;
    padding-bottom: 0px;
    overflow: hidden;
}
.leftSideBar{
    flex: 1.5;
    display: flex;
    padding-top: 30px;
    transition: transform .5s ease;
    justify-content: space-around;
    height: auto;
    display: flex;
    align-items: center;
    order: 2;
}
.leftSideBar img{
    width:100%;
    height:50vh;
    min-height:400px;
    object-fit:cover;
   
   
   
}

.rightSideBar{
    flex: 1.7;
    display: flex;
    align-items: center;
    margin-left: 40px; 
    order: 1;
    margin-right: 10px;
}
.rightSideBar .container{
   // padding-right: 76px;
    align-content: center;
    display: flex;
    flex-direction: column;
}
.donateDiv{
     display:flex;
     background-color:$primaryColor;
     width:150px;
     height: 45px;
     justify-content:center;
     align-items: center;
     margin-top:15px;
    border-radius: 10px;
     text-decoration: none;
     
}
.donateDiv:hover{
    cursor: pointer;
    background-color:$hoverColor;
}
.donateDiv p{
    font-size:20px;
    color:#fff;
    font-weight:700;
    letter-spacing:1.2;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
}

.rightSideBar .container h1{
    font-size:30px;
    font-weight:700;
    line-height: 40px;
    color: #000;
    margin-bottom: 5px;
}
.rightSideBar .container p{
    //text-align: center;
    font-size: 18px;
    margin-bottom: 5px;
    line-height: 25px;
}

@media all and (max-width: 1064px) {
    .mainContainer{
        padding-left: 5%;
        padding-right: 5%;
    }
}
@media all and (max-width: 988px) {
   
    .rightSideBar .container h1{
       
        font-size:28px;
        
    }

   
   
    .leftSideBar{
        //background-color: yellow;
        padding-top: 41px;
        flex: 2;
        
    }
}
@media all and (max-width: 836px) {
          .leftSideBar img{
            width:100%;
            height:456px;
           
           
        }
  }
  @media all and (max-width: 745px) {
   
    .rightSideBar .container h1{
        margin-top:20px;
      }
  
      .rightSideBar{
       // background-color: yellow;
        display: flex;
        justify-content: center;
        margin-left: 0px;
        order:2;
      }
    .mainContainer{
      flex-direction: column;
      padding-left: 0%;
      padding-right: 0%;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .leftSideBar{
        padding-left: 50px;
        padding-right: 50px;
        margin-top: 0px;
        padding-top: 0px;
        order:1;
    }
    .leftSideBar img{
        width:100%;
        height:60vh;
        min-height: 450px;
        object-fit:cover;
        transition: transform .5s ease;
       
    }
   
   
    .rightSideBar .container{
        margin-top: 7px;
        width: 85%;
        
    }
    .rightSideBar .container p{
        //text-align: center;
        font-size: 18px;
        line-height: 25px;
    }
   
    
   
}

@media all and (max-width: 608px) {

}

@media all and (max-width: 532px) {
    
    .mainContainer{
       
        padding-left: 3%;
        padding-right:3%;
      }

    .rightSideBar {
       
        display: flex;
        align-items: center;
       
        
    }
    .rightSideBar .container{
        width: 80%;
       // margin-left: 7px;
    }

   /* .mainContainer{
        padding-left: 0px;
        padding-right: 0px;
      
    }*/
    .rightSideBar {
       width: 100%;
    }
    .rightSideBar .container h1{
        padding-right: 0em;
       font-size: 26px;
    }
  
    .rightSideBar .container p{
        font-size: 16px;
    }
    .container  .donateDiv p{
        font-size:15px;
        
    }
  }
  
   @media all and (max-width: 456px) {
    .leftSideBar{
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 20px;
    }
    
    .leftSideBar img{
        width:90%;
        height:63px;
        min-height:300px;
       
    }
    .rightSideBar .container{
        width: 100%;
        padding: 0px 10px;
        
    }
      
  }
       @media all and (max-width: 380px) {
        .rightSideBar .container h1{
            padding-right: 0em;
           font-size: 22px;
        }
       
        .mainContainer{
        
            padding-left: 0%;
            padding-right:0%;
          }
        .leftSideBar img{
            width:100%;
            max-height:250px;
            min-height:250px;
           
        }
        .donateDiv{
           align-self: flex-start; 
       }
    }

